import { ActionReducerMap } from '@ngrx/store';
import * as ui from './ui/ui.reducer';
import * as auth from './auth/auth.reducer';
import * as authListClient from './auth/auth-list-client.reducer';
import * as shoppingCar from './shoppingCar/shoppingCar.reducer';
import * as launchers from './launchers/launchers.reducer';
import * as clientSelect from './selectClient/selectClient.reducer';
import { AuthState } from '../interfaces/states';
import { CarsState } from '@core/interfaces/cars';
import { LaunchersState } from '../interfaces/launchers';
import { SelectClientState } from '@core/interfaces/selectclient';
import * as essentials from './essentials/essentials.reducer';
import { EssentialsState } from '@core/interfaces/essentials';

export interface AppState {
   ui: ui.State,
   auth: AuthState,
   authListClient: AuthState,
   clientSelect: SelectClientState,
   carsState: CarsState,
   launchersState: LaunchersState,
   essentialsState: EssentialsState
}

export const appReducers: ActionReducerMap<AppState> = {
   ui: ui.uiReducer,
   auth: auth.authReducer,
   clientSelect: clientSelect.selectReducer,
   authListClient: authListClient.authListClientReducer,
   carsState: shoppingCar.searchReducer,
   launchersState: launchers.launchersReducer,
   essentialsState: essentials.essentialsReducer
}

