import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WayFormsRoutingModule } from './way-forms-routing.module';
import { WayFormsComponent } from './way-forms.component';
import { ErrorComponent } from './error/error.component';
import { SuccessComponent } from './success/success.component';
import { BadComponent } from './bad/bad.component';
import { MainComponent } from './main/main.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    WayFormsComponent,
    ErrorComponent,
    SuccessComponent,
    BadComponent,
    MainComponent
  ],
  imports: [
    CommonModule,
    WayFormsRoutingModule,
    SharedModule,
    NgxSkeletonLoaderModule
  ]
})
export class WayFormsModule { }
