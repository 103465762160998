import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ControlFormsService } from '@core/services/control-forms/control-forms.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  responseService: any;
  constructor(
    private _location: Location,
    private router: Router,
    private accessControlService: ControlFormsService
  ) {}

  ngOnInit() {
    this.dataError(this.router.url);
    if (!this.accessControlService.isAccessAllowed()) {
      this.router.navigate(['/seleccionar-cliente']);
    }
    this.accessControlService.resetAccess();
  }
  goBack() {
    this._location.back();
  }
  dataError(error) {
    this.responseService = {
      img: 'assets/svg/spot-not-found.svg',
      button: false,
      title: 'No se pudo mostrar el formulario',
      description:
        'Prueba ingresando desde tu sección de AlicorWay en la plataforma Web Kam.',
      description2:
        'En caso de que el problema persista comunicate a este correo: ',
      email: 'atencionclientes@alicorp.com.pe',
    };
  }
}
