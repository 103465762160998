import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CarsState } from '@core/interfaces/cars';
import { AppState } from './app.reducer';
import { EssentialsState } from '@core/interfaces/essentials';
 
export const selectCarsStateFeature = (state: AppState) => state.carsState;
export const selectClientStateFeature = (state: AppState) => state.clientSelect;
export const selectEssentialsStateFeature = (state: AppState) => state.essentialsState;
export const listCars = createSelector(
  selectCarsStateFeature,
  (state: CarsState) => state.cars
);
export const selectLoginUserInfo = createSelector(
  selectClientStateFeature,
  (state: any) => {
    return state.client
  }
);

// Selector para obtener el estado completo


// Selector para obtener 'essentials' dentro del estado
export const selectEssentials = createSelector(
  selectEssentialsStateFeature,
  (state: EssentialsState) => state.essentials
);
export const selectEssentialsLoading = createSelector(
  selectEssentialsStateFeature,
  (state: EssentialsState) => {state.loading}
);
export const selectEssentialsError = createSelector(
  selectEssentialsStateFeature,
  (state: EssentialsState) => state.error
);