<section>
	<div class="container d-flex jc-center ai-center">
	 <div class="content-error ta-c">
	  <div class="iconography-two mb-30">
		<img src="assets/image/forms/bad.png" width="244" height="189" alt="msg">
	  </div>
	  <p class="ta-c font-bold-dali fts-24">{{responseService.title}}</p>
	  <p class="ta-c mb-0 font-regular fts-14 description">{{responseService.description}}</p>
	  <div class="ta-c mt-24">
		<button href="#" class="btn-red w-button mb-8 fts-16 font-medium ph-16" (click)="goBack()"
		*ngIf="responseService?.button">Reintentar</button>
	  </div>
	 </div>
  </div>
  </section>