import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectedClientService {
  private url = environment.apiURL;
  constructor(private _http: HttpClienteService, private http: HttpClient) {}

  getSelectClient(id: any) {
    const query = `${this.url}/wcl-seller/customer-list?code=${id}`;
    return this._http.get(query);
  }

  getRoutinesList(code: string | number) {
    //  const query =' https://demo0160928.mockable.io/rutinas';
    const query = `${this.url}/wcl-document/routines-by-seller-code?code=${code}`;
    return this._http.get(query);
  }
  getformId(idForm: string) {
    //  const query =' https://skynet-api-dev.azure-api.net/api/wcl-document/from-questions-by-form-id?formId=F20';
    const query = `${this.url}/wcl-document/from-questions-by-form-id?formId=${idForm}`;
    return this._http.get(query);
  }
  createPresigned(data: any){
    const query = `${this.url}/wcl-document/create-presigned-url`
    return this._http.post(query, data)
  }
  insertAnswer(data: any){
    const query = `${this.url}/wcl-document/insert-seller-answer`
    return this._http.post(query, data)
  }
  // Método para subir archivos al Blob Storage de Azure
  uploadFileToBlob(preSignedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({
      'x-ms-blob-type': 'BlockBlob', // Tipo de blob específico para Azure
    });
  
    // Realiza la solicitud PUT usando la URL pre-firmada sin ningún encabezado de autenticación ni cookies
    return this.http.put(preSignedUrl, file, {
      headers,
      observe: 'response',
      withCredentials: false,
    });
  }  
}
