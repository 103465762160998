<div class="container-benefits">
  <div class="vw-100 title-benefits">
    <p class="m-0 p-0 fts-24 font-bold-dali">{{ benefitsTitle }}</p>
    <div class="custom-tab-buttons">
      <span class="font-bold-dali fts-16 mr-8 show-desktop-tablet">{{sectionAvailableText}}</span>
      <button (click)="onSelectOptionButton('aliados')" [class.active]="selectedOptionButton === 'aliados'">
        Aliados
      </button>
      <button (click)="onSelectOptionButton('visibilidad')" [class.active]="selectedOptionButton === 'visibilidad'">
        Visibilidad
      </button>
      <button (click)="onSelectOptionButton('ejecucion')" [class.active]="selectedOptionButton === 'ejecucion'">
        Ejecución
      </button>
      <button (click)="onSelectOptionButton('soporte')" [class.active]="selectedOptionButton === 'soporte'">
        Post Venta
      </button>
    </div>
  </div>
  <section class="container-table show-desktop-tablet">
    <div class="grid-container">
      <div class="d-flex align-items-center level-item level-item-padding fts-16 font-bold-dali head-benefits">
        {{ levelsTitle }}
      </div>

      <div class="d-flex align-items-center level-item level-item-padding head-benefits"
        [ngClass]="getLevelData(1)?.levelTypeSelect ? 'color-client-background' : ''" >
        <img src="{{ levelImages[0].src }}" alt="{{ levelImages[0].alt }}" />
        <span class="ml-8 fts-12 font-bold-dali line-height-16">{{
          levelImages[0].text
          }}</span>
      </div>
      <div class="d-flex align-items-center level-item level-item-padding  head-benefits"
        [ngClass]="getLevelData(2)?.levelTypeSelect ? 'color-client-background' : ''">
        <img src="{{ levelImages[1].src }}" alt="{{ levelImages[1].alt }}" />
        <span class="ml-8 fts-12 font-bold-dali line-height-16">{{
          levelImages[1].text
          }}</span>
      </div>
      <div class="d-flex align-items-center level-item level-item-padding head-benefits"
        [ngClass]="getLevelData(3)?.levelTypeSelect ? 'color-client-background' : ''">
        <img src="{{ levelImages[2].src }}" alt="{{ levelImages[2].alt }}" />
        <span class="ml-8 fts-12 font-bold-dali line-height-16">{{
          levelImages[2].text
          }}</span>
      </div>
      <div class="d-flex align-items-center level-item level-item-padding head-benefits"
        [ngClass]="getLevelData(4)?.levelTypeSelect ? 'color-client-background' : ''">
        <img src="{{ levelImages[3].src }}" alt="{{ levelImages[3].alt }}" />
        <span class="ml-8 fts-12 font-bold-dali line-height-16">{{
          levelImages[3].text
          }}</span>
      </div>
    </div>
    <!-- fila 1 -->
    <div class="grid-body-container">
      <div class="d-flex align-items-center level-item level-item-padding-initial">
        <div>
          <div class="d-flex align-items-center">
            <img [src]="dataTableSelected[0]?.icon" width="32px" class="mr-8" />
            <p class="m-0 p-0 fts-20 font-bold-dali color-support-positive-medium">
              {{ dataTableSelected[0]?.title }}
            </p>
          </div>
          <div class="mt-16 fts-16 font-regular color-subTitle-custom">
            <p class="m-0 p-0" [innerHTML]="dataTableSelected[0]?.descriptionText1"></p>
          </div>
        </div>
      </div>
      <!-- primer value bronce -->
      <div class="level-item level-item-padding-initial color-client-background" *ngIf="
        getLevelData(1)?.levelTypeSelect;
        else notSelect
      ">
        <div class="ta-c">
          <img [src]="iconPeople.src"/>
          <p class="m-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark">
            {{ currentLevelTitle }}
          </p>
        </div>
      </div>
      <!-- primer value plata -->
      <div class="level-item level-item-padding-initial color-client-background" *ngIf="
          getLevelData(2)?.levelTypeSelect;
          else notSelect
        ">
        <div class="ta-c">
          <img [src]="iconPeople.src"/>
          <p class="m-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark">
            {{ currentLevelTitle }}
          </p>
        </div>
      </div>
      <!-- primer value oro -->
      <div class="level-item level-item-padding-initial color-client-background" *ngIf="
        getLevelData(3)?.levelTypeSelect;
        else notSelect
      ">
        <div class="ta-c">
          <img [src]="iconPeople.src" />
          <p class="m-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark">
            {{ currentLevelTitle }}
          </p>
        </div>
      </div>
      <!-- primer value diamante -->
      <div class="level-item level-item-padding-initial color-client-background" *ngIf="
        getLevelData(4)?.levelTypeSelect;
        else notSelect
      ">
        <div class="ta-c">
          <img [src]="iconPeople.src"/>
          <p class="m-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark">
            {{ currentLevelTitle }}
          </p>
        </div>
      </div>
    </div>
    <!-- fila 2 -->
    <div class="grid-body-container">
      <div class="d-flex align-items-start level-item level-item-padding-end">
        <div>
          <div class="fts-16 font-regular color-subTitle-custom" [innerHTML]="dataTableSelected[0]?.descriptionText2">
          </div>
        </div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionPoints', 1)">
        <div [innerHTML]="getLevelData(1)?.descriptionPoints"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionPoints', 2)">
        <div [innerHTML]="getLevelData(2)?.descriptionPoints"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionPoints', 3)">
        <div [innerHTML]="getLevelData(3)?.descriptionPoints"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionPoints', 4)">
        <div [innerHTML]="getLevelData(4)?.descriptionPoints"></div>
      </div>

    </div>
    <!-- fila 3-->
    <div class="grid-body-container">
      <div class="d-flex align-items-start level-item level-item-padding-end">
        <div class="fts-16 font-regular color-subTitle-custom" [innerHTML]="dataTableSelected[0]?.descriptionText3">

        </div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionCrouch', 1)">
        <div [innerHTML]="getLevelData(1)?.descriptionCrouch"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionCrouch', 2)">
        <div [innerHTML]="getLevelData(2)?.descriptionCrouch"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionCrouch', 3)">
        <div [innerHTML]="getLevelData(3)?.descriptionCrouch"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionCrouch', 4)">
        <div [innerHTML]="getLevelData(4)?.descriptionCrouch"></div>
      </div>
    </div>
     <!-- fila 4-->
     <div class="grid-body-container" *ngIf="dataTableSelected[0]?.descriptionText4">
      <div class="d-flex align-items-start level-item level-item-padding-end">
        <div class="fts-16 font-regular color-subTitle-custom" [innerHTML]="dataTableSelected[0]?.descriptionText4">

        </div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionMerchant', 1)">
        <div [innerHTML]="getLevelData(1)?.descriptionMerchant"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionMerchant', 2)">
        <div [innerHTML]="getLevelData(2)?.descriptionMerchant"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionMerchant', 3)">
        <div [innerHTML]="getLevelData(3)?.descriptionMerchant"></div>
      </div>
      <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
        [ngClass]="getLevelClassesDesktop('descriptionMerchant', 4)">
        <div [innerHTML]="getLevelData(4)?.descriptionMerchant"></div>
      </div>
    </div>
  </section>

  <!-- seccion mobile init -->
  <section class="container-table show-mobile">
    <div class="grid-container">
      <div class="level-item level-item-padding d-flex justify-content-start align-items-center head-benefits">
        <div class="fts-16 font-bold-dali mr-16 color-title-custom">
          {{ levelsTitleMobile }}
        </div>
        <div class="d-flex ai-center container-select vw-100">
          <mat-form-field appearance="outline" class="select-general-40 font-bold-dali fontw-700 fts-16" color="accent">
            <mat-select name="selectAliPartners" [(ngModel)]="selectedOption"
              (selectionChange)="onSelectionChange($event.value)" #selectPanel="matSelect"
              (opened)="onSelectPanelOpened()" (closed)="onSelectPanelClosed()">
              <!-- Personalizar lo que se muestra en el input -->
              <mat-select-trigger>
                <div class="level-item align-items-center d-flex mt-16">
                  <!-- Mostrar la imagen y el texto en el input -->
                  <img [src]="selectedOption?.src" [alt]="selectedOption?.alt" />
                  <span class="ml-8 fts-16 font-bold-dali line-height-16 color-title-custom">{{
                    selectedOption?.text }}</span>
                </div>
              </mat-select-trigger>

              <!-- Opciones desplegables -->
              <mat-option *ngFor="let option of levelImages" [value]="option" class="font-book fts-16">
                <div class="align-items-center d-flex">
                  <img [src]="option.src" [alt]="option.alt" class="mr-8" />
                  <span class="ml-8 fts-12 font-bold-dali line-height-16">{{
                    option.text
                    }}</span>
                </div>

                <!-- Icono de check (si es la opción seleccionada) -->
                <mat-icon *ngIf="selectedOption?.id == option?.id"
                  class="icon-check d-flex justify-content-end align-items-center">
                  <img src="assets/image/home-seller/check_regular.svg" width="20px" />
                </mat-icon>
              </mat-option>
            </mat-select>

            <span class="material-symbols-rounded color-active">
              {{ isOpen ? "expand_less" : "expand_more" }}
            </span>
          </mat-form-field>
        </div>
      </div>
    </div>

    <section class="body-table">
      <div class="grid-body-container">
        <!-- Fila 1 -->
        <div class="level-item level-item-padding-initial">
          <div>
            <div class="d-flex align-items-center">
              <img [src]="dataTableSelected[0]?.icon" width="32px" class="mr-8" />
              <p class="m-0 p-0 fts-20 font-bold-dali color-support-positive-medium">
                {{ dataTableSelected[0]?.title }}
              </p>
            </div>
            <div class="mt-16 fts-16 font-regular color-subTitle-custom">
              <p class="m-0 p-0" [innerHTML]="dataTableSelected[0]?.descriptionText1"></p>
            </div>
          </div>
        </div>

        <div class="level-item level-item-padding-initial color-client-background" *ngIf="
            getLevelData(selectedOption?.id)?.levelTypeSelect;
            else notSelect
          ">
          <div class="ta-c">
            <img [src]="iconPeople.src"/>
            <p class="m-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark">
              {{ currentLevelTitle }}
            </p>
          </div>
        </div>
      </div>

      <div class="grid-body-container">
        <!-- Fila 2 -->
        <div class="level-item level-item-padding-end">
          <div class="fts-16 font-regular color-subTitle-custom" [innerHTML]="dataTableSelected[0]?.descriptionText2">
          </div>
        </div>
        <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
          [ngClass]="getLevelClasses('descriptionPoints')">
          <div [innerHTML]="getLevelData(selectedOption?.id)?.descriptionPoints"></div>
        </div>
      </div>

      <div class="grid-body-container">
        <!-- Fila 3 -->
        <div class="level-item level-item-padding-end">
          <div class="fts-16 font-regular color-subTitle-custom" [innerHTML]="dataTableSelected[0]?.descriptionText3">
          </div>
        </div>
        <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
          [ngClass]="getLevelClasses('descriptionCrouch')">
          <div [innerHTML]="getLevelData(selectedOption?.id)?.descriptionCrouch"></div>
        </div>
      </div>

      <div class="grid-body-container" *ngIf="dataTableSelected[0]?.descriptionText4">
        <!-- Fila 4 -->
        <div class="level-item level-item-padding-end">
          <div class="fts-16 font-regular color-subTitle-custom" [innerHTML]="dataTableSelected[0]?.descriptionText4">
          </div>
        </div>
        <div class="level-item level-item-padding-end d-flex justify-content-center align-items-center"
          [ngClass]="getLevelClasses('descriptionMerchant')">
          <div [innerHTML]="getLevelData(selectedOption?.id)?.descriptionMerchant"></div>
        </div>
      </div>
    </section>
  </section>
  <!-- seccion mobile  end-->
</div>
<ng-template #notSelect>
  <div class="level-item level-item-padding-initial d-flex justify-content-center"></div>
</ng-template>