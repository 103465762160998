import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '@core/services/utils/utils.service';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store/app.reducer';
import * as ShoppingActions from '@core/store/shoppingCar/shoppingCar.actions';
import { LocalStorageService } from '@core/services/local-storage.service';
import { GtmEventService } from '@core/analytics/gtm-events.service';

@Component({
  selector: 'app-essentials-card',
  templateUrl: './essentials-card.component.html',
  styleUrls: ['./essentials-card.component.scss'],
})
export class InfaltablesCardComponent implements OnInit {
  @Input() cardData: any;
  localCardData: any;
  carsState: any = { cars: [] };
  sectorSelect: any;

  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private _gtmEventService: GtmEventService
  ) {}

  ngOnInit() {
    this.sectorSelect = this.localStorageService.getItem('sectorSelect');
    this.initStore();
    this.localCardData = {
      count: this.cardData.count,
      totalPrice: this.cardData.totalPrice || '0',
      sku: this.cardData?.sku ? Number(this.cardData.sku) : null
    };
  }
  // Getter para seleccionar la imagen según el descuento
  get discountImageUrl(): string {
    if (this.cardData && this.cardData.discountType === 'PRECIO') {
      return 'assets/image/infaltables-card/discount-fund_green.svg';
    }
    return 'assets/image/infaltables-card/discount-fund.svg';
  }
  get discountText(): string {
    if (this.cardData && this.cardData.discountType === 'PRECIO') {
      return 'Desct.';
    }
    return 'Rebate';
  }

  updateCount(value: number) {
      const replace = String(value).replace(/[^0-9-.]/g, '');
      let countFormat = parseFloat(replace);
      
      if (isNaN(countFormat) || countFormat == 0) {
        this.localCardData.totalPrice = '0';
        this.localCardData.count = '';
      } else if (countFormat < 0) {
        countFormat = countFormat * -1;
        const total = countFormat * parseFloat(this.cardData.discountedPrice || this.cardData.originalPrice);
        this.localCardData.totalPrice = UtilsService.formatTotalNumber(total.toFixed(2));
        this.localCardData.count = UtilsService.formatNumber(countFormat);
      } else if (countFormat > 999999) {
        const total = 999999 * parseFloat(this.cardData.discountedPrice || this.cardData.originalPrice);
        this.localCardData.totalPrice = UtilsService.formatTotalNumber(total.toFixed(2));
        this.localCardData.count = '999,999';
      } else {
        const total = countFormat * parseFloat(this.cardData.discountedPrice || this.cardData.originalPrice);
        this.localCardData.totalPrice = UtilsService.formatTotalNumber(total.toFixed(2));
        this.localCardData.count = UtilsService.formatNumber(countFormat);
      }
    }

    get totalPrice(): string {
      if (!this.localCardData?.totalPrice) {
        return '0';
      }
      return this.localCardData.totalPrice;
    }

  initStore() {
    this.store.select('carsState').subscribe(carsState => {
      if (carsState) {
        this.carsState.cars = [...carsState.cars];
      }
    });
  }

  addToCar() {
    if (!this.localCardData.count || this.localCardData.count === '0') return;
    
    const productToAdd = {
      available: this.cardData.available,
      baseUnd: this.cardData.baseUnd,
      brandName: this.cardData.brandName,
      buyUnd: this.cardData.buyUnd,
      cashPrice: this.cardData.cashPrice,
      categoryName: this.cardData.categoryName,
      count: this.localCardData.count,
      creditPrice: this.cardData.creditPrice,
      description: this.cardData.description,
      igv: this.cardData.igv?.toString(),
      percep: this.cardData.percep,
      perceptionFlag: this.cardData.perceptionFlag,
      salePrice: this.cardData.salePrice,
      sku: this.localCardData.sku,
      stock: this.cardData.stock,
      suggestedQuantity: this.cardData.suggestedQuantity,
      totalPrice: this.localCardData.totalPrice,
      url: this.cardData.productImage,
      discount: this.cardData.discountNumber?.toString() + '%',
      discountType: this.cardData.discountType,
      categoryCode: this.cardData.categoryCode,
      group: this.cardData.group,
      isWebExclusive: this.cardData.isWebExclusive,
      mechanics: this.cardData.mechanics,
      priority: this.cardData.priority
    };

    if (this.carsState.cars.length == 0) {
      // crear carro nuevo
      const car = {
        unitBussines: this.sectorSelect.name,
        products: [productToAdd]
      };
      this.carsState.cars.push(car);
      this.store.dispatch(ShoppingActions.setCar({ cars: this.carsState.cars }));
    } else {
      // buscar la unidad de negocio actual
      let ifBusinessCarExist = false;
      const tempCars = this.carsState.cars.map(car => {
        if (car.unitBussines == this.sectorSelect.name) {
          car = {
            ...car,
            products: this.searchProductInExistingCar(car, productToAdd)
          };
          ifBusinessCarExist = true;
          return car;
        }
        return car;
      });

      if (!ifBusinessCarExist) {
        const car = {
          unitBussines: this.sectorSelect.name,
          products: [productToAdd]
        };
        tempCars.push(car);
      }
      this.carsState.cars = [...tempCars];
      this.store.dispatch(ShoppingActions.setCar({ cars: this.carsState.cars }));
    }

    this._gtmEventService.addProductCartEvent("AÑADIR AL CARRITO", [productToAdd]);
    this.cleanCar();
  }

  searchProductInExistingCar(car: any, productToAdd: any) {
    let newListInCarProducts: any = [];
    let flagExist = false;
    
    car.products.forEach((productInCar) => {
      if (productToAdd.sku == productInCar.sku) {
        const replace = Number.isInteger(productToAdd.count) ? productToAdd.count : productToAdd.count.replace(/[^0-9]/g, '');
        const replace2 = Number.isInteger(productInCar.count) ? productInCar.count : productInCar.count.replace(/[^0-9]/g, '');
        
        let tempCount = parseFloat(replace) + parseFloat(replace2) > 999999
          ? 999999
          : parseFloat(replace) + parseFloat(replace2);
        let tempTotalprice = UtilsService.formatTotalNumber((tempCount * parseFloat(productInCar.salePrice)).toFixed(2));
        
        newListInCarProducts.push({
          ...productInCar,
          count: UtilsService.formatNumber(tempCount),
          totalPrice: `${tempTotalprice}`
        });
        flagExist = true;
      } else {
        newListInCarProducts.push(productInCar);
      }
    });

    if (!flagExist) {
      newListInCarProducts.push(productToAdd);
    }
    
    return newListInCarProducts;
  }

  private calculateNewCount(existingCount: string | number, newCount: string | number): string {
    const count1 = Number.isInteger(existingCount) ? existingCount : existingCount.toString().replace(/[^0-9]/g, '');
    const count2 = Number.isInteger(newCount) ? newCount : newCount.toString().replace(/[^0-9]/g, '');
    
    let total = parseFloat(count1.toString()) + parseFloat(count2.toString());
    total = total > 999999 ? 999999 : total;
    
    return UtilsService.formatNumber(total);
  }

  cleanCar() {
    this.localCardData.count = 0;
    this.localCardData.totalPrice = '0';
  }
}
