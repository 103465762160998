import { createReducer, on } from '@ngrx/store';
import * as EssentialsActions from './essentials.actions';
import { EssentialsState } from '@core/interfaces/essentials';

// Leer desde sessionStorage si ya existe un estado guardado
const storedEssentials = sessionStorage.getItem('essentials');
const storedError = sessionStorage.getItem('essentials_error');
const storedLoading = sessionStorage.getItem('essentials_loading');

export const initialState: EssentialsState = {
  essentials: storedEssentials ? JSON.parse(storedEssentials) : [],
  loading: storedLoading ? JSON.parse(storedLoading) : false,
  error: storedError ? JSON.parse(storedError) : false,  // Cargar el estado de error desde sessionStorage
};

const _essentialsReducer = createReducer(
  initialState,

  // Acción para establecer los essentials
  on(EssentialsActions.setEssentials, (state, { essentials }) => {
    sessionStorage.setItem('essentials', JSON.stringify(essentials)); // Persistir en sessionStorage
    return {
      ...state,
      essentials,
      loading: false,
      error: false, // Limpiar el error cuando se cargan los essentials correctamente
    };
  }),

  // Acción para limpiar los essentials
  on(EssentialsActions.clearEssentials, (state) => {
    sessionStorage.removeItem('essentials'); // Limpiar los essentials en sessionStorage
    sessionStorage.removeItem('essentials_loading'); // Limpiar el estado de loading
    sessionStorage.removeItem('essentials_error'); // Limpiar el estado de error
    return {
      ...state,
      essentials: [],
      loading: false,
      error: false,
    };
  }),

  // Manejar el estado de carga
  on(EssentialsActions.essentialsLoading, (state) => {
    sessionStorage.setItem('essentials_loading', JSON.stringify(true));  // Persistir loading
    return {
      ...state,
      loading: true,
      error: false,  // Limpiar el error cuando comienza la carga
    };
  }),

  // Manejar el estado de error
  on(EssentialsActions.essentialsError, (state) => {
    sessionStorage.setItem('essentials_error', JSON.stringify(true));  // Persistir error
    return {
      ...state,
      loading: false,
      error: true, // Establecer error en true cuando ocurre un error
    };
  }),

  // Acción para limpiar todo (incluyendo sessionStorage)
  on(EssentialsActions.clearAll, (state) => {
    sessionStorage.clear();  // Limpiar todo el sessionStorage
    return {
      ...initialState,  // Reiniciar el estado a los valores iniciales
    };
  })
);

export function essentialsReducer(state: EssentialsState | undefined, action: any) {
  return _essentialsReducer(state, action);
}
