import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/authentication/auth.service';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as ui from './core/store/ui/ui.actions';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LocalStorageService } from '@core/services/local-storage.service';
import { NavigationInterceptorService } from '@core/services/orders/navigationInterceptor.service';
import { OrderChangeService } from '@core/services/orders/orderChange.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading: Boolean = false;
  uiSubscription!: Subscription;
  isLoggedIn = false;
  hiddenHeader: boolean = false;
  hiddenHeaderSeller: boolean = false;
  user:any;
  appVersion = environment.appVersion;
  constructor(
    private store: Store<AppState>,
    private _authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private gtmService: GoogleTagManagerService,
    private navigationInterceptorService: NavigationInterceptorService,
    private orderChangeService: OrderChangeService
  ) {
    this.gtmService.addGtmToDom();

    router.events.subscribe((event: any) => {
      this.user = this.localStorageService.getItem('user');
      if (event.url) {
        // Verificar si el modal está activo
        if (this.orderChangeService.getButtonState()) {
          return; // Si el modal está activo, no cambiar la visibilidad del header
        }
        //ocultar rutas para nuevo header
        this.hiddenHeader =
          event.url == '/login' ||
            event.url == '/' ||
            event.url == '/unidades-de-negocio' ||
            event.url == '/error' ||
            event.url == '/404' ||
            event.url == '/500' ||
            event.url == '/503' ||
            event.url == '/504' ||
            event.url == '/seleccionar-cliente' ||
            event.url == '/main' ||
            event.url == '/main/inicio' ||
            event.url == '/main/cliente-seleccionado' ||
            event.url == '/detalle-rutinas' ||
            event.url == '/main/historial-compras' ||
            event.url.includes('/main/historial-compras/detalle-historial') ||
            event.url == '/main/cuenta-corriente' ||
            event.url == '/main/subir-pedido' ||
            event.url == '/main/avances-ventas' ||
            event.url == '/main/reconocimientos' ||
            event.url == '/main/aliados' ||
            event.url == '/main/ali-socios' ||
            event.url == '/main/los-infaltables' ||
            event.url == '/main/confirmacion' ||
            event.url.includes('/main/checkout')
            ? false
            : true;
        //Mostrar rutas para nuevo header
        this.hiddenHeaderSeller =
          (event.url == '/main' ||
            event.url == '/main/inicio' ||
            event.url == '/main/cliente-seleccionado' ||
            event.url == '/main/historial-compras' ||
            event.url == '/main/cuenta-corriente' ||
            event.url.includes('/main/historial-compras/detalle-historial') ||
            event.url == '/main/subir-pedido' ||
            event.url == '/main/avances-ventas' ||
            event.url == '/main/reconocimientos' ||
            event.url == '/main/aliados' ||
            event.url == '/main/confirmacion' ||
            event.url == '/main/ali-socios' ||
            event.url == '/main/los-infaltables' ||
            event.url.includes('/main/checkout') ||
            event.url.includes('/tienda/busqueda')) &&
          this.user.type === 'Vendedor'
            ? true
            : false;
      }
    });
  }

  ngOnInit(): void {
    this.initStore();
    this.manageSesion();
  }

  initStore() {
    this.uiSubscription = this.store
      .select('ui')
      .subscribe((ui) => (this.isLoading = ui.isLoading));
      this.checkVersionAndRedirect();
  }

  activeLoading() {
    this.store.dispatch(ui.isLoading());
  }

  deactiveLoading() {
    this.store.dispatch(ui.stopLoading());
  }

  ngOnDestroy(): void {
    this.uiSubscription.unsubscribe();
  }

  manageSesion(): void {
    if (window.addEventListener) {
      window.addEventListener('storage', (e) => {
        if (e.key == 'alicorp-user')
          if (!this._authService.isLoggedIn()) {
            this._authService.logout();
          }
      });
    }
  }

  checkVersionAndRedirect() {
    
    const storedVersion = localStorage.getItem('appVersion');

    if (storedVersion !== this.appVersion) {
      // Limpia el localStorage
      localStorage.clear(); // o tu método específico

      // Actualiza la versión
      localStorage.setItem('appVersion', this.appVersion);

      // Redirige al login
      this.router.navigate(['/login']);
    }
  }
}
