<section class="content-list-select scroll-hidden">
  <!-- Barra de navegación superior -->
  <nav class="navbar navbar-light bg-light p-0 content-nav">
    <div
      class="container d-flex justify-content-between align-items-center vw-100 container-list"
    >
      <!-- Logo -->
      <a class="navbar-brand" [routerLink]="'/seleccionar-cliente'">
        <img src="assets/svg/logo-new.svg" alt="Logo" />
      </a>

      <!-- Sección de perfil de usuario -->
      <div>
        <!-- Perfil en pantallas de escritorio -->
        <div class="content-img-perfil d-none d-md-block">
          <div class="img-user d-flex" [matMenuTriggerFor]="menuUser">
            <mat-icon>
              <img
                src="assets/image/selected-client-list/user-select.svg"
                alt="Icono de usuario"
                class="m-0 p-0"
              />
            </mat-icon>
          </div>
        </div>

        <!-- Perfil en pantallas móviles -->
        <div class="content-profile d-md-none">
          <mat-drawer-container class="hp-100 vw-100" autosize>
            <mat-drawer #drawerProfileOptions mode="over" position="end">
              <app-profile-responsive
                [optionsMenu]="options"
                [user]="user"
                [dateClients]="!!dateClients?.length"
                (closeModalProfile)="closeProfile($event)"
              ></app-profile-responsive>
            </mat-drawer>
            <mat-drawer-content>
              <div class="background-color">
                <div class="img-user d-flex" (click)="openProfile()">
                  <mat-icon>
                    <img
                      src="assets/image/selected-client-list/user-select.svg"
                      alt="Icono de usuario"
                      class="m-0 p-0"
                    />
                  </mat-icon>
                </div>
              </div>
            </mat-drawer-content>
          </mat-drawer-container>
        </div>
      </div>
    </div>
  </nav>

  <!-- Título y botón de descarga -->
  <div class="py-3 container container-body">
    <div class="container-download">
      <div class="d-flex align-items-center font-regular fts-14">
        <img
          src="assets/image/home-seller/icon-meeting.svg"
          height="20"
          alt="Icono de reunión"
          class="icon mr-8"
        />
        <h2
          class="font-bold-dali fts-24 fontw-700 m-0 h2-title show-desktop-tablet"
        >
          Bitácora de reuniones y planes
        </h2>
        <h2 class="font-bold-dali fts-24 fontw-700 m-0 h2-title show-mobile text-ellipsis">
          B. de reuniones y planes
        </h2>
      </div>
      <!-- <div class="container-download-button">
        <span class="mr-16 text-download fontw-600">Descárgate la bitácora:</span>
        <button class="btn-green p-12 font-regular fts-14 fontw-600" pButton>
          Descargar Excel
          <img src="assets/svg/icon-download.svg" alt="Icono de descarga" />
        </button>
      </div> -->
    </div>

    <!-- Banner e información -->
    <div
      class="container-imgs container-banner fts-16 font-regular mt-16 mb-16"
    >
      <div>
        <img
          src="assets/image/manage-order/banner-manage-order.svg"
          alt="Banner desktop"
          class="img-banner show-desktop-tablet"
        />
        <img
          src="assets/image/manage-order/banner-manage-order-mobile.svg"
          alt="Banner móvil"
          class="img-banner show-mobile pl-16"
        />
      </div>
      <div class="p-16 fts-14 font-regular line-height-16">
        Revisa tu bitácora de trabajo y registra tus nuevas iniciativas.
        <span class="font-bold-dali line-height-17"
          >Dale visibilidad a tus clientes de los acuerdos pactados</span
        >
        en este periodo, tus clientes podrán ver aquellos acuerdos en su Web de
        Clientes.
      </div>
    </div>

    <!-- Tabla de bitácora -->
    <div class="container-binnacle-meetings p-24">
      <div class="container-download vw-100">
        <div class="font-bold-dali fts-16">Reuniones, planes y acuerdos</div>
        <!-- Botones de guardar y cancelar al agregar una fila -->
        <ng-container *ngIf="isDataLoading; else buttonsAddSave">
          <div class="container-skeleton-button">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              [theme]="{
                height: '40px',
                width: '100%',
                'border-radius': '4px',
                'margin-bottom': '0px',
                display: 'flex'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </ng-container>
        <ng-template #buttonsAddSave>
          <!-- Botón de registrar -->
          <button
            *ngIf="!isAddingRow && !isRowBeingEdited() && !isServiceError"
            class="btn-back-new fts-14 font-semiBold d-flex align-items-center btn-add-custom"
            [ngClass]="{
              'btn-register-disabled': isActionRetry,
              'btn-add': !isActionRetry
            }"
            [disabled]="isActionRetry"
            (click)="addNewRow()"
          >
            Registrar
            <mat-icon class="ml-8">add</mat-icon>
          </button>
          <ng-container *ngIf="isAddingRow || isRowBeingEdited()">
            <div
              class="d-flex align-items-center justify-content-end btn-add-custom"
            >
              <button
                mat-flat-button
                class="btn-cancel d-flex justify-content-center align-items-center mr-16"
                (click)="isAddingRow ? cancelAddRow(true) : cancelEditRow()"
              >
                Cancelar
              </button>

              <button
                [matMenuTriggerFor]="menuIncompleteForm"
                class="btn-back-new fts-14 font-semiBold d-flex align-items-center"
                [ngClass]="{ 'btn-add': true }"
                [disabled]="false"
                #menuTriggerIncompleteForm="matMenuTrigger"
                (click)="
                  isAddingRow
                    ? saveRow($event, menuTriggerIncompleteForm)
                    : editSaveRow($event, menuTriggerIncompleteForm)
                "
              >
                Guardar registro
              </button>
            </div>
          </ng-container>
        </ng-template>
      </div>

      <hr class="dividing-line-solid my-3" />
      <!-- Contenedor de la tabla -->
      <div
        class="mt-16"
        [ngClass]="{
          'container-table-binnacle table-container': !isDataLoading && !isServiceError && dataSourceBinnacleList?.length > 0,
          'd-flex align-items-center justify-content-center table-skeleton-container': isDataLoading || (!isServiceError && (!dataSourceBinnacleList || dataSourceBinnacleList.length === 0)),
          'd-flex align-items-center justify-content-center error-container': isServiceError
        }"
      >
        <ng-container *ngIf="isDataLoading; else tableContent">
          <app-binnacle-skeleton></app-binnacle-skeleton>
        </ng-container>

        <ng-template #tableContent>
          <ng-container *ngIf="isServiceError; else noDataContent">
            <div class="w-28">
              <app-message-empty-error
                [messageError]="binnacleMessageError"
                (retryDataError)="getBinnacleDataList(1, 15)"
              ></app-message-empty-error>
            </div>
          </ng-container>

          <ng-template #noDataContent>
            <ng-container
              *ngIf="dataSourceBinnacleList?.length > 0; else emptyDataMessage"
            >
              <app-binnacle-table
                [dataSource]="dataSourceBinnacleList"
                [displayedColumns]="displayedColumns"
                [isAnyRowEditing]="isAnyRowEditing"
                [dateClients]="dateClients"
                [user]="user"
                [selectedClient]="selectedClient"
                [dataConvertClientSelect]="selectedClientCode"
                [rowErrors]="rowErrors"
                [isAddingRow]="isAddingRow"
                (editRow)="editRow($event)"
                (addNewRowEvent)="updatingValues($event)"
                (focusRow)="onFocus($event)"
                (blurRow)="onBlur($event)"
                (growRow)="autoGrow($event)"
                (isRowBeingEdited)="isRowBeingEdited()"
                (actionRetry)="
                  isActionRetry
                    ? saveRow($event, menuTriggerIncompleteForm)
                    : undefined
                "
                [isLoadingRegistrationService]="isLoadingRegistrationService"
                [isErrorRegistrationService]="isErrorRegistrationService"
              >
              </app-binnacle-table>
              <div></div>
            </ng-container>
            <ng-template #emptyDataMessage>
              <app-message-empty-error
                [messageError]="binnacleMessageNull"
              ></app-message-empty-error>
            </ng-template>
          </ng-template>
        </ng-template>
      </div>
      <div *ngIf="(dataSourceBinnacleListInitial?.totalPages > 1 && dataSourceBinnacleList?.length > 0)" class="mt-16">
        <app-paginator
          [length]="dataSourceBinnacleListInitial?.totalPages * 15"
          [pageSize]="15"
          [pageIndex]="dataSourceBinnacleListInitial?.currentPage - 1"
          (pageChange)="onPageChange($event)"
          displayType="desktop"
        ></app-paginator>
      </div>
    </div>
  </div>
</section>
<mat-menu #menuUser="matMenu">
  <app-profile
    [user]="user"
    [dateClients]="dateClients?.length ? true : false"
    (clientSelect)="clientSelect()"
  ></app-profile>
</mat-menu>
<mat-menu
  #menuIncompleteForm="matMenu"
  xPosition="before"
  yPosition="above"
  class="custom-menu-z-index mat-menu-incomplete"
>
  <div class="container-matmenu-delete">
    <div class="d-flex pb-3 align-items-center">
      <div class="icon-delete fts-18" emoji="⚠️">⚠️</div>
      <div class="font-regular fts-16 ml-8 line-height-20">
        Revisa tu información, aún tienes campos necesarios por completar.
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div>
        <button mat-raised-button color="accent" class="fts-14 font-semiBold">
          Entendido
        </button>
      </div>
    </div>
  </div>
</mat-menu>
