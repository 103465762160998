import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {
  DataSourceBinnacle,
  DataSourceBinnacleInitial,
  Note,
  NoteDelete,
} from '@core/interfaces/binnacle';
import { DetailRoutines, Routines, SelectedClient } from '@core/interfaces/selected-client';
import { BinnacleMeetingsService } from '@core/services/binnacle-meetings/binnacle-meetings.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SelectedClientService } from '@core/services/selected-client/selected-client.service';
import { map, take } from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/es';
import { CustomSnackBarComponent } from '@shared/components/custom-snack-bar/custom-snack-bar.component';
import {
  BinnacleMessageError,
  BinnacleMessageNull,
} from 'src/assets/data/errors-message';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ManageOrderModalComponent } from '@shared/components/modals/manage-order-modal/manage-order-modal.component';
import { MatSidenav } from '@angular/material/sidenav';
import { DialogCloseSesionComponent } from '@shared/components/modals/dialog-close-sesion/dialog-close-sesion.component';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store/app.reducer';
import * as authActions from '@core/store/auth/auth.actions';
import * as shoppingActions from '@core/store/shoppingCar/shoppingCar.actions';
import * as clientsActions from '@core/store/selectClient/selectClient.actions';
import * as EssentialsActions from '@core/store/essentials/essentials.actions';
import { AuthService } from '@core/services/authentication/auth.service';
import { BinnacleTableComponent } from './binnacle-table/binnacle-table.component';
@Component({
  selector: 'app-binnacle-meetings-plans',
  templateUrl: './binnacle-meetings-plans.component.html',
  styleUrls: ['./binnacle-meetings-plans.component.scss'],
})
export class BinnacleMeetingsPlansComponent implements OnInit {
  // Variables para la gestión de iconos y opciones del componente
  options: any;
  user: Array<any> | any;
  dateClients: Array<SelectedClient> = [];
  isAddingRow = false; // Controla el estado de adición de fila
  isActionRetry = false; // Controla el estado de adición de fila

  // Estado para saber si alguna fila está en modo edición
  isAnyRowEditing = false;
  activeRow: any = null;
  @ViewChild(MatMenuTrigger) myMenu!: MatMenuTrigger;
  @ViewChild('menuTriggerIncompleteForm', { static: true })
  menuTriggerIncompleteForm!: MatMenuTrigger;
  @ViewChild(BinnacleTableComponent) childComponent!: BinnacleTableComponent;
  clientList: any;
  filteredSelect = ['Opción 1', 'Opción 2', 'Opción 3'];

  loadingSkeleton = true;
  booleanMessageError = false;
  listResponseNull: any;
  selectedClient: any;
  selectedClientCode: any = ''; // Aquí se almacena el código del cliente seleccionado
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  // DataSource con datos de ejemplo
  dataSourceBinnacleList: DataSourceBinnacle[] = []; // Propiedad para almacenar los datos transformados
  dataSourceBinnacleListInitial!: DataSourceBinnacleInitial | null; // Propiedad para almacenar los datos transformados

  isDataLoading: boolean = true; // Estado de carga de datos
  binnacleMessageNull = [BinnacleMessageNull];
  binnacleMessageError = [BinnacleMessageError];
  isServiceError: boolean = false; // Bandera para indicar error en el servicio
  isLoadingRegistrationService: boolean = false;
  isErrorRegistrationService: boolean = false;
  successButton: string = 'Guardar registro';
  private retryCount = 0; // Contador para rastrear intentos
  private maxRetries = 4; //
  private pendingToggles: any;

  // Columnas que se mostrarán en la tabla
  displayedColumns: string[] = [
    'Visibilidad cliente',
    'Cliente',
    'Fecha de ejecución Inicio - Fin',
    'Estado',
    'Rutina',
    'Agenda',
    'Categoría',
    'Planes de acción / Actividades',
    'Responsable',
    'Área soporte',
    'Fecha de creación',
    'Notas del vendedor',
    'Notas del cliente',
    'acciones',
  ];

  rowErrors: { [key: string]: { [field: string]: boolean } } = {}; // Mapa de errores por fila
  @ViewChild('drawerProfileOptions')
  sidenavProfile!: MatSidenav;
  routinesList: DetailRoutines[] = [];
  routinesListLoading: boolean = false;
  routinesMessageError: boolean = false;
  selectRoutines?: DetailRoutines;
  constructor(
    private _localStorageService: LocalStorageService,
    private _selectedClientService: SelectedClientService,
    private cdRef: ChangeDetectorRef,
    private _binnacleMeetingsService: BinnacleMeetingsService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    public dialogCloseSesion: MatDialog,
    private store: Store<AppState>,
    private _authService: AuthService
  ) {
    // Registro de iconos SVG
  }

  ngOnInit() {
    this.initDataSelectclient();
    this.initDataRoutines();
    this.getBinnacleDataList(1, 15);

  }

  initDataSelectclient() {
    this.loadingSkeleton = true;
    this.user = this._localStorageService.getItem('user');
    this._selectedClientService
      .getSelectClient(this.user?.code)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.loadingSkeleton = false;
          this.booleanMessageError = false;
          this.listResponseNull = res;
          if (this.listResponseNull) {
            this.dateClients = res.map((element: any) => {
              const phoneNumber = element.phone ? element.phone : '_';
              const countryCode = phoneNumber?.slice(0, 3);
              const firstGroup = phoneNumber?.slice(3, 6);
              const secondGroup = phoneNumber?.slice(6, 9);
              const thirdGroup = phoneNumber?.slice(9);

              const separatedPhoneNumber = `${countryCode} ${firstGroup} ${secondGroup} ${thirdGroup}`;
              let temp: any = {
                ...element,
                isSelected: false,
                phone: separatedPhoneNumber,
              };
              return temp;
            });
            this.selectedClient = this.dateClients[0];
            this.selectedClientCode = this.selectedClient?.code;
          }
        },
        (error: any) => {
          this.booleanMessageError = true;
          this.loadingSkeleton = false;
        }
      );
  }
  initDataRoutines() {
    this.routinesList = [];
    this.routinesListLoading = true;
    this.routinesMessageError = false;
  
    this._selectedClientService
      .getRoutinesList(this.user?.code)
      .pipe(take(1))
      .subscribe(
        (res: Routines) => {
          // Asegúrate de que 'res.routines' sea un array de 'DetailRoutines'
          this.routinesList = res.routines || []; // Asignamos un array vacío si no existe 'routines'
          
          // Si es necesario, asigna selectRoutines como el primer item del array
          if (this.routinesList.length > 0) {
            this.selectRoutines = this.routinesList[0];
          }
  
          localStorage.setItem('lastFetchDate', new Date().toDateString());
          this.routinesListLoading = false;
        },
        () => {
          this.routinesListLoading = false;
          this.routinesMessageError = true;
        }
      );
  }
  
  getBinnacleDataList(pageNumber, pageSize) {
    this.isDataLoading = true;
    this.dataSourceBinnacleList = [];
    let dataBody = {
      sellerCode: this.getSellerCode(),
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    this._binnacleMeetingsService
      .getBinnacleList(dataBody)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.isDataLoading = false;
          this.isServiceError = false;
          if (data) {
            this.dataSourceBinnacleListInitial = data;
            this.dataSourceBinnacleList = this.transformBinnacleData(data);

          } else {
            this.dataSourceBinnacleList = [];
            this.dataSourceBinnacleListInitial = null;
          }
        },
        error: (err) => {
          this.isDataLoading = false;
          this.isServiceError = true; // Mostrar error
        },
      });
  }

  transformBinnacleData(serviceData: any): DataSourceBinnacle[] {
    return serviceData.binnacles.map((binnacle: any) => ({
      'Visibilidad cliente': binnacle.isVisible,
      // Lógica de asignación para 'Cliente' y 'ClienteOTRO'
      Cliente: binnacle.type === 'otros' ? 'otros' : binnacle.customerCode,  // Si 'type' es 'otros', Cliente será 'otros', si no, tomará el customerCode
      ClienteOTRO: binnacle.type === 'otros' ? binnacle.customerCode : null, // Si 'type' es 'otros', asignamos customerCode, si no, ponemos null
      type: binnacle.type,
      'Fecha de ejecución Inicio - Fin': {
        start: new Date(binnacle.startDate + 'T23:59:59Z'), // Añadir tiempo y zona horaria UTC
        end: new Date(binnacle.endDate + 'T23:59:59Z'),
      },
      Estado: binnacle.status,
      Rutina:binnacle?.routineId,
      RutinaName:binnacle.routineName,
      Agenda: binnacle.schedule,
      Categoría: binnacle.category,
      'Planes de acción / Actividades': binnacle.activities,
      Responsable: binnacle.responsible,
      'Área soporte': binnacle.supportArea,
      'Fecha de creación': new Date(binnacle.createdAt)
        .toISOString()
        .split('T')[0],
      'Notas del vendedor': binnacle.notes
        .filter((note: Note) => note.type === 'S')
        .map((note: Note) => ({
          id: note.id,
          type: note.type,
          note: note.note,
          createdAt: moment(note.createdAt).format('DD/MM/YYYY'),
          updatedAt: note.updatedAt,
        })),
      'Notas del cliente': binnacle.notes
        .filter((note: Note) => note.type === 'C')
        .map((note: Note) => ({
          id: note.id,
          type: note.type,
          note: note.note,
          createdAt: moment(note.createdAt).format('DD/MM/YYYY'),
          updatedAt: note.updatedAt,
        })),
      isExecutionDateEditable: binnacle.isExecutionDateEditable,
      isEditing: false,
      isNewRegister: false,
      nameClient: binnacle.customerName,
      idBinnacle: binnacle.id,
    }));
  }



  addNewRow() {
    this.successButton = 'Guardar registro';
    if (this.isAddingRow) return;
    this.retryCount = 0;
    this.isErrorRegistrationService = false;
    const newRow: DataSourceBinnacle = {
      'Visibilidad cliente': false,
      Cliente: '',
      type: '',
      routineId: "",
      'Fecha de ejecución Inicio - Fin': { start: null, end: null },
      Estado: 'Por iniciar',
      Rutina:'',
      Agenda: '',
      Categoría: '',
      'Planes de acción / Actividades': '',
      Responsable: '',
      'Área soporte': '',
      'Fecha de creación': '',
      'Notas del vendedor': [],
      'Notas del cliente': [],
      isEditing: false,
      isNewRegister: true,
    };

    this.dataSourceBinnacleList = [newRow, ...this.dataSourceBinnacleList];
    this.isAddingRow = true;
    this.isActionRetry = true;
    this.cdRef.detectChanges();
  }
  updatingValues(value: any) {
    // Encontrar el índice del último registro (nuevo) usando isNewRegister
    const index = this.dataSourceBinnacleList.findIndex(
      (row) => row.isNewRegister
    );
    if (index !== -1) {
      // Si es necesario, puedes validar nuevamente los campos después de la actualización
      const errors = this.validateRow(
        this.dataSourceBinnacleList[index],
        index,
        value.nameInput
      );
    }
  }

  saveRow(event: MouseEvent, menu: MatMenuTrigger) {
    const lastRow = this.dataSourceBinnacleList.find(
      (row) => row.isNewRegister === true
    );
    if (!lastRow) {
      return;
    }
    if(this.pendingToggles) {
      lastRow['Visibilidad cliente'] = this.pendingToggles.newState;
    }
    const rowId = this.dataSourceBinnacleList.indexOf(lastRow);

    const errors = this.validateRow(lastRow, rowId, 'Todas');
    if (
      Object.keys(errors).length > 0 &&
      Object.keys(errors).some((key) => Object.keys(errors[key]).length > 0)
    ) {
      return; // Salir si hay errores
    } else {
      if (event && typeof event.preventDefault === 'function') {
        event.preventDefault();
        event.stopPropagation();
      }
      if (this.menuTriggerIncompleteForm) {
        this.menuTriggerIncompleteForm.closeMenu();
      }
      if (menu && menu.menuOpen) {
        menu.closeMenu();
      }
    }
    this.isLoadingRegistrationService = true;
    this.isErrorRegistrationService = false;
    this.isAddingRow = false;
    const dataBodyRegitered = {
      sellerCode: this.getSellerCode(),
      customerCode: (lastRow['Cliente'] == 'otros' && lastRow['ClienteOTRO']) ? `${lastRow['ClienteOTRO']}` : lastRow['Cliente'],
      type: lastRow['type'],
      routineId: lastRow['Rutina'],
      startDate: moment(
        lastRow['Fecha de ejecución Inicio - Fin']?.start
      ).isValid()
        ? moment(lastRow['Fecha de ejecución Inicio - Fin']?.start).format(
          'YYYY-MM-DD'
        )
        : '',
      endDate: moment(lastRow['Fecha de ejecución Inicio - Fin']?.end).isValid()
        ? moment(lastRow['Fecha de ejecución Inicio - Fin']?.end).format(
          'YYYY-MM-DD'
        )
        : '',
      status: lastRow['Estado'] || 'Por iniciar',
      schedule: lastRow['Agenda'] || '',
      category: lastRow['Categoría'] || '',
      activities: lastRow['Planes de acción / Actividades'] || '',
      responsible: lastRow['Responsable'] || '',
      supportArea: lastRow['Área soporte'] || '',
      isVisible: lastRow['Visibilidad cliente'] || false,
      notes: (this.getReversedItems(lastRow['Notas del vendedor']) || []).concat(
        this.getReversedItems(lastRow['Notas del cliente']) || []
      ),
    };
    this._binnacleMeetingsService
      .getBinnacleSave(dataBodyRegitered)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.retryCount = 0; // Reinicia el contador en caso de éxito
          lastRow.isNewRegister = false;
          lastRow.isEditing = false;
          this.isLoadingRegistrationService = false;

          this.showSnackBar(
            'Se agregó un nuevo registro correctamente.', // Mensaje del éxito
            'Entendido', // Texto del botón
            'custom-snack-bar-check'
          );
          this.getBinnacleDataList(1, 15);
          this.isActionRetry = false; // Desactiva el intento de agregar fila

          this.cancelAddRow(false);
        },
        error: () => {
          this.retryCount++; // Incrementa el contador de intentos
          this.isLoadingRegistrationService = false;
          this.isErrorRegistrationService = true;

          if (this.retryCount < this.maxRetries) {
            // Mostrar mensaje de reintento
            this.showSnackBar(
              'No pudimos guardar tu nuevo registro.', // Mensaje del error
              'Reintentar', // Texto del botón
              'custom-snack-bar-error', // Clase personalizada
              () => this.saveRow(event, menu) // Acción de reintento
            );
          } else {
            // Mostrar mensaje final tras superar intentos máximos
            this.showSnackBar(
              'Lo sentimos no pudimos guardar tu registro, vuelve a intentarlo en unos minutos.', // Mensaje del error final
              'Entendido', // Texto del botón
              'custom-snack-bar-error-end'
            );
            this.retryCount = 0; // Reinicia el contador
            this.isActionRetry = false; // Desactiva la acción de reintento
            this.cancelAddRow(false); // Cancela la adición de fila
          }
        },
      });
  }

  getSellerCode(): string | null {
    const userData = localStorage.getItem('alicorp-user');

    if (userData) {
      const userObject = JSON.parse(userData);
      const code = userObject?.code;

      if (code) {
        return code; // Retorna el código si existe
      } else {
        return null; // Retorna null si no hay código
      }
    } else {
      return null; // Retorna null si no hay datos en el localStorage
    }
  }

  // Función para activar o desactivar el modo edición en una fila
  editRow(element: any) {
    this.successButton = 'Guardar cambios';
    // Si ya hay una fila activa (en edición), desactivar la edición
    if (this.activeRow && !this.activeRow.isNewRegister) {
      this.dataSourceBinnacleList.forEach((row) => {
        if (row !== element) {
          row.isEditing = false; // Desactivar la edición de otras filas
          row.isNewRegister = false; // Desactivar el nuevo registro en otras filas
        }
      });
    }

    // Si la fila ya está en edición, desactivarla
    if (this.activeRow === element) {
      element.isEditing = !element.isEditing;
      this.activeRow = null; // Limpiar la fila activa
    } else {
      // Si no había una fila activa, activar la edición de la fila seleccionada
      if (this.activeRow) {
        this.activeRow.isEditing = false; // Desactivar la fila previamente activa
      }
      element.isEditing = true; // Activar la fila seleccionada
      this.activeRow = element; // Establecer la fila activa
    }
  }

  getReversedItems(list: any[]): any[] {
    return list ? [...list].reverse() : [];
  }

  editSaveRow(event: MouseEvent, menu: MatMenuTrigger) {
    const lastRow = this.dataSourceBinnacleList.find(
      (row) => row.isEditing === true
    );
    if (!lastRow) {
      return;
    }
    if(this.pendingToggles) {
      lastRow['Visibilidad cliente'] = this.pendingToggles.newState;
    }
    const rowId = this.dataSourceBinnacleList.indexOf(lastRow);
    const errors = this.validateRow(lastRow, rowId, 'Todas');
    if (
      Object.keys(errors).length > 0 &&
      Object.keys(errors).some((key) => Object.keys(errors[key]).length > 0)
    ) {
      return; // Salir si hay errores
    } else {
      if (event && typeof event.preventDefault === 'function') {
        event.preventDefault();
        event.stopPropagation();
      }
      if (this.menuTriggerIncompleteForm) {
        this.menuTriggerIncompleteForm.closeMenu();
      }
      if (menu && menu.menuOpen) {
        menu.closeMenu();
      }
      this.isLoadingRegistrationService = true;
      this.isErrorRegistrationService = false;
      
      let body = {
        id: lastRow.idBinnacle,
        startDate: moment(
          lastRow['Fecha de ejecución Inicio - Fin']?.start
        ).isValid()
          ? moment(lastRow['Fecha de ejecución Inicio - Fin']?.start).format(
            'YYYY-MM-DD'
          )
          : '',
        endDate: moment(
          lastRow['Fecha de ejecución Inicio - Fin']?.end
        ).isValid()
          ? moment(lastRow['Fecha de ejecución Inicio - Fin']?.end).format(
            'YYYY-MM-DD'
          )
          : '',
        status: lastRow['Estado'],
        responsible: lastRow['Responsable'],
        supportArea: lastRow['Área soporte'],
        isVisible: lastRow['Visibilidad cliente'],
        // Filtrar las notas eliminadas (de tipo NoteDelete)
        deleteNotes: lastRow['Notas del vendedor']
          .filter((note: any) => note?.isDelete === true) // Filtramos las notas con isDelete
          .map((note: any) => ({
            id: note.id, // Solo tomamos el id de la nota eliminada
          })),

        // Filtrar las notas nuevas (de tipo Note)
        saveNotes: this.getReversedItems(lastRow['Notas del vendedor'])
          .filter((note: Note) => note?.isNew === true) // Filtramos las notas con isNew
          .map((note: Note) => ({
            type: 'S', // El tipo de la nota
            note: note.note, // El texto de la nota
          })),
      };
      // Set array vacio cuando una nota se abre y se cierra en la misma interacción
      if (
        body.deleteNotes.length === 1 &&
        Object.values(body.deleteNotes[0]).every((value) => value === undefined)
      ) {
        body.deleteNotes = [];
      }
      this._binnacleMeetingsService
        .getBinnacleEdit(body)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isLoadingRegistrationService = false;
            this.showSnackBar(
              'Se guardaron tus cambios correctamente.', // Mensaje del éxito
              'Entendido', // Texto del botón
              'custom-snack-bar-check'
            );
            this.getBinnacleDataList(1, 15);
            this.isActionRetry = false; // Desactiva el intento de agregar fila

            this.cancelAddRow(false);
          },
          error: () => {
            this.retryCount++; // Incrementa el contador de intentos
            this.isLoadingRegistrationService = false; // Detén el indicador de carga
            this.isErrorRegistrationService = true; // Activa la bandera de error

            if (this.retryCount < this.maxRetries) {
              // Mostrar mensaje de reintento
              this.showSnackBar(
                'No pudimos guardar tus cambios.', // Mensaje del error
                'Reintentar', // Texto del botón
                'custom-snack-bar-error', // Clase personalizada
                () => this.editSaveRow(event, menu) // Acción de reintento
              );
            } else {
              // Mostrar mensaje final tras superar intentos máximos
              this.showSnackBar(
                'Lo sentimos no pudimos guardar tus cambios, vuelve a intentarlo en unos minutos.', // Mensaje del error final
                'Entendido', // Texto del botón
                'custom-snack-bar-error-end'
              );
              this.retryCount = 0; // Reinicia el contador
              this.isActionRetry = false; // Desactiva la acción de reintento
              this.cancelEditRow(false); // Cancela la edición
            }
          },
        });
    }
  }
  // Función para verificar si el botón de editar debe estar deshabilitado
  isEditDisabled(element: any): boolean {
    // Deshabilitar si la fila está en edición o si es un nuevo registro
    return (
      (this.activeRow && this.activeRow !== element) || element.isNewRegister
    );
  }
  validateRow(
    lastRow: any,
    rowId: number,
    nameInput: string
  ): { [field: string]: boolean } {
    var localErrors: any; // Correción, declaramos un objeto

    localErrors = { ...this.rowErrors };

    // Si nameInput es 'Todas', validar todos los campos
    if (nameInput === 'Todas') {
      localErrors[rowId] = {};

      // Validar Cliente
      if (!lastRow['Cliente']) {
        localErrors[rowId]['Cliente'] = true;
      } else {
        delete localErrors[rowId]['Cliente']; // Eliminar error si el campo es corregido
      }

      // Validar fechas de ejecución
      const datesError = this.validateDates(lastRow);
      if (datesError) {
        localErrors[rowId]['Fecha de ejecución Inicio - Fin'] = true;
      } else {
        delete localErrors[rowId]['Fecha de ejecución Inicio - Fin'];
      }

        // Validar Rutina
        if (!lastRow['Rutina']) {
          localErrors[rowId]['Rutina'] = true;
        } else {
          delete localErrors[rowId]['Rutina']; // Eliminar error si el campo es corregido
        }

      // Validar Agenda
      const agenda = lastRow['Agenda']?.trim();
      if (!agenda || agenda.length < 1 || agenda.length > 1000) {
        localErrors[rowId]['Agenda'] = true;
      } else {
        delete localErrors[rowId]['Agenda']; // Eliminar error si el campo es corregido
      }

      // Validar Categoría
      const categoria = lastRow['Categoría']?.trim();
      if (!categoria || categoria.length < 1 || categoria.length > 1000) {
        localErrors[rowId]['Categoría'] = true;
      } else {
        delete localErrors[rowId]['Categoría']; // Eliminar error si el campo es corregido
      }

      // Validar Planes
      const planesAct = lastRow['Planes de acción / Actividades']?.trim();
      if (!planesAct || planesAct.length < 1 || planesAct.length > 1000) {
        localErrors[rowId]['Planes de acción / Actividades'] = true;
      } else {
        delete localErrors[rowId]['Planes de acción / Actividades']; // Eliminar error si el campo es corregido
      }

      // Validar Responsable
      const responsable = lastRow['Responsable']?.trim();
      if (!responsable || responsable.length < 1 || responsable.length > 1000) {
        localErrors[rowId]['Responsable'] = true;
      } else {
        delete localErrors[rowId]['Responsable']; // Eliminar error si el campo es corregido
      }

      // Validar Área soporte
      const area = lastRow['Área soporte']?.trim();
      if (!area || area.length < 1 || area.length > 1000) {
        localErrors[rowId]['Área soporte'] = true;
      } else {
        delete localErrors[rowId]['Área soporte']; // Eliminar error si el campo es corregido
      }

      this.rowErrors = { ...localErrors };
    } else {
      // Si rowId ya tiene errores registrados en localErrors
      if (rowId in localErrors) {
        // Validar el campo específico que se pasa
        localErrors = this.validateField(
          lastRow,
          rowId,
          nameInput,
          localErrors[rowId]
        );

        // Si no hay errores en este campo, eliminamos del objeto
        if (!localErrors[nameInput]) {
          delete localErrors[nameInput];
        }

        // Actualizar directamente los errores del rowId
        this.rowErrors[rowId] = { ...localErrors };
      } else {
        // Si no hay errores previos, inicializar con una validación
        localErrors[rowId] = this.validateField(lastRow, rowId, nameInput, {});

        // Si no se detectan errores después de validar, limpiar
        if (Object.keys(localErrors[rowId]).length === 0) {
          delete localErrors[rowId];
        } else {
          this.rowErrors[rowId] = { ...localErrors[rowId] };
        }
      }
    }
    return localErrors;
  }

  validateField(
    lastRow: any,
    rowId: number,
    nameInput: string,
    errors: { [field: string]: boolean }
  ) {
    switch (nameInput) {
      case 'Cliente':
        if (!lastRow['Cliente']) {
          errors['Cliente'] = true;
        } else {
          errors['Cliente'] = false;
        }
        break;
      case 'Fecha de ejecución Inicio - Fin':
        const datesError = this.validateDates(lastRow);
        if (datesError) {
          errors['Fecha de ejecución Inicio - Fin'] = true;
        } else {
          errors['Fecha de ejecución Inicio - Fin'] = false;
        }
        break;
        case 'Rutina':
          if (!lastRow['Rutina']) {
            errors['Rutina'] = true;
          } else {
            errors['Rutina'] = false;
          }
          break;
      case 'Agenda':
        const agenda = lastRow['Agenda']?.trim();
        if (!agenda || agenda.length < 1 || agenda.length > 1000) {
          errors['Agenda'] = true;
        } else {
          errors['Agenda'] = false;
        }
        break;
      case 'Categoría':
        const categoria = lastRow['Categoría']?.trim();
        if (!categoria || categoria.length < 1 || categoria.length > 1000) {
          errors['Categoría'] = true;
        } else {
          errors['Categoría'] = false;
        }
        break;
      case 'Planes de acción / Actividades':
        const planesAct = lastRow['Planes de acción / Actividades']?.trim();
        if (!planesAct || planesAct.length < 1 || planesAct.length > 1000) {
          errors['Planes de acción / Actividades'] = true;
        } else {
          errors['Planes de acción / Actividades'] = false;
        }
        break;
      case 'Responsable':
        const responsable = lastRow['Responsable']?.trim();
        if (
          !responsable ||
          responsable.length < 1 ||
          responsable.length > 1000
        ) {
          errors['Responsable'] = true;
        } else {
          errors['Responsable'] = false;
        }
        break;
      case 'Área soporte':
        const area = lastRow['Área soporte']?.trim();
        if (!area || area.length < 1 || area.length > 1000) {
          errors['Área soporte'] = true;
        } else {
          errors['Área soporte'] = false;
        }
        break;
    }
  
    return errors;
  }

  validateDates(element: any): boolean {
    const startDate = element['Fecha de ejecución Inicio - Fin'].start
      ? new Date(element['Fecha de ejecución Inicio - Fin'].start)
      : null;
    const endDate = element['Fecha de ejecución Inicio - Fin'].end
      ? new Date(element['Fecha de ejecución Inicio - Fin'].end)
      : null;

    // Si alguna de las fechas es null o inválida
    if (
      !startDate ||
      !endDate ||
      isNaN(startDate.getTime()) ||
      isNaN(endDate.getTime())
    ) {
      return true; // Indicar que hay un error
    }

    // Validar si la fecha inicial es mayor que la fecha final
    if (startDate > endDate) {
      return true; // Indicar que hay un error
    }

    return false; // No hay error
  }

  onPageChange(event: any) {
    const pageNumber = event + 1; // `page` en el evento es base 0, entonces sumamos 1
    const pageSize = 15; // `rows` es el número de elementos por página

    // Llamamos a la función para obtener los datos de la nueva página
    this.getBinnacleDataList(pageNumber, pageSize);
  }
  // Función para cancelar la edición de una fila
  cancelEdit() {
    if (this.activeRow) {
      this.activeRow.isEditing = false; // Desactivamos el modo edición
      this.activeRow = null; // Reseteamos la fila activa
    }
  }

  cancelAddRow(value) {
    if (value) {
      // Si el valor es verdadero, se abre el modal y se espera la respuesta
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.data = { status: 3 }; // Pasar los datos que necesites
      dialogConfig.width = '31rem';

      this.dialog
        .open(ManageOrderModalComponent, dialogConfig)
        .afterClosed()
        .pipe(
          map((result) => result === true) // Compara si la respuesta del modal es true
        )
        .subscribe((shouldDelete) => {
          if (shouldDelete) {
            // Si el modal devuelve `true`, eliminar la fila
            this.deleteRow();
          }
        });
    } else {
      this.deleteRow();
    }
  }

  cancelEditRow(value): void {
    if (value) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.data = { status: 4 }; // Pasar datos necesarios para el modal
      dialogConfig.width = '31rem';
      this.dialog
        .open(ManageOrderModalComponent, dialogConfig)
        .afterClosed()
        .pipe(
          map((result) => result === true) // Verificar si el resultado del modal es verdadero
        )
        .subscribe((shouldCancel) => {
          if (shouldCancel) {
            // Desactivar la edición sin eliminar la fila
            this.dataSourceBinnacleList?.forEach((item) => {
              if (item?.isEditing) {
                item.isEditing = false; // Desactiva el modo edición
              }
            });
            this.dataSourceBinnacleList = JSON.parse(
              JSON.stringify(this.childComponent.tempData)
            );
            this.pendingToggles?.clear();
            // Limpiar las referencias y estados
            this.activeRow = null;
            this.isAnyRowEditing = false;
            this.rowErrors = {};
            // Forzar la detección de cambios
            this.cdRef.detectChanges();
          }
        });
    } else {
      this.isErrorRegistrationService = false;
      // Desactivar la edición sin eliminar la fila
      this.dataSourceBinnacleList.forEach((item) => {
        if (item.isEditing) {
          item.isEditing = false; // Desactiva el modo edición
        }
      });
      this.dataSourceBinnacleList = JSON.parse(
        JSON.stringify(this.childComponent.tempData)
      );
      this.pendingToggles.clear();
      // Limpiar las referencias y estados
      this.activeRow = null;
      this.isAnyRowEditing = false;

      // Forzar la detección de cambios
      this.cdRef.detectChanges();
    }
  }

  deleteRow() {
    // Verifica si hay una fila en estado de adición
    if (this.isAddingRow || !this.isActionRetry || this.isRowBeingEdited()) {
      // Filtra la lista para eliminar cualquier fila nueva (`isNewRegister`)
      this.dataSourceBinnacleList = this.dataSourceBinnacleList.filter(
        (item) => !item.isNewRegister
      );

      // Resetea los estados de adición y la fila activa
      this.isAddingRow = false;
      this.isActionRetry = false;
      this.activeRow = null;
      this.rowErrors = {};
      // Forzar la detección de cambios para asegurar la sincronización
      this.cdRef.detectChanges();
    }
  }

  onFocus(textarea: HTMLTextAreaElement) {
    if (textarea) {
      textarea.style.height = 'auto'; // Reseteamos la altura
      textarea.style.height = `${textarea.scrollHeight}px`; // Ajustamos la altura al contenido
    }
  }

  onBlur(textarea: HTMLTextAreaElement) {
    if (textarea) {
      textarea.style.height = '40px'; // Restablecemos la altura mínima
    }
  }

  autoGrow(event: any) {
    const textarea = event.target;
    if (textarea) {
      textarea.style.height = 'auto'; // Reseteamos la altura
      textarea.style.height = `${textarea.scrollHeight}px`; // Ajustamos la altura según el contenido
    }
  }

  // Función para restablecer la altura del textarea
  resetTextareaHeight(textarea: HTMLTextAreaElement) {
    textarea.style.height = '40px'; // Restablece la altura mínima
  }

  // Función para abrir el perfil del usuario (vacía por ahora)
  openProfile() {
    this.sidenavProfile.toggle();
  }

  // Función para cerrar el perfil del usuario (vacía por ahora)
  closeProfile(event: string) {
    this.sidenavProfile.close();
    if (event === 'logout') {
      setTimeout(() => {
        this.openModalCloseSesion();
      }, 350);
    }
  }
  openModalCloseSesion() {
    const dialogRef = this.dialogCloseSesion.open(DialogCloseSesionComponent, {
      width: '31rem',
      data: {},
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data?.status) {
        this.store.dispatch(authActions.unsetUser());
        this.store.dispatch(shoppingActions.unsetCar());
        this.store.dispatch(clientsActions.unsetClients());
        this.store.dispatch(EssentialsActions.clearAll());
        this._authService.logout();
      }
    });
  }

  handleToggleConfirmed(data: any) {
    this.pendingToggles = data; // Guardar el elemento con cambio pendiente
  }

  // Función para abrir y cerrar el panel de selección (vacía por ahora)
  clientSelect() {
    this.myMenu.closeMenu();
  }
  // Método para verificar si existe una fila en edición
  isRowBeingEdited(): boolean {
    return this.dataSourceBinnacleList.some((row) => row.isEditing);
  }

  // Mostrar SnackBar con contenido personalizado
  showSnackBar(
    message: string,
    actionText: string,
    panelClass: string,
    actionCallback?: () => void // Callback opcional
  ): void {
    this._snackBar.openFromComponent(CustomSnackBarComponent, {
      data: { message, actionText, actionCallback },
      duration: 5000,
      panelClass: [panelClass],
    });
  }
}
