import { Component, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss'],
})
export class CustomSnackBarComponent {
  @Input() message: string = ''; // Mensaje del snack bar
  @Input() actionText: string = ''; // Texto del botón
  @Input() icon: string = ''; // Ruta del ícono
  @Input() loginInProcess: boolean = false; // Estado de carga

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<CustomSnackBarComponent>
  ) {
    if (data) {
      this.message = data.message;
      this.actionText = data.actionText;
    }
  }

  onActionClick(): void {
    if (this.data.actionCallback) {
      this.data.actionCallback(); // Ejecuta el callback si está definido
    }
    this.snackBarRef.dismiss(); // Cierra el Snackbar
  }
}
