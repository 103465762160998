import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { linksDetails } from '@core/interfaces/partners-ALI'; // Importación de la interfaz para los detalles de enlaces
import { PartnersALIService } from '@core/services/partners-ALI/partners-ALI.service'; // Servicio para manejar los datos de partners
import { Subscription, take } from 'rxjs'; // Operadores y tipos de datos de RxJS
import { filesAliPartners, levelsAliPartners, partnerNotMessages, remindersAliPartners } from 'src/assets/data/partners-ALI-fixed-data'; // Datos estáticos para los partners
import * as fs from 'file-saver'; // Librería para la descarga de archivos
import { LocalStorageService } from '@core/services/local-storage.service'; // Servicio para manejo de localStorage
import { MatSnackBar } from '@angular/material/snack-bar'; // Servicio para mostrar notificaciones
import { CustomSnackBarComponent } from '@shared/components/custom-snack-bar/custom-snack-bar.component'; // Componente para el SnackBar personalizado
import { Router } from '@angular/router'; // Servicio de navegación de Angular
import { TermsConditionsComponent } from '@shared/components/terms-conditions/terms-conditions.component'; // Componente de términos y condiciones

@Component({
  selector: 'app-partners-ALI',
  templateUrl: './partners-ALI.component.html',
  styleUrls: ['./partners-ALI.component.scss']
})
export class PartnersALIComponent implements OnInit, OnDestroy {

  // Datos iniciales
  levels = levelsAliPartners; // Niveles de clientes
  reminders = remindersAliPartners; // Recordatorios
  filesAliPartners = filesAliPartners; // Archivos de partners
  partnerNotMessages = partnerNotMessages; // Mensajes de partners

  // Suscripción de datos
  partnersALISuscription$!: Subscription;
  dataLink: linksDetails | undefined = undefined;

  // Estado de carga y datos del cliente
  loadingPDf = false;
  userDataSetting!: string | null;
  userDataSegment!: string | null;
  nameClient!: string;
  snackBarRef: any;
  showTermsConditions: boolean = false;
  selectorBelongs: boolean = false;

  // Constructor para inyectar dependencias
  constructor(
    private _partnersALIService: PartnersALIService,
    public _localStorageService: LocalStorageService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // Cargar datos del cliente dependiendo de la URL actual
    if (this.router.url === '/main/ali-socios') {
      const userInfo = this._localStorageService.getItem('clientSelect');
      this.nameClient = `${userInfo.name} - ${userInfo.ruc}`;
      this.userDataSetting = userInfo?.setting?.isPartner;
      this.userDataSegment = userInfo?.segment;
      this.selectorBelongs = userInfo?.sector == '0010';

    } else {
      const userInfo = this._localStorageService.getItem('user');
      this.nameClient = `${userInfo.name} - ${userInfo.code}`;
      this.userDataSetting = userInfo?.setting?.isPartner;
      this.userDataSegment = userInfo?.sectors?.find(sector => sector.sector == '0010')?.segment;
      this.selectorBelongs = this._localStorageService.getItem('sectorSelect')?.sector == '0010';
    }
    if (this.isUserDataValid()) {
      this.loadFiles(); // Cargar los archivos relacionados con el partner
    }
  }
  isUserDataValid(): boolean {
    return !!this.userDataSetting && this.selectorBelongs && ['B', 'A', 'A+', '1T'].includes(this.userDataSegment?.trim() ?? '');
  }

  // Mostrar el SnackBar de términos y condiciones
  showTermsConditionsSnackBar(): void {
    this.snackBarRef = this._snackBar.openFromComponent(
      TermsConditionsComponent,
      {
        data: {
          message: 'Términos y Condiciones',
          actionText: this.dataLink?.terms,
          customerCode: this.router.url === '/main/ali-socios' ? this._localStorageService.getItem('clientSelect')?.code : this._localStorageService.getItem('user')?.code,
          userType: this.router.url === '/main/ali-socios' ? 'seller' : 'client'
        },
        panelClass: ['terms-conditions-snackbar'],
      }
    );

    // Suscribirse al estado de aceptación de términos
    setTimeout(() => {
      if (this.snackBarRef.instance?.termsStatus$) {
        this.snackBarRef.instance.termsStatus$.subscribe((status: boolean) => {
          console.log('Estado de los términos:', status);
          this.showTermsConditions = status;
          this.cdr.detectChanges();
        });
      }
    });
  }

  // Cargar archivos del servicio de partners
  loadFiles() {
    this.partnersALISuscription$ = this._partnersALIService.getAliPartnersFiles().pipe(take(1)).subscribe({
      next: (res: any) => {
        this.dataLink = res;
        // Asignar las URLs de los archivos obtenidos
        if (this.dataLink) {
          this.filesAliPartners[0].url = this.dataLink.terms;
          this.filesAliPartners[1].url = this.dataLink.questions;
          this.filesAliPartners[2].url = this.dataLink.formats;
        } else {
          this.dataLink = undefined;
        }
        this.showTermsConditionsSnackBar();
      },
      error: (err: Error) => {
        this.dataLink = undefined; // Manejar error
        this.showTermsConditionsSnackBar();
      },
    });
  }

  // Obtener el nivel activo según el segmento del usuario
  obtenerNivelActivo() {
    return this.levels?.find(nivel => nivel.segment == this.userDataSegment) || { type: '', name: '', icon: '' };
  }

  openFileInNewWindow(file: any) {
    // Verificar si ya está en proceso de descarga
    if (file.loading) {
      return; // Evitar hacer clic si el archivo ya está en descarga
    }
    // Activar el loader para indicar que el archivo está descargando
    file.loading = true;
    // Verificar si la URL del archivo es válida
    if (!file.url || file.url.trim() === '') {
      file.loading = false;
      this.showSnackBar('Oops, ocurrió un error', 'X', 'custom-snack-bar-error');
      return;
    } else {


      // Descargar el archivo
      fs.saveAs(file.url, file.name);

      // Desactivar el loader después de la descarga
      setTimeout(() => {
        file.loading = false;
      }, 2000); // Simulamos el tiempo para completar la descarga
    }


  }


  // Abrir archivo PDF
  openFilePDF() {
    if (this.loadingPDf) {
      return; // Evitar que se ejecute si ya está en proceso de carga
    }

    this.loadingPDf = true; // Activar el loading
    if (this.dataLink?.benefits) {
      // Descargar el archivo PDF
      fs.saveAs(this.dataLink?.benefits, 'Beneficios');
      // Después de un tiempo, desactivar el loading (simulando tiempo de descarga)
      setTimeout(() => {
        this.loadingPDf = false;
      }, 2000);
    } else {
      this.showSnackBar('Oops, ocurrió un error', 'X', 'custom-snack-bar-error');
      this.loadingPDf = false; // Desactivar el loading si hay error
    }
  }


  // Abrir video en una nueva ventana
  openVideo() {
    if (this.dataLink?.video && this.dataLink.video.length > 0) {
      window.open(this.dataLink.video, '_blank');
    } else {
      this.showSnackBar('Oops, ocurrió un error', 'X', 'custom-snack-bar-error');
    }
  }

  // Mostrar un SnackBar personalizado
  showSnackBar(message: string, actionText: string, panelClass: string, actionCallback?: () => void): void {
    const snackBarRef = this._snackBar.openFromComponent(CustomSnackBarComponent, {
      data: { message, actionText, actionCallback },
      duration: 5000,
      panelClass: [panelClass],
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this.showTermsConditionsSnackBar();
    });
  }

  // Desuscribir la suscripción cuando el componente se destruye
  unsubscribeFromStorePartners() {
    if (this.partnersALISuscription$) {
      this.partnersALISuscription$.unsubscribe();
    }
  }

  // Lifecycle hook: ngOnDestroy
  ngOnDestroy(): void {
    this.unsubscribeFromStorePartners();
    // Cerrar todos los snackbars activos
    this._snackBar.dismiss();
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }
}
