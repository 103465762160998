<section class="show-desktop-tablet">
<section class="cards-container mt-16">
  <div
    class="card"
    *ngFor="let level of levels"
    [ngClass]="{
      'color-background-select': level?.segment == nivelActivo,
      'background-color-card mt-56': level?.segment !== nivelActivo,
      'color-background-diamond': level?.type == 'Socio Diamante'
    }"
  >
    <div
      class="d-flex align-items-center justify-content-center select-level color-client-background"
      *ngIf="level?.segment == nivelActivo"
    >
      <img
        src="/assets/image/partners-ali/icon-people.svg"
        class="mr-8"
      />
      <p
        class="m-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark"
      >
        Mi nivel actual
      </p>
    </div>
    <div class="p-32">
      <div class="d-flex justify-content-start align-items-end mb-32">
        <img [src]="level.iconMedal" alt="{{ level.type }}" />
        <p
          class="m-0 p-0 font-bold-dali fts-20 ta-s ml-8 line-height-24 medal-title"
        >
          {{ level.medalTitle }}
        </p>
      </div>
      <div
        *ngFor="let description of level?.description"
        class="ta-s ml-16"
      >
        <ul class="list m-0 p-0 font-regular fts-16">
          <li class="ta-s mb-8">{{ description }}</li>
        </ul>
      </div>
    </div>
    <div
      class="ml-32 mr-32 mb-32 line-height-16 initial-level"
      *ngIf="level?.type == 'Socio Bronce'"
    >
      <p
        class="ml-0 mr-0 mb-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark mt-16"
      >
        Nivel inicial
      </p>
    </div>
  </div>
</section>
</section>
<section class="show-mobile">
  <p-carousel 
    [value]="levels" 
    [numVisible]="1" 
    [numScroll]="1" 
    [autoplayInterval]="false" 
    [circular]="true" 
    [page]="selectedIndex"
    class="custom-carousel">
  
    <ng-template pTemplate="item" let-level>
      <div 
        class="card"
        [ngClass]="{
          'color-background-select select-card': level?.segment == nivelActivo,
          'background-color-card select-not-card mt-56': level?.segment !== nivelActivo,
          'color-background-diamond': level?.type == 'Socio Diamante'
        }">
        
        <!-- Contenido de la tarjeta -->
        <div class="d-flex align-items-center justify-content-center select-level color-client-background" *ngIf="level?.segment == nivelActivo">
          <img src="/assets/image/partners-ali/icon-people.svg" class="mr-8" />
          <p class="m-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark">Mi nivel actual</p>
        </div>
        
        <div class="p-32">
          <div class="d-flex justify-content-start align-items-end mb-32">
            <img [src]="level.iconMedal" alt="{{ level.type }}" />
            <p class="m-0 p-0 font-bold-dali fts-20 ta-s ml-8 line-height-24 medal-title">{{ level.medalTitle }}</p>
          </div>
          <div *ngFor="let description of level?.description" class="ta-s ml-16">
            <ul class="list m-0 p-0 font-regular fts-16">
              <li class="ta-s mb-8">{{ description }}</li>
            </ul>
          </div>
        </div>
        
        <div class="ml-32 mr-32 mb-32 line-height-16 initial-level" *ngIf="level?.type == 'Socio Bronce'">
          <p class="ml-0 mr-0 mb-0 p-0 font-bold-dali fts-14 line-height-16 color-support-positive-dark mt-16">Nivel inicial</p>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</section>

