
export const levelsAliPartners = [
  {
    type: 'Socio Bronce',
    segment:'B',
    name: '',
    icon: '/assets/image/partners-ali/avatar-bronce.svg', // Ruta de la imagen SVG para Socio Bronce
    iconMedal: '/assets/image/partners-ali/medal-bronce.svg',
    medalTitle: 'Ali Socio Bronce',
    description: [ 'Atención directa por Alicorp.']
  },
  {
    type: 'Socio Plata',
    segment:'A',
    name: '',
    icon: '/assets/image/partners-ali/avatar-plata.svg', // Ruta de la imagen SVG para Socio Plata
    iconMedal: '/assets/image/partners-ali/medal-plata.svg',
    medalTitle: 'Ali Socio Plata',
    description: [
      'Acumula puntos por tus compras.',
      'Agasajo por cumpleaños.',
      'Mercaderista.',
      'Acceso a materiales para destacar tus productos.'
    ]
  },
  {
    type: 'Socio Oro',
    segment:'A+',
    name: '',
    icon: '/assets/image/partners-ali/avatar-oro.svg',// Ruta de la imagen SVG para Socio Oro
    iconMedal: '/assets/image/partners-ali/medal-oro.svg',
    medalTitle: 'Ali Socio Oro',
    description: [
      'Acumula puntos por tus compras.',
      'Agasajo por cumpleaños.',
      'Mercaderista y Personal Multifunción',
      'Acceso a elementos para destacar tus productos.'
    ]
  },

  {
    type: 'Socio Diamante',
    segment:'1T',
    name: '',
    icon: '/assets/image/partners-ali/avatar-diamond.svg', // Ruta de la imagen SVG para Socio Estrella
    iconMedal: '/assets/image/partners-ali/medal-diamond.svg',
    medalTitle: 'Ali Socio Diamante',
    description: [
      'Acumula puntos por tus compras.',
      'Agasajo por cumpleaños.',
      'Mercaderista, Personal Multifunción y Personal de Apoyo logístico',
      'Acceso a elementos para destacar tus productos.',
      'Asesor exclusivo para atender consultas y reclamos.'
    ]
  }
];
export const remindersAliPartners = [
  { icon: '/assets/image/partners-ali/icon-calendar_purple.svg', text: 'Recuerda que tienes la opción de subir a un nivel más alto al inicio de cada año.' },
  { icon: '/assets/image/partners-ali/icon-people_purple.svg', text: '<span class="font-semiBold">Consulta con tu asesor de ventas</span> los objetivos que debes alcanzar para subir de nivel.' },
  { icon: '/assets/image/partners-ali/icon-statistics_purple.svg', text: 'A mayores compras mayores posibilidades de acceder a un nivel más alto con mayores beneficios.' }


];
export const filesAliPartners = [
  {id:1, name: 'Términos y condiciones', icon: '/assets/svg/icon-download.svg' ,url:'',loading: false},
  {id:2, name: 'Preguntas y respuestas', icon: '/assets/svg/icon-download.svg' ,url:'',loading: false},
  {id:3, name: 'Formatos de Visibilidad', icon: '/assets/svg/icon-download.svg' ,url:'',loading: false}
];

//--------DATOS DE TABLA DE ALI PARTNERS INICIO------
export const benefitsTitle = 'Beneficios';
export const sectionAvailableText = 'Secciones disponibles:';

export const levelsTitle = 'Niveles - Ali Socios';
export const levelsTitleMobile = 'Niveles';
export const levelImages = [
  { id: 1, src: '/assets/image/partners-ali/icon-badge-bronce.svg', alt: 'Ali Socios bronce', text: 'Ali Socio Bronce' },
  { id: 2, src: '/assets/image/partners-ali/icon-badge-plata.svg', alt: 'Ali Socios plata', text: 'Ali Socio Plata' },
  { id: 3, src: '/assets/image/partners-ali/icon-badge-oro.svg', alt: 'Ali Socios oro', text: 'Ali Socio Oro' },
  { id: 4, src: '/assets/image/partners-ali/icon-badge-diamond.svg', alt: 'Ali Socios diamante', text: 'Ali Socio Diamante' }
];
export const currentLevelTitle = 'Mi nivel actual';
export const iconPeople = { src: '/assets/image/partners-ali/icon-people.svg', alt: 'icon people' };

//-----DATOS DE TABLA DE ALI PARTNERS END--------
export const dataTable = {
  allies: [
    {
      title: 'Aliados',
      icon: '/assets/image/partners-ali/icon-heart_green.svg',
      descriptionText1: 'Acumula puntos con cada compra y canjéalos por increíbles premios del catálogo o premios personalizados.<br><br>Aplican términos y condiciones.',
      descriptionText2: '<span class="fts-16 font-bold-dali color-title-custom">Puntos</span><br>Todas tus compras de productos CORE* acumulan puntos y si las realizas por la web acumularán el doble.<br><br>Revisa el detalle en los términos y condiciones, los montos incluyen IGV. ',
      descriptionText3: '<span class="fts-16 font-bold-dali color-title-custom">Agasajo</span><br>¡Feliz cumpleaños! Te enviaremos un regalo para celebrar este día tan especial.',
      partnersTypes: [
        {
          id: 1,
          levelType: 'Bronce',
          levelTypeSelect: false,
          descriptionPoints: 'X',
          descriptionCrouch: 'X'
        },
        {
          id: 2,
          levelType: 'Plata',
          levelTypeSelect: false,
          descriptionPoints: '<span class="font-bold-dali">Acumula 1 punto por cada S/ 150</span> de compra realizada por la web.<br><br>Acumula 1 punto por cada S/ 300 de compra realizada a través de tu vendedor.',
          descriptionCrouch: 'Regalo:<br>Torta de cumpleaños.'
        },
        {
          id: 3,
          levelType: 'Oro',
          levelTypeSelect: false,
          descriptionPoints: '<span class="fts-16 font-bold-dali">Acumula 1 punto por cada S/ 100</span> de compra realizada por la web.<br><br>Acumula 1 punto por cada S/ 200 de compra realizada a través de tu vendedor.',
          descriptionCrouch: 'Regalo:<br>Torta de cumpleaños + vale de S/ 150.'
        },
        {
          id: 4,
          levelType: 'Diamante',
          levelTypeSelect: false,
          descriptionPoints: '<span class="fts-16 font-bold-dali">Acumula 1 punto por cada S/ 50</span> de compra realizada por la web.<br><br>Acumula 1 punto por cada S/ 100 de compra realizada a través de tu vendedor.',
          descriptionCrouch: 'Regalo:<br>Torta de cumpleaños + vale de S/ 300.'
        }]
    }
  ],
  visibility: [
    {
      title: 'Visibilidad',
      icon: '/assets/image/partners-ali/icon-visibility_green.svg',
      descriptionText1: 'Accede a nuestros elementos de visibilidad (gancheras, exhibidores, entre otros) diseñados para destacar los productos Alicorp en tu negocio y crear un ambiente más atractivo y organizado.<br><br>Aplican términos y condiciones.',
      descriptionText2: '<span class="fts-16 font-bold-dali color-title-custom">Materiales Publicitarios</span><br>Recibirás material publicitario de nuestras marcas. Esto optimizará la experiencia de tus clientes y fortalecerá la presencia de las marcas en tu punto de venta.<br><br>Frecuencia variada.',
      descriptionText3: '<span class="fts-16 font-bold-dali color-title-custom">Merchandising para tus clientes</span><br>Recibirás productos promocionales de nuestras marcas (por ejemplo: polo, gorro, mandiles, bloc, etc) que podrás regalar a tus clientes.<br><br>Frecuencia variada.',
      descriptionText4: '<span class="fts-16 font-bold-dali color-title-custom">Materiales Permanentes</span><br>A inicio de año podrás escoger de nuestro catálogo de materiales como portafiche, colgantes, gancheras, entre otros, y te lo entregaremos en el segundo trimestre del año.<br><br>1 vez al año.',
      partnersTypes: [
        {
          id: 1,
          levelType: 'Bronce',
          levelTypeSelect: false,
          descriptionPoints: 'X',
          descriptionCrouch: 'X',
          descriptionMerchant: 'X'
        },
        {
          id: 2,
          levelType: 'Plata',
          levelTypeSelect: false,
          descriptionPoints: 'Mínimo 1  paquete de material publicitario al trimestre.',
          descriptionCrouch: 'X',
          descriptionMerchant: 'X'
        },
        {
          id: 3,
          levelType: 'Oro',
          levelTypeSelect: false,
          descriptionPoints: 'Mínimo 1  paquete de material publicitario al trimestre.',
          descriptionCrouch: 'Mínimo 1  paquete de material de merchadising al trimestre.',
          descriptionMerchant: 'Tienes la opción de elegir 2 materiales: ganchera, display, glorificador, porta afiche colgante.'
        },
        {
          id: 4,
          levelType: 'Diamante',
          levelTypeSelect: false,
          descriptionPoints: 'Mínimo 1  paquete de material publicitario al trimestre.<br><br>Opción a recibir mayor cantidad de materiales publicitarios.',
          descriptionCrouch: 'Mínimo 1  paquete de material de merchadising al trimestre.<br><br>Opción a recibir mayor cantidad de materiales.',
          descriptionMerchant: 'Tienes la opción de elegir 3 materiales: Porta Afiche de piso, porta afiche colgante, ganchera, display, glorificador, exhibidor, mueble.'
        }]
    }
  ],
  Execution: [
    {
      title: 'Personal de Ejecución',
      icon: '/assets/image/partners-ali/icon-execution_green.svg',
      descriptionText1: 'Ayudamos a impulsar tus ventas con la presencia de personal tercerizado por Alicorp en tu punto de venta.<br><br>Aplican términos y condiciones.',
      descriptionText2: '<span class="fts-16 font-bold-dali color-title-custom">Personal de Apoyo logístico</span><br><ul class="pl-24 pr-0 pt-0 pb-0"><li>Apoyo en la recepción y despacho de mercadería de Alicorp</li><li>Control de inventarios</li><li>Relevo de días giro.</li></ul>',
      descriptionText3: `<span class="fts-16 font-bold-dali color-title-custom">Personal Multifunción</span>
      <ul class="pl-24 pr-0 pt-0 pb-0">
        <li>Impulso de ventas</li>
        <li>Apoyo en el mantenimiento de la exhibición y visibilidad de productos y material publicitario</li>
        <li>Reporte de movimientos de mercado (actividades, precios, promociones).</li>
      </ul>
    `, descriptionText4: `<span class="fts-16 font-bold-dali color-title-custom">Mercaderista</span>
    <ul class="pl-24 pr-0 pt-0 pb-0">
      <li>Ubicación y mantenimiento de la exhibición de surtido</li>
      <li>Implementación de elementos de visibilidad</li>
      <li>Mantenimiento de material POP.</li>
    </ul>
  `,
      partnersTypes: [
        {
          id: 1,
          levelType: 'Bronce',
          levelTypeSelect: false,
          descriptionPoints: 'X',
          descriptionCrouch: 'X',
          descriptionMerchant: 'X'
        },
        {
          id: 2,
          levelType: 'Plata',
          levelTypeSelect: false,
          descriptionPoints: 'X',
          descriptionCrouch: 'X',
          descriptionMerchant: '1 persona asignada con visita de 2 horas 3 días a la semana.'
        },
        {
          id: 3,
          levelType: 'Oro',
          levelTypeSelect: false,
          descriptionPoints: 'X',
          descriptionCrouch: '1 persona asignada con jornada 8 horas de lunes a viernes y 4 horas los sábados.',
          descriptionMerchant: '1 persona asignada con visita de 2 horas 3 días a la semana.'
        },
        {
          id: 4,
          levelType: 'Diamante',
          levelTypeSelect: false,
          descriptionPoints: '1 persona asignada con jornada 8 horas de lunes a viernes y 4 horas los sábados.',
          descriptionCrouch: '1 persona asignada con jornada 8 horas de lunes a viernes y 4 horas los sábados.',
          descriptionMerchant: '1 persona asignada con visita de 2 horas 3 días a la semana.'
        }]
    }
  ],
  Support: [
    {
      title: 'Post Venta',
      icon: '/assets/image/partners-ali/icon-support_green.svg',
      descriptionText1: 'Accede a la atención preferencial de tus consultas y/o reclamos de post venta con nuestros asesores dedicados a tu negocio.<br><br>Aplican términos y condiciones.',
      descriptionText2: '<span class="fts-16 font-bold-dali color-title-custom">Asesoría de soporte y atención</span><br>Tendrás un asesor dedicado a atender las solicitudes de post venta de tu negocio, desde consultas hasta reclamos.<br><br>Horario de atención:<br>Lunes a Viernes de 8 a.m. a 1 p.m. y de 2 p.m. a 6 p.m. / Sábados de 9 a.m. a 12 p.m. (No incluye feriados).',
      partnersTypes: [
        {
          id: 1,
          levelType: 'Bronce',
          levelTypeSelect: false,
          descriptionPoints: 'X'
        },
        {
          id: 2,
          levelType: 'Plata',
          levelTypeSelect: false,
          descriptionPoints: 'X'
        },
        {
          id: 3,
          levelType: 'Oro',
          levelTypeSelect: false,
          descriptionPoints: 'X',
        },
        {
          id: 4,
          levelType: 'Diamante',
          levelTypeSelect: false,
          descriptionPoints: '1 Asesor dedicado a la atención preferencial de tus consultas y/o reclamos.'
        }]
    }
  ]
}
export const partnerNotMessages = {
  imageUrl: '/assets/image/partners-ali/partners-not.svg',
  altText: 'No eres ALI Socios',
  messageTitle: 'No eres parte del programa',
  messageDescription: 'Lo sentimos, no eres parte del programa de beneficios, si crees que es un error comunícate con tu asesor de ventas.'
};