<section class="d-flex">
  <div class="content-dialog">
    <div class="button-x justify-content-end d-flex">
      <a
        mat-icon-button
        class="button-x-style custom-button m-0 p-0 d-flex align-items-center justify-content-center"
        (click)="closeDialog()"
      >
        <span
          class="material-symbols-rounded d-flex align-items-center justify-content-center fst-32"
        >
          close
        </span>
      </a>
    </div>
    <div
      class="ta-c d-flex justify-content-center align-items-center img-container fts-56"
    >
      <div>{{ modals[dataRecieved.status].img }}</div>
    </div>
    <div class="title-suport ta-c p-2">
      <h1 class="font-bold-dali line-height-40 mt-8 mb-16 h1-title">
        {{
          this.dataRecieved.message
            ? this.dataRecieved.message
            : modals[dataRecieved.status].title
        }}
      </h1>
    </div>
    <div class="ta-c">
      <p
        class="m-0 font-regular color-subTitle-custom fts-14"
        [innerHTML]="modals[dataRecieved.status].description"
      ></p>
    </div>
    <div
      class="content-button-close d-flex justify-content-center vvw-100 mt-24 mb-8"
    >
      <div
        class="content-button-close_button w-100 align-items-center buttons"
        [ngClass]="modals[dataRecieved.status]?.cssClass ? modals[dataRecieved.status].cssClass : 'd-flex justify-content-between'"
      >
        <button
          color="accent"
          *ngFor="let action of modals[dataRecieved.status].actions"
          class="font-semiBold  ph-24 d-flex align-items-center fts-16"
          [ngClass]="action.class"
          (click)="action.event()"
        >
          {{ action.name }}
        </button>
      </div>
    </div>
  </div>
</section>
