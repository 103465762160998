<div class="modal-body">
  <div *ngIf="data.routine.activities.length != 0; else nullRoutine">
    <div class="container header-container">
      <div class="modal-title font-bold-dali">
        {{ data.routine.position }}. {{ data.routine.name }}
      </div>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <img src="assets/image/modal/close-modal.svg" alt="alert" />
      </button>
      <div class="sub-header-container">
        <div class="activities-count">
          <p class="activities-p">
            Esta rutina tiene
            <span class="font-bold-dali">{{ data.routine.activities.length }}</span>
            <span class="font-bold-dali" *ngIf="data.routine.activities.length > 1">
              actividades.</span
            >
            <span class="font-bold-dali" *ngIf="data.routine.activities.length == 1">
              actividad.</span
            >
          </p>
        </div>
        <div class="repository-link">
          <span class="font-bold-dali">Mi repositorio:</span>
          <button
            class="btn-green p-12 font-book fts-14"
            pButton
            (click)="goToUrl(data.routines.linkSharepoint)"
          >
            Ir al sharepoint
            <img
              src="assets/image/home-seller/icon-redirect.svg"
              class="ml-8"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="content-container-desktop">
      <div class="activities-container">
        <div class="activities-title font-bold-dali">
          <img src="assets/image/modal/activities.svg" alt="activities" />
          Actividades
        </div>
        <!-- Contenido del contenedor de actividades -->
        <div
          class="activitie-list"
          *ngFor="let activitie of data.routine.activities; let i = index"
        >
          <div>
            <span class="font-bold-dali">{{i+1}}. </span
            ><span [innerHTML]="processText(activitie.name)"></span>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="format-container">
        <div class="format-title font-bold-dali">
          <img src="assets/image/modal/format.svg" alt="format" /> Formatos para
          esta rutina
        </div>
        <!-- Contenido del contenedor de rutinas -->
        <ng-container
          *ngFor="let activitie of data.routine.activities; let i = index"
        >
          <div *ngIf="activitie.formatName" class="format-list">
            <div>{{ activitie.formatName }}</div>
            <button
              class="btn-red p-12 fts-14 font-bold-dali"
              pButton
              (click)="goToUrlOpen(activitie, data.routine)"
            >
              Abrir
              <svg
			  	class="ml-8"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.04262 12.7445H11.0098C11.5344 12.7445 12.0066 12.3642 12.0066 11.7609V6.85597H10.4984V11.3674H1.5541V2.63302H6.23606V1.25597H1.04262C0.51803 1.25597 0.0458984 1.6363 0.0458984 2.23958V11.7609C0.0458984 12.3642 0.51803 12.7445 1.04262 12.7445ZM12.3607 1.58384L11.8492 4.95433L10.6164 4.7445C10.7738 3.82646 10.7607 3.35433 11.3508 2.47565L7.6 6.22647L6.76065 5.38712L10.5115 1.6363C9.63278 2.22647 9.17377 2.22647 8.30819 2.39696L8.09836 1.16417L11.4033 0.626465L12.3607 1.58384Z"
                />
              </svg>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="content-container-mobile">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
        <mat-tab label="Actividades">
          <div class="cont">
            <div class="activities-title font-bold-dali">
              <img src="assets/image/modal/activities.svg" alt="activities" />
              Actividades
            </div>
            <!-- Contenido del contenedor de actividades -->
            <div
              class="activitie-list"
              *ngFor="let activitie of data.routine.activities; let i = index"
            >
              <div>
                <span class="font-bold-dali">{{i+1}}. </span
                ><span [innerHTML]="processText(activitie.name)"></span>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Formatos">
          <div class="cont">
            <div class="format-title font-bold-dali">
              <img src="assets/image/modal/format.svg" alt="format" /> Formatos
              para esta rutina
            </div>
            <!-- Contenido del contenedor de rutinas -->
            <ng-container
              *ngFor="let activitie of data.routine.activities; let i = index"
            >
              <div *ngIf="activitie.formatName" class="format-list">
                <div>{{ activitie.formatName }}</div>
                <button
                  class="btn-red p-12 fts-14 font-bold-dali"
                  pButton
                  (click)="goToUrlOpen(activitie, data.routine)"
                >
                  Abrir
                  <svg
				  	class="ml-8"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.04262 12.7445H11.0098C11.5344 12.7445 12.0066 12.3642 12.0066 11.7609V6.85597H10.4984V11.3674H1.5541V2.63302H6.23606V1.25597H1.04262C0.51803 1.25597 0.0458984 1.6363 0.0458984 2.23958V11.7609C0.0458984 12.3642 0.51803 12.7445 1.04262 12.7445ZM12.3607 1.58384L11.8492 4.95433L10.6164 4.7445C10.7738 3.82646 10.7607 3.35433 11.3508 2.47565L7.6 6.22647L6.76065 5.38712L10.5115 1.6363C9.63278 2.22647 9.17377 2.22647 8.30819 2.39696L8.09836 1.16417L11.4033 0.626465L12.3607 1.58384Z"
                    />
                  </svg>
                </button>
              </div>
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <ng-template #nullRoutine>
    <div class="container header-container-error">
      <div class="modal-title font-bold-dali">
        {{ data.routine.position }}. {{ data.routine.name }}
      </div>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <img src="assets/image/modal/close-modal.svg" alt="alert" />
      </button>
    </div>
    <div class="text-error-container ta-c pb-16 pt-16">
      <div class="text-error">
        <app-message-empty-error
        [messageError]="messageClientNull"
        ></app-message-empty-error>
      </div>
    </div>
  </ng-template>
  <div class="modal-footer">
  <div *ngIf="data.routine.activities.length == 0" class="divider-horizontal"></div>
    <app-paginator
      [length]="data.routines.routines.length"
      [pageSize]="pageSize"
      [pageIndex]="data.routine.position - 1"
      (pageChange)="onPageChange($event)"
      displayType="desktop"
    >
    </app-paginator>
  </div>
</div>