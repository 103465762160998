import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-essentials-card-skeleton',
  templateUrl: './essentials-card-skeleton.component.html',
  styleUrls: ['./essentials-card-skeleton.component.scss'],
})
export class InfaltablesCardSkeletonComponent implements OnInit {
  constructor(
  ) {}

  ngOnInit() {
    
  }
 
}
