import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotFoundGuard implements CanLoad {
  public allowedRoutesForVendedor = [
    'main',
    'main/inicio',
    'tienda',
    'main/cliente-seleccionado',
    'main/historial-compras',
    'main/historial-compras/detalle-historial',
    'main/subir-pedido',
    'main/cuenta-corriente',
    'main/confirmacion',
    'main/checkout',
    'main/avances-ventas',
    'main/aliados',
    'main/aliados/avance',
    'main/ali-socios',
    'main/los-infaltables',
    'main/reconocimientos',
    'seleccionar-cliente',
    'detalle-rutinas',
    'tienda/busqueda',
    'main/historial-compras/detalle-historial/gestiona-tu-pedido',
    'formulario/*'
  ];

  // Lista de rutas permitidas para el usuario tipo 'Cliente'
  public allowedRoutesForCliente = [
    'unidades-de-negocio',
    'tienda',
    'tienda/mi-carrito',
    'tienda/pedido-realizado',
    'herramientas',
    'tienda/busqueda',
    'herramientas/subir',
    'cuenta',
    'cuenta/historial',
    'cuenta/aliados',
    'cuenta/facturacion',
    'cuenta/acuerdos-comerciales',
    'cuenta/ali-socios',
    'cuenta/los-infaltables',
    'cuenta/perfil/credenciales',
    'cuenta/avances-ventas',
    'cuenta/premios',
    'cuenta/historial/detalle',
    'formulario/*'
  ];

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  private isAllowedRoute(requestedRoute: string, allowedRoutes: string[]): boolean {
    const baseRoute = requestedRoute.split('?')[0];
  
    // Permitir rutas que comiencen con 'formulario/' para permitir los parámetros dinámicos
    if (baseRoute.startsWith('formulario/')) {
      return true;
    }
  
    return allowedRoutes.includes(baseRoute);
  }
  
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const user = this.localStorageService.getItem('user');
    const requestedRoute = segments.map(segment => segment.path).join('/');
  
    // Permitir acceso si la ruta comienza con 'formulario/'
    if (requestedRoute.startsWith('formulario/')) {
      return true;
    }
  
    if (!requestedRoute) {
      this.router.navigate(['/login']);
      return false;
    }
  
    if (user.type === 'Vendedor') {
      if (!this.isAllowedRoute(requestedRoute, this.allowedRoutesForVendedor)) {
        this.router.navigate(['/seleccionar-cliente']);
        return false;
      }
    } else if (user.type === 'Cliente') {
      if (!this.isAllowedRoute(requestedRoute, this.allowedRoutesForCliente)) {
        this.router.navigate(['/unidades-de-negocio']);
        return false;
      }
    }
  
    return true;
  }
  
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const user = this.localStorageService.getItem('user');
    const requestedRoute = route.url.map(segment => segment.path).join('/');
  
    // Permitir acceso si la ruta comienza con 'formulario/'
    if (requestedRoute.startsWith('formulario/')) {
      return true;
    }
  
    if (!requestedRoute) {
      this.router.navigate(['/login']);
      return false;
    }
  
    if (user.type === 'Vendedor') {
      if (!this.isAllowedRoute(requestedRoute, this.allowedRoutesForVendedor)) {
        this.router.navigate(['/seleccionar-cliente']);
        return false;
      }
    } else if (user.type === 'Cliente') {
      if (!this.isAllowedRoute(requestedRoute, this.allowedRoutesForCliente)) {
        this.router.navigate(['/unidades-de-negocio']);
        return false;
      }
    }
  
    return true;
  }  
}
