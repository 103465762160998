// access-control.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlFormsService {
  private canAccess: boolean = false;
  private retrySubject = new Subject<void>();
  retry$ = this.retrySubject.asObservable();

  // Método para solicitar reintento
  requestRetry() {
    this.retrySubject.next();
  }

  // Método para habilitar el acceso
  allowAccess() {
    this.canAccess = true;
  }

  // Método para verificar el acceso
  isAccessAllowed(): boolean {
    return this.canAccess;
  }

  // Método para resetear el acceso
  resetAccess() {
    this.canAccess = false;
  }
}
