<section class="d-flex align-items-center justify-content-center custom-carousel-skeleton">
  <div class="custom-carousel-nav-skeleton">
    <!-- Botón de flecha izquierda -->
    <button type="button" class="custom-nav-button-skeleton" [class.disabled]="true">
      <img src="assets/image/essentials/arrow-left-disabled.svg" alt="previous" />
    </button>
  
    <!-- Contenedor de indicadores con 3 puntos -->
    <div class="custom-indicators-skeleton d-none d-sm-flex">
      <button *ngFor="let _ of [0, 1, 2]; let i = index" 
              type="button" 
              class="custom-indicator-skeleton" 
              [class.active]=""> <!-- Aquí se marca el primer punto como activo -->
      </button>
    </div>
  
    <!-- Botón de flecha derecha -->
    <button type="button" class="custom-nav-button-skeleton" [class.disabled]="true">
      <img src="assets/image/essentials/arrow-right-disabled.svg" alt="next" />
    </button>
  </div>
</section>