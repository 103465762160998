import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table'
import {MatExpansionModule} from '@angular/material/expansion';
import {CarouselModule} from 'primeng/carousel';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ButtonModule} from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import {MenuModule} from 'primeng/menu';
import { InputSwitchModule } from 'primeng/inputswitch';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const MODULES = [
  CommonModule,
  MatAutocompleteModule,
  MatInputModule,
  MatIconModule,
  FormsModule,
  ReactiveFormsModule,
  MatButtonModule,
  MatCardModule,
  MatToolbarModule,
  MatMenuModule,
  MatSelectModule,
  MatSidenavModule,
  MatListModule,
  MatTabsModule,
  MatProgressBarModule,
  MatBadgeModule,
  MatFormFieldModule,
  MatDialogModule,
  MatTableModule,
  MatExpansionModule,
  CarouselModule,
  MenuModule,
  MatSnackBarModule,
  ButtonModule,
  MatProgressSpinnerModule,
  DropdownModule,
  MatChipsModule,
  MatDividerModule,
  MatCheckboxModule,
  MatRadioModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  InputSwitchModule
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...MODULES
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-PE' },
  ],
  exports: [...MODULES],
})
export class MaterialModule { }
