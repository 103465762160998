import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class BinnacleMeetingsService {

  private url = environment.apiURL;
  constructor(
    private _http: HttpClienteService,
  ) { }

  getBinnacleSave(data: any) {
    const query = `${this.url}/wcl-seller/binnacle-save`
    return this._http.post(query, data);
  }
  getBinnacleList(obj: any) {
    const query = `${this.url}/wcl-seller/binnacles-paginated-by-seller-code?sellerCode=${obj.sellerCode}&pageNumber=${obj.pageNumber}&pageSize=${obj.pageSize}`;
    return this._http.get(query);
  }
  getBinnacleEdit(data: any) {
    const query = `${this.url}/wcl-seller/binnacle-update`
    return this._http.put(query, data);
  }
}