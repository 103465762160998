export const ErrorsMessage = {
  icon: '',
  title: 'Algo salió mal',
  description:
    'Lo sentimos, no pudimos mostrar la información, vuelve a intentarlo <br> en unos minutos.',
  button: true,
  textButton: 'Reintentar',
};
export const ErrorsMessageMobile = {
  icon: '',
  title: 'Algo salió mal',
  description:
    'Lo sentimos, no pudimos mostrar la información, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
};
export const NullMessage = {
  icon: '',
  title: 'Aún no tienes clientes asignados',
  description:
    'Si tienes problemas con tu lista de clientes comunícate con nosotros <br> al siguiente correo: <strong>atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
};

export const NullMessageMobile = {
  icon: '',
  title: 'Aún no tienes clientes asignados',
  description:
    'Si tienes problemas con tu lista de clientes comunícate con nosotros al siguiente correo: <strong>atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
};
export const SearchNullMessage = {
  icon: 'assets/image/selected-client-list/error-data-null.svg',
  title: 'No se encontraron resultados',
  description: 'Intenta usar otras palabras clave.',
  button: false,
  textButton: '',
};
export const ErrorsMessageCardPricesPromotion = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Lo sentimos, no pudimos mostrar la información, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
};
export const NullMessagePrices = {
  icon: '',
  title: 'Aún no tienes una lista de precios <br> disponible',
  description:
    'Si tienes problemas con tu lista de precios comunícate con nosotros al <br> siguiente correo: <strong>atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
};
export const NullMessagePromotion = {
  icon: '',
  title: 'Aún no tienes promociones disponibles',
  description:
    'Si tienes problemas con tu promociones comunícate con nosotros al <br> siguiente correo: <strong>atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
};
export const SearchNullMessagePrices = {
  icon: 'assets/image/selected-client-list/error-data-null.svg',
  title: 'No se encontraron resultados',
  description:
    'Intenta usar otras palabras clave o asegúrate de estar buscando en la lista correcta.',
  button: false,
  textButton: '',
};

export const SearchNullMessagePromotion = {
  icon: 'assets/image/selected-client-list/error-data-null.svg',
  title: 'No se encontraron resultados',
  description: 'Intenta usar otras palabras clave.',
  button: false,
  textButton: '',
};

export const orderNullMessage = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Aún no has realizado una compra',
  description: 'Cuando realices una compra la mostraremos en esta sección.',
  button: false,
  textButton: '',
};

export const documentsTypeNullMessage = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Aún no tienes documentos',
  description:
    'Si tienes problemas con tus documentos comunícate con nosotros <br>al siguiente correo: <strong>atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
};
export const documentsTyprErrorMessage = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Los sentimos, no pudimos mostrar tus documentos, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
  classCustom: 'error-custom-0',
};
export const orderErrorMessage = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Lo sentimos, no pudimos mostrar la información, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
};
export const orderErrorMessageNew = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Los sentimos, no pudimos mostrar la información de tus líneas de crédito, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
  classCustom: 'error-custom-0',
};
export const checkingNullMessage = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'No existen registros de línea de crédito',
  description: '',
  button: false,
  textButton: '',
};
export const orderError_02 = {
  icon: 'assets/image/selected-client-list/cardsErrors.svg',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Lo sentimos, no pudimos mostrar la información, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
};
export const NullMessageAwards = {
  icon: '',
  title: 'Aún no tienes reconocimientos',
  description:
    'Si tienes problemas con tus reconocimientos comunícate con nosotros al siguiente correo:  <strong class"font-medium">atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
};
export const ErrorMessageAwards = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Lo sentimos, no pudimos mostrar la información de tus reconocimientos, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
};
export const ErrorsMessageClient = {
  icon: '',
  title: 'Algo salió mal',
  description:
    'Lo sentimos, no pudimos mostrar tus clientes, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
  classCustom: 'error-custom',
};
export const NullMessageClient = {
  icon: '',
  title: 'Aún no tienes clientes asignados',
  description:
    'Si tienes problemas con tu lista de clientes comunícate con nosotros al siguiente  correo: <strong>atencionclientes@alicorp.com.pe</strong> o llámanos al número: <strong> 01 708-9300 </strong> Opción 2.',
  button: false,
  textButton: '',
  classCustom: 'error-custom',
};
export const MessageAdvanceSalesNull = {
  icon: '',
  title: 'Aún no tienes información',
  description:
    'Si tienes problemas con tu avance de ventas comunícate con nosotros al siguiente correo: <strong class"font-medium">atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
};
export const MessageAdvanceSaleError = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Los sentimos, no pudimos mostrar tu avance de ventas, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
};
export const MessageAdvanceSalesDetailNull = {
  icon: '',
  title: 'Aún no tienes información',
  description:
    'Si tienes problemas con tu avance de ventas comunícate con nosotros al siguiente correo: <strong class"font-medium">atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
};
export const MessageAdvanceSaleDetailError = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Los sentimos, no pudimos mostrar tu avance por categorías, vuelve a intentarlo en unos minutos.',
  button: true,
  textButton: 'Reintentar',
};
export const MessageRoutinesNull = {
  iconText: '☝️',
  image: '',
  title: 'No tienes rutinas activas para el día de hoy',
  description:
    'Si tienes problemas con tus rutinas comunícate con nosotros al siguiente correo: <strong class"font-medium">atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
  classCustom: 'error-custom',
};
export const MessageRoutinesError = {
  icon: '',
  image: 'assets/image/selected-client-list/cardsErrors.svg',
  title: 'Algo salió mal',
  description:
    'Los sentimos, no pudimos mostrar tus rutinas, vuelve a intentarlo en unos minutos.</br> </br>Si el problema persiste escríbenos al siguiente correo: <strong class"font-medium">atencionclientes@alicorp.com.pe</strong>',
  button: true,
  textButton: 'Reintentar',
  classCustom: 'error-custom',
};
export const MessageActivitiesNull = {
  iconText: '📭',
  image: '',
  title: 'Esta rutina no tiene información',
  description:
    'Si tienes problemas con tus rutinas comunícate con nosotros al siguiente correo: <strong class"font-medium">atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
  classCustom: 'error-custom',
};
export const BinnacleMessageError = {
  icon: '',
  title: 'Algo salió mal',
  description:
    'Los sentimos, no pudimos mostrar la información de tu Bitácora, vuelve a intentarlo en unos minutos. <br> <br> Si el problema persiste escríbenos al siguiente correo:  <strong class"font-medium>atencionclientes@alicorp.com.pe</strong>',
  button: true,
  textButton: 'Reintentar',
  classCustom: 'error-custom',
};
export const BinnacleMessageNull = {
  iconText: '📭',
  title: 'Aún no tienes información',
  description:
    'Si tienes problemas con tu Bitácora comunícate con nosotros <br> al siguiente correo: <strong class"font-medium>atencionclientes@alicorp.com.pe</strong>',
  button: false,
  textButton: '',
  classCustom: 'error-custom',
};
