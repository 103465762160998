import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from "@core/core.module";
import { SharedModule } from "@shared/shared.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './features/login/login.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './core/store/app.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EffectsArray } from '@core/store/app.effects';
import { RedZoomModule } from 'ngx-red-zoom';
import { RouterErrorComponent } from './features/router-error/router-error.component';
import { SelectedClientListComponent } from './features/selected-client-list/selected-client-list.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { RoutinesDetailComponent } from './features/routines-detail/routines-detail.component';
import { WayFormsModule } from './features/way-forms/way-forms.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SelectedClientListComponent,
    RoutinesDetailComponent,
    RouterErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    WayFormsModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot(EffectsArray),
    RedZoomModule,
    GoogleTagManagerModule.forRoot({ id: environment.GTM_KEY,
    })
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: environment.GTM_KEY}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
