<div class="scroll-hidden">
  <!-- Cabecera -->
  <nav class="navbar navbar-light bg-light p-0 content-nav">
    <div
      class="d-flex justify-content-center vvw-100 align-items-center container"
    >
      <img
        src="assets/image/selected-client-list/icon-way.png"
        alt
        class="pr-8 image-custom_way"
      />
    </div>
  </nav>

  <router-outlet></router-outlet>
</div>
