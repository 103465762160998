import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

interface PaginationStrategy {
  calculatePages(length: number, pageSize: number, pageIndex: number): any[];
}

class DefaultPaginationStrategy implements PaginationStrategy {
  calculatePages(length: number, pageSize: number, pageIndex: number): any[] {
    const totalPages = Math.ceil(length / pageSize);
    let pages: any[] = [];

    if (totalPages <= 6) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (pageIndex < 3) {
        pages = [1, 2, 3, 4, '...', totalPages];
      } else if (pageIndex >= totalPages - 4) {
        pages = [
          totalPages - 5,
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      } else {
        pages = [
          pageIndex + 1,
          pageIndex + 2,
          pageIndex + 3,
          '...',
          totalPages,
        ];
      }
    }

    return pages;
  }
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() length!: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number = 0;
  @Input() displayType: string = 'desktop'; // New input for display type
  @Output() pageChange = new EventEmitter<number>();

  pages: any[] = [];
  totalPages: number = 0;

  private paginationStrategy: PaginationStrategy;

  constructor() {
    this.paginationStrategy = new DefaultPaginationStrategy();
  }
  ngOnInit() {
    this.updatePages();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.warn(this.length,this.pageSize,this.pageIndex);
    
    if (
      changes['length'] ||
      changes['pageSize'] ||
      changes['pageIndex'] ||
      changes['displayType']
    ) {
      this.updatePages();
    }
  }

  updatePages() {
    this.totalPages = Math.ceil(this.length / this.pageSize);
    this.pages = this.paginationStrategy.calculatePages(
      this.length,
      this.pageSize,
      this.pageIndex
    );
  }

  setPage(page: any) {
    if (page !== this.pageIndex + 1 && page !== '...') {
      this.pageIndex = page - 1;
      this.pageChange.emit(this.pageIndex);
      this.updatePages();
    }
  }

  previousPage() {
    if (this.pageIndex > 0) {
      this.pageIndex--;
      this.pageChange.emit(this.pageIndex);
      this.updatePages();
    }
  }

  nextPage() {
    if (this.pageIndex < this.totalPages - 1) {
      this.pageIndex++;
      this.pageChange.emit(this.pageIndex);
      this.updatePages();
    }
  }
}
