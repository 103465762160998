import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/authentication/auth.service';
import { environment } from '@env/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private ocpKey;
  constructor(private inj: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ocpKey = environment.ocpKey;

    // Verifica si la URL es una URL de Azure Blob Storage
    if (req.url.includes('blob.core.windows.net')) {
      // Si es una solicitud a Azure Blob, no modifiques los encabezados
      return next.handle(req);
    }

    const tok = this.inj.get(AuthService);

    // Si la solicitud ya tiene un encabezado de autorización, pasa sin modificar
    if (req.headers.has('Authorization')) {
      return next.handle(req);
    }

    const authHeader = tok.getToken();
    if (!tok.isLoggedIn()) {
      // Si el usuario no está autenticado, añade Ocp-Apim-Subscription-Key
      const dupReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Ocp-Apim-Subscription-Key', this.ocpKey),
      });
      return next.handle(dupReq);
    } else {
      // Si el usuario está autenticado, añade tanto el token como la Ocp-Apim-Subscription-Key
      const dupReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Ocp-Apim-Subscription-Key', this.ocpKey)
          .set('Authorization', `Bearer ${authHeader}`),
      });
      return next.handle(dupReq);
    }
  }
}
