<section class="hidden-overflow-x" *ngIf="isUserDataValid(); else partnerNot">
  <!-- Descripción General  -->
  <section class="section-with-background">
    <div class="container">
      <div class="partners-banner">
        <!-- Left Side Content -->
        <div class="left-content">
          <div class="partner-logo d-flex">
            <img
              src="/assets/image/partners-ali/icon-diamond.svg"
              alt="Ali Socios Logo"
            />
            <span class="fts-24 font-bold-dali ml-4">Ali Socios</span>
          </div>
          <p class="welcome-message fts-16 font-regular p-0 m-0">
            ¡Bienvenido a nuestro Programa de beneficios!
          </p>
        </div>

        <!-- Right Side Content -->
        <div class="right-content">
          <div class="member-info d-flex align-items-center">
            <div class="member-logo d-flex align-items-center">
              <img
                [src]="obtenerNivelActivo()?.icon"
                [alt]="obtenerNivelActivo().type"
              />
            </div>
            <div class="member-details ta-s ml-8">
              <p class="m-0 pt-0 pl-0 pr-0 pb-8 fts-24 font-bold-dali line-height-28">
                Soy {{ obtenerNivelActivo()?.type }}
              </p>
              <p class="m-0 p-0 fts-14 line-height-16 font-regular welcome-message">
                {{ nameClient }}
              </p>
            </div>
          </div>
          <div class="pl-32 pr-32 show-desktop-tablet">
            <img src="/assets/image/partners-ali/line-partners.svg" alt="" />
          </div>
          <div class="download-section">
            <p class="m-0 p-0 vw-100 fts-14 font-semiBold text-ellipsis">
              Descárgate los beneficios:
            </p>
            <button
              class="btn-green p-12 font-regular fts-14 fontw-600 text-download"
              (click)="openFilePDF()"
              pButton
              [loading]="loadingPDf"
            >
              Descargar PDF
              <img
                *ngIf="!loadingPDf"
                src="assets/svg/icon-download.svg"
                alt
                class="pl-8"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="container-description-aliPartners">
        <div class="description-aliPartners ta-s">
          <div class="partener-logo">
            <img
              src="/assets/image/partners-ali/logo-aliPartners.svg"
              alt="Ali partners Logo"
            />
          </div>
          <div class="mt-16">
            <p class="m-0 pb-8 fts-24 font-bold-dali color-neutral-lightest">
              Nuestro programa
            </p>
            <p class="m-0 p-0 fts-16 font-regular color-neutral-lightest">
              Bienvenido a <span class="font-bold-dali">Ali Socios</span>, un
              programa donde te premiamos y acompañamos tu crecimiento con
              grandes beneficios según el nivel en el que te encuentres.
            </p>
          </div>
          <div class="mt-16">
            <div class="mb-16">
              <p class="m-0 pb-8 fts-24 font-bold-dali color-neutral-lightest">
                Los Beneficios
              </p>
              <div class="d-flex">
                <div class="d-flex align-items-center benefits">
                  <img
                    src="/assets/image/partners-ali/icon-heart_green.svg"
                    alt="icon heart"
                    class="mr-8"
                  />
                  <p class="m-0 p-0 fts-12 font-bold-dali">Programa Aliados</p>
                </div>
                <div class="d-flex align-items-center benefits ml-8">
                  <img
                    src="/assets/image/partners-ali/icon-picture_green.svg"
                    alt="icon heart"
                    class="mr-8"
                  />
                  <p class="m-0 p-0 fts-12 font-bold-dali">
                    Materiales de Visibilidad
                  </p>
                </div>
              </div>
              <div class="d-flex mt-8">
                <div class="d-flex align-items-center benefits">
                  <img
                    src="/assets/image/partners-ali/icon-settingsAlt_green.svg"
                    alt="icon heart"
                    class="mr-8"
                  />
                  <p class="m-0 p-0 fts-12 font-bold-dali">
                    Personal de Ejecución
                  </p>
                </div>
                <div class="d-flex align-items-center benefits ml-8">
                  <img
                    src="/assets/image/partners-ali/icon-callCenter_green.svg"
                    alt="icon heart"
                    class="mr-8"
                  />
                  <p class="m-0 p-0 fts-12 font-bold-dali">
                    Soporte y atención
                  </p>
                </div>
              </div>
            </div>
            <p class="m-0 p-0 fts-16 font-regular color-neutral-lightest">
              Sigue leyendo y entérate más acerca de los Niveles y el detalle de
              nuestros Beneficios.
            </p>
          </div>
        </div>
        <div>
          <div class="container-video">
            <img
              src="/assets/image/partners-ali/text-findOut.svg"
              alt="icon heart"
              class="mr-16 show-desktop-tablet img-desktop"
            />
            <div
              class="show-mobile fts-40 font-bold-dali line-height-38 color-neutral-lightest pb-16"
            >
              Entérate más<br />con este video
            </div>
            <div
              class="findOut d-flex align-items-center justify-content-center"
              [ngClass]="dataLink?.background ? '' : 'background-color-custom'"
              (click)="openVideo()"
            >
              <div
                *ngIf="dataLink?.background; else imageNot"
                [ngClass]="
                  dataLink?.background
                    ? 'background-image-custom'
                    : 'background-color-custom'
                "
                [ngStyle]="{
                  'background-image': dataLink?.background
                    ? 'url(' + dataLink?.background + ')'
                    : '',
                  'background-color': dataLink?.background
                    ? ''
                    : 'background-color-custom'
                }"
              ></div>
              <ng-template #imageNot
                ><img src="/assets/image/partners-ali/icon-play.svg"
              /></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Niveles  -->
  <section class="levels-section mt-32">
    <div class="container">
      <div class="d-flex align-items-center justify-content-center">
        <div class="levels ta-c">
          <p class="m-0 p-0 fts-24 font-bold-dali">Los niveles</p>
          <p class="m-0 p-0 fts-16 font-regular show-desktop-tablet">
            Cada nivel te brinda beneficios y experiencias personalizadas
            diseñadas para que disfrutes al máximo tu participación. Aplican
            términos y condiciones.
          </p>
          <p class="m-0 p-0 fts-16 font-regular show-mobile">
            Aplican términos y condiciones.
          </p>
        </div>
      </div>
      <web-card-partners
        [levels]="levels"
        [nivelActivo]="userDataSegment"
      ></web-card-partners>
      <section class="mt-32">
        <div class="container-reminder">
          <div *ngFor="let reminder of reminders" class="card-reminder">
            <div class="ta-c">
              <img [src]="reminder.icon" />
              <p
                class="m-0 p-0 fts-16 font-regular"
                [innerHTML]="reminder?.text"
              ></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>

  <!-- Beneficios -->
  <section class="benefits-section mt-32 pb-32 auto-overflow-x">
    <div class="container">
      <web-table-partners-ALI
        [userDataSegment]="userDataSegment"
      ></web-table-partners-ALI>
    </div>
  </section>
  <!-- Docuemntos descargables -->
  <section class="mt-32" [ngClass]="{'pb-32': showTermsConditions, 'pb-136': !showTermsConditions}">
    <div class="container">
      <div class="d-flex justify-content-center vw-100 container-dowloand">
        <div class="container-files d-flex">
          <div
            *ngFor="let file of filesAliPartners"
            class="d-flex files align-items-center"
          >
            <p
              class="m-0 fts-16 font-semiBold line-height-19 pr-8 pl-0 pt-0 pb-0 text-term"
            >
              {{ file?.name }}
            </p>
            <button
              class="btn-green p-12 font-semiBold fts-14 fontw-600"
              (click)="openFileInNewWindow(file)"
              pButton
              [loading]="file.loading"
            >
              Descargar
              <img *ngIf="!file.loading" [src]="file.icon" alt class="ml-6" />
              <!-- Mostrar icono solo si no está cargando -->
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
<ng-template #partnerNot>
  <section class="mt-32 pb-32 hidden-overflow">
    <div class="container">
      <div
        class="d-flex align-items-center justify-content-center h-custom-not"
      >
        <div class="container-not-partners ta-c">
          <img
            [src]="partnerNotMessages.imageUrl"
            [alt]="partnerNotMessages.altText"
            class="pb-24"
          />
          <p class="m-0 pb-16 font-bold-dali title-message-not-partners">
            {{ partnerNotMessages.messageTitle }}
          </p>
          <p class="m-0 p-0 fts-14 font-regular color-subTitle-custom ta-c">
            {{ partnerNotMessages.messageDescription }}
          </p>
        </div>
      </div>
    </div>
  </section>
</ng-template>
