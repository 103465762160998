<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fts-12">
  <!-- Columnas de la tabla -->
  <ng-container matColumnDef="Visibilidad cliente">
    <th mat-header-cell *matHeaderCellDef class="fts-12 font-regular">
      Visibilidad cliente
    </th>
    <td mat-cell *matCellDef="let element">
      <div
        class="d-flex justify-content-center align-items-center"
        width="90px"
      >
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '16px',
            'border-radius': '4px',
            'margin-bottom': '0px',
            display: 'flex'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Cliente">
    <th mat-header-cell *matHeaderCellDef>Cliente</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <div class="d-flex justify-content-center align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '138px',
            'border-radius': '4px',
            'margin-bottom': '0px',
            display: 'flex'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Fecha de ejecución Inicio - Fin">
    <th mat-header-cell *matHeaderCellDef>
      Fecha de ejecución<br />Inicio - Fin
    </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <div class="d-flex justify-content-center align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '94px',
            'border-radius': '4px',
            'margin-bottom': '0px',
            display: 'flex'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Estado">
    <th mat-header-cell *matHeaderCellDef>Estado</th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex justify-content-center align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '78px',
            'border-radius': '4px',
            'margin-bottom': '0px',
            display: 'flex'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Agenda">
    <th mat-header-cell *matHeaderCellDef>Agenda</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <div class="d-flex justify-content-center align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '118px',
            'border-radius': '4px',
            'margin-bottom': '0px',
            display: 'flex'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Categoría">
    <th mat-header-cell *matHeaderCellDef>Categoría</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <div class="d-flex justify-content-center align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '102px',
            'border-radius': '4px',
            'margin-bottom': '0px',
            display: 'flex'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Planes de acción / Actividades">
    <th mat-header-cell *matHeaderCellDef>Planes de acción / Actividades</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <div class="d-flex justify-content-center align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '235px',
            'border-radius': '4px',
            'margin-bottom': '0px',
            display: 'flex'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </td>
  </ng-container>

  <!-- Columna fija con el botón de editar -->
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef class="columna-fija-header">
      {{ isAnyRowEditing ? "Edición" : "Editar" }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      class="columna-fija mat-column-acciones"
    >
      <div class="d-flex justify-content-center align-items-center">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            height: '16px',
            width: '16px',
            'border-radius': '4px',
            'margin-bottom': '0px',
            display: 'flex'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </td>
  </ng-container>

  <!-- Definir las filas -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{ 'editing-row': row.isEditing || row.isNewRegister }"
  ></tr>
</table>
