// BadComponent

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ControlFormsService } from '@core/services/control-forms/control-forms.service';

@Component({
  selector: 'app-bad',
  templateUrl: './bad.component.html',
  styleUrls: ['./bad.component.scss'],
})
export class BadComponent implements OnInit {
  responseService: any;

  constructor(
    private router: Router,
    private accessControlService: ControlFormsService
  ) {}

  ngOnInit() {
    this.dataError();
    if (!this.accessControlService.isAccessAllowed()) {
      this.router.navigate(['/seleccionar-cliente']);
    }
    this.accessControlService.resetAccess();
  }

  goBack() {
    this.accessControlService.requestRetry(); // Solicita el reintento
  }

  dataError() {
    this.responseService = {
      img: 'assets/svg/spot-not-found.svg',
      button: true,
      title: 'Algo salió mal',
      description:
        'Lo sentimos, no pudimos enviar tu formulario. Por favor vuelve a intentarlo.',
    };
  }
}
