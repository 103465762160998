import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { TermsConditionsService } from '@core/services/terms-conditions/terms-conditions.service';
import { Subject, Subscription, take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgZone } from '@angular/core';
import { CustomSnackBarComponent } from '../custom-snack-bar/custom-snack-bar.component';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent implements OnInit, OnDestroy {
  @ViewChild('successSnackbar') successSnackbar!: TemplateRef<any>;
  @ViewChild('errorSnackbar') errorSnackbar!: TemplateRef<any>;
  acceptTerms = new FormControl(false);
  termsConditionsSubscription$!: Subscription;
  termsStatus: boolean = false;
  isLoading: boolean = true;
  isSubmitting: boolean = false;
  termsStatus$ = new Subject<boolean>();
  private successSnackbarRef: MatSnackBarRef<any> | null = null;
  private errorSnackbarRef: MatSnackBarRef<any> | null = null;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      actionText: any;
      actionCallback?: (accepted: boolean) => void;
      customerCode?: string;
      userType: 'seller' | 'client';
    },
    private snackBarRef: MatSnackBarRef<TermsConditionsComponent>,
    private _termsConditionsService: TermsConditionsService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    if (this.data.customerCode) {
      this.getTermsStatus(this.data.customerCode);
    }
  }

  getTermsStatus(id: string) {
    this.termsConditionsSubscription$ = this._termsConditionsService
      .getTermsConditionsStatus(id)
      .pipe(take(1))
      .subscribe({
        next: (res: any) => {
          this.zone.run(() => {
            this.termsStatus = res.customerAcceptedTerm;
            this.isLoading = false;
            this.termsStatus$.next(this.termsStatus);
          });
        },
        error: (err: Error) => {
          this.zone.run(() => {
            this.termsStatus = false;
            this.isLoading = false;
            this.termsStatus$.next(this.termsStatus);
          });
        },
      });
  }

  onSubmit(): void {
    if (this.acceptTerms.value) {
      this.isSubmitting = true;
      if (this.data.customerCode) {
        this.submitTermsAcceptance({
          customerCode: this.data.customerCode,
        });
      }
    }
  }

  submitTermsAcceptance(data: any) {
    this.termsConditionsSubscription$ = this._termsConditionsService
      .acceptTermsConditions(data)
      .pipe(take(1))
      .subscribe({
        next: (res: any) => {
          this.isSubmitting = false;
          this.snackBarRef.dismiss();
          this.successSnackbarRef = this._snackBar.openFromTemplate(this.successSnackbar, {
            panelClass: ['success-snackbar'],
          });
        },
        error: (err: Error) => {
          this.isSubmitting = false;
          this.snackBarRef.dismiss();
          this.errorSnackbarRef = this._snackBar.openFromTemplate(this.errorSnackbar, {
            panelClass: ['error-snackbar'],
          });
        },
      });
  }

  retrySubmit(): void {
    // Close the error snackbar
    this._snackBar.dismiss();

    // Reset the form control
    this.acceptTerms.reset(false);

    // Show the original snackbar again with all required data
    this.snackBarRef = this._snackBar.openFromComponent(
      TermsConditionsComponent,
      {
        data: {
          message: 'Términos y Condiciones',
          actionText: this.data.actionText,
          customerCode: this.data.customerCode,
          userType: this.data.userType,
        },
        panelClass: ['terms-conditions-snackbar'],
      }
    );
  }

  openTerms(): void {
    if (this.data?.actionText) {
      window.open(this.data.actionText, '_blank');
    } else {
      this.showSnackBar('Oops, ocurrió un error', 'X', 'custom-snack-bar-error');
      console.warn('No actionText (terms URL) provided');
    }
  }

  // Mostrar un SnackBar personalizado
  showSnackBar(message: string, actionText: string, panelClass: string, actionCallback?: () => void): void {
    const snackBarRef = this._snackBar.openFromComponent(CustomSnackBarComponent, {
      data: { message, actionText, actionCallback },
      duration: 3000,
      panelClass: [panelClass],
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this.retrySubmit();
    });
  }

  ngOnDestroy(): void {
    if (this.termsConditionsSubscription$) {
      this.termsConditionsSubscription$.unsubscribe();
    }
    if (this.successSnackbarRef) {
      this.successSnackbarRef.dismiss();
    }
    if (this.errorSnackbarRef) {
      this.errorSnackbarRef.dismiss();
    }
    this._snackBar.dismiss();
    this.termsStatus$.complete();
  }
}
