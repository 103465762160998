import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';
@Injectable({
  providedIn: 'root',
})
export class EssentialsService {
  private url = environment.apiURL;
  constructor(private _http: HttpClienteService) {}

  getEssentials(data: any) {
	//https://skynet-api-dev.azure-api.net/api/wcl-material/product-essential
    const query = `${this.url}/wcl-material/product-essential`;
    return this._http.post(query, data);
  }
}
