import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, tap, take, map, catchError, of, switchMap } from 'rxjs';
import * as authListClientActions from './auth-list-client.actions';
import { LocalStorageService } from '@core/services/local-storage.service';
import { User } from '@core/interfaces/user.model';
import { AuthListClientService } from '@core/services/authentication/authListClient.service';
import { GtmEventService } from '@core/analytics/gtm-events.service';
import { environment } from '@env/environment';

@Injectable()
export class authListClientEffects {
  constructor(
    private actions$: Actions,
    private _authListClientService: AuthListClientService,
    private _localStorageService: LocalStorageService,
    private _gtmEventService: GtmEventService
  ) {}

  loginListClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authListClientActions.logInListClient),
      switchMap(({ data }) => {
        return this._authListClientService
          .loginListClient({ email: data.email, password: data.password })
          .pipe(
            map((user: User) => {
              if (user) {
                const response = {
                  ...user,
                  type: data.type,
                };
                const appVersion = environment.appVersion;
                localStorage.setItem('appVersion', appVersion);
                this._authListClientService.setUser(response);
                return authListClientActions.setUserListClient({
                  user: response,
                });
              }
              return authListClientActions.logInListClientError({
                payload: '201',
              });
            }),
            catchError((error: Error) => {
              return of(
                authListClientActions.logInListClientError({ payload: error })
              );
            })
          );
      }),
      tap(() => {
        this._gtmEventService.loginEvent();
      })
    )
  );
}
