<p-carousel
  #carouselGeneral
  [value]="cards"
  [numVisible]="5"
  [numScroll]="5"
  [responsiveOptions]="responsiveOptions"
  [circular]="false"
  [showNavigators]="false"
  [showIndicators]="false"
  styleClass="custom-carousel"
>
  <!-- Plantilla de cada ítem del carrusel -->
  <ng-template let-card pTemplate="item">
    <div class="dinamic-card">
      <app-essentials-card [cardData]="card"></app-essentials-card>
    </div>
  </ng-template>

  <!-- Plantilla para el pie del carrusel (navegación) -->
  <ng-template pTemplate="footer">
    <div class="custom-carousel-nav">
      <button type="button" class="custom-nav-button" (click)="prevPage()" [class.disabled]="carouselGeneral?.page === 0">
        <img src="assets/image/essentials/arrow-left.svg" alt="previous" />
      </button>

      <div class="custom-indicators d-none d-sm-flex">
        <button *ngFor="let _ of getTotalPages(); let i = index" type="button" class="custom-indicator" [class.active]="carouselGeneral.page === i" (click)="goToPage(i)"></button>
      </div>

      <button type="button" class="custom-nav-button" (click)="nextPage()" [class.disabled]="carouselGeneral.page === getTotalPages().length - 1">
        <img src="assets/image/essentials/arrow-right.svg" alt="next" />
      </button>
    </div>
  </ng-template>
</p-carousel>
