import { Component, OnInit } from '@angular/core';

// Componente principal del formulario
@Component({
  selector: 'app-way-forms',
  templateUrl: './way-forms.component.html',
  styleUrls: ['./way-forms.component.scss'],
})
export class WayFormsComponent implements OnInit {
  // Preguntas disponibles con sus propiedades
  ngOnInit() {
  }
  
}
