import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessUnitGuard } from '@core/guards/businessUnit.guard.service';
import { LoginGuard } from '@core/guards/login.guard.service';
import { LoginComponent } from './features/login/login.component';
import { BusinessUnitiesComponent } from './features/main/business-unities/business-unities.component';
import { RouterErrorComponent } from './features/router-error/router-error.component';
import { SelectedClientListComponent } from './features/selected-client-list/selected-client-list.component';
import { NotFoundGuard } from '@core/guards/notFound.guard.service';
import { RoutinesDetailComponent } from './features/routines-detail/routines-detail.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    canActivate: [LoginGuard, BusinessUnitGuard, NotFoundGuard],
    canLoad: [NotFoundGuard],
    path: 'unidades-de-negocio',
    component: BusinessUnitiesComponent,
  },
  {
    canActivate: [LoginGuard, NotFoundGuard],
    canLoad: [NotFoundGuard],
    // canLoad: [LoginGuard],
    path: 'tienda',
    loadChildren: () =>
      import('./features/main/main.module').then((m) => m.MainModule),
  },

  {
    canActivate: [LoginGuard, NotFoundGuard],
    canLoad: [NotFoundGuard],
    path: 'herramientas',
    loadChildren: () =>
      import('./features/tools/tools.module').then((m) => m.ToolsModule),
  },
  {
    canActivate: [LoginGuard, NotFoundGuard],
    canLoad: [NotFoundGuard],
    path: 'cuenta',
    loadChildren: () =>
      import('./features/account/account.module').then((m) => m.AccountModule),
  },
  {
    canActivate: [LoginGuard, NotFoundGuard],
    canLoad: [NotFoundGuard],
    path: 'seleccionar-cliente',
    component: SelectedClientListComponent,
  },
  {
    canActivate: [LoginGuard, NotFoundGuard],
    canLoad: [NotFoundGuard],
    path: 'detalle-rutinas',
    component: RoutinesDetailComponent,
  },
  {
    path: 'formulario',
    loadChildren: () =>
      import('./features/way-forms/way-forms.module').then(
        (m) => m.WayFormsModule
      ),
  },
  {
    canActivate: [LoginGuard, NotFoundGuard],
    canLoad: [NotFoundGuard],
    path: 'main',
    loadChildren: () =>
      import('./features/main-seller/main-seller.module').then(
        (m) => m.MainClientModule
      ),
  },
  {
    canActivate: [LoginGuard],
    path: 'error',
    component: RouterErrorComponent,
  },
  {
    canActivate: [LoginGuard],
    path: '500',
    component: RouterErrorComponent,
  },
  {
    canActivate: [LoginGuard],
    path: '503',
    component: RouterErrorComponent,
  },
  {
    canActivate: [LoginGuard],
    path: '504',
    component: RouterErrorComponent,
  },
  {
    canActivate: [LoginGuard],
    path: '404',
    component: RouterErrorComponent,
  },
  {
    canActivate: [NotFoundGuard],
    canLoad: [NotFoundGuard],
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
