<!-- Tarjeta de tipo 1: Promociones -->
<div
  class="card general-box-container card-type_1"
  *ngIf="cardData.typeCard == 1"
>
  <!-- Título de la tarjeta con mensaje promocional -->
  <div class="p-16">
    <p
      class="m-0 p-0 text-promotion font-family-italic fts-24 ta-s line-height-26"
    >
      ¡No te pierdas estas grandes promociones!
    </p>
  </div>

  <!-- Imagen asociada a la promoción -->
  <img
    src="assets/image/infaltables-card/purchase-made.svg"
    alt="Imagen fija"
    height="250px"
    class="card-img d-flex align-items-end vw-100"
  />
</div>

<!-- Tarjeta de tipo 2: Producto dinámico con descuento -->
<div
  class="card general-box-container card-type_2"
  *ngIf="cardData.typeCard == 2"
>
  <!-- Contenedor de la imagen del descuento (si existe) -->
  <div class="d-flex pt-16 pb-16 pl-8 pr-8 h-max">
    <!-- Descuento, si existe -->
    <div *ngIf="cardData.discountNumber && cardData.discountType; else emptyDiscountImage">
      <div
        class="discount-container"
        [matTooltip]="cardData.mechanics"
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
        [matTooltipDisabled]="!cardData.mechanics"
        matTooltipHideDelay="0"
        [matTooltipShowDelay]="0"
      >
        <img [src]="discountImageUrl" alt="Discount Image" />
        <p class="discount-text p-0 m-0 fts-12 font-bold-dali">
          {{ cardData.discountNumber }}%
        </p>
      </div>
      <!-- Texto del descuento -->
      <p class="p-0 m-0 fts-12 font-bold-dali text-discont ta-c">
        {{ discountText }}
      </p>
    </div>

    <!-- Si no hay descuento, mostrar un espacio vacío -->
    <ng-template #emptyDiscountImage>
      <div class="empty-image"></div>
    </ng-template>

    <!-- Imagen del producto -->
    <div class="vw-100 d-flex justify-content-center align-items-center">
      <img
        [src]="
          (cardData?.productImage && cardData?.productImage != 'NO' )
            ? cardData.productImage
            : 'assets/image/infaltables-card/icon-product-default.svg'
        "
        alt="Product Image"
        [ngClass]="cardData.typeCard === 2 ? 'product-img' : 'card-img'"
      />
    </div>
    <div class="empty-image"></div>
  </div>

  <!-- Línea divisoria -->
  <div class="dl-divider-neutral-medium"></div>

  <!-- Detalles del producto -->
  <div class="p-16">
    <div class="sku fts-12 line-height-14 font-regular color-subTitle-custom">
      {{ localCardData.sku }}
    </div>
    <div class="description fts-14 font-semiBold line-height-16 pt-8">
      {{ cardData.buyUnd }} - {{ cardData.description }}
    </div>
    <div class="mt-8">
      <!-- Precio original tachado y precio con descuento -->
      <span
        class="original-price fts-14 font-regular"
        [class.price-line-through]="cardData.discountedPrice"
        >S/ {{ cardData.originalPrice }}</span
      >
      <span
        class="discounted-price m-16 fts-14 font-bold-dali"
        *ngIf="cardData.discountedPrice"
        >S/ {{ cardData.discountedPrice }}</span
      >
    </div>
  </div>

 <section class="position-bottom">
   <!-- Línea divisoria -->
  <div>
    <div class="dividing-line"></div>

  <!-- Detalles finales con el total con IGV y el botón para añadir al carrito -->
  <div class="p-16">
    <div class="d-flex justify-content-between align-items-center">
      <span class="title-igv fts-14 font-regular color-subTitle-custom text-ellipsis"
        >Total con IGV</span
      >
      <span class="price fts-14 font-bold-dali text-ellipsis">S/ {{ totalPrice }}</span>
    </div>

    <!-- Campo de entrada para el precio y botón "Añadir" -->
    <div class="card-content d-flex align-items-center mt-16">
      <input
        #referenceInput
        type="text"
        class="ta-c h-40 p-0 input-car font-regular"
        appFormatInput
        [(ngModel)]="localCardData.count"
        (ngModelChange)="updateCount($event)"
        [disabled]="false"
      />
      <button
        class="btn-green btn-secondary-red font-regular fts-14 vw-100 d-flex ml-8"
        pButton
        (click)="addToCar()"
      >
        Añadir
        <img
          src="assets/image/home/nav/icon-car.svg"
          alt="Imagen arrow"
          width="16px"
          class="ml-8"
        />
      </button>
    </div>
  </div>
  </div>
 </section>
</div>

<!-- Tarjeta de tipo 3: Mensaje final -->
<div
  class="p-16 general-box-container vw-100 d-flex align-items-center justify-content-center card card-type_3"
  *ngIf="cardData.typeCard == 3"
>
  <div>
    <!-- Mensaje invitando a descubrir más productos -->
    <p class="pt-0 pb-16 pl-0 pr-0 m-0 font-family-italic fts-18 ta-c">
      Aún hay más productos para ti, descúbrelos aquí.
    </p>
    <!-- Botón para redirigir a más productos -->
    <button
      class="btn-green btn-secondary-red font-regular fts-14 vw-100 d-flex"
      pButton
      [routerLink]="'/cuenta/los-infaltables'"
    >
      Ir a lo Infaltables
      <img
        src="assets/image/home/icon-arrowRight_white.svg"
        alt="Imagen arrow"
        width="16px"
        class="ml-8"
      />
    </button>
  </div>
</div>
