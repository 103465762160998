import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'web-card-partners',
  templateUrl: './card-partners.component.html',
  styleUrls: ['./card-partners.component.scss']
})
export class CardPartnersComponent implements OnInit {
  @Input() levels =[];
  @Input() nivelActivo;
  @Input() userDataSegment;
  selectedIndex: number = 0; 
  constructor() { }

  ngOnInit() {
    this.selectedIndex=this.numVisible(this.nivelActivo)
  }
  numVisible(number: string): number {
    switch (number) {
      case 'B':
        return 0;
      case 'A':
        return 1;
      case 'A+':
        return 2;
      case '1T':
        return 3;
      default:
        return 5; // Si el valor no coincide con ninguno de los casos anteriores, devuelve 5
    }
  }

}
