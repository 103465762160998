import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';
@Injectable({
  providedIn: 'root'
})
export class PartnersALIService {



  private url = environment.apiURL;
  constructor(
    private _http: HttpClienteService,
  ) { }

 
  getAliPartnersFiles() {
    const query = `${this.url}/wcl-document/get-ali-socios-files`;
    return this._http.get(query);
  }
}
