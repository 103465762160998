<div class="modal-body">
  <div *ngIf="data.routine.activities.length != 0; else nullRoutine">
    <div class="container header-container">
      <div class="modal-title font-bold-dali">
        {{ data.routine.position }}. {{ data.routine.name }}
      </div>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <img src="assets/image/modal/close-modal.svg" alt="alert" />
      </button>
      <div class="sub-header-container">
        <div class="activities-count">
          <p class="activities-p">
            Esta rutina tiene
            <span class="font-bold-dali">{{
              data.routine.activities.length
            }}</span>
            <span
              class="font-bold-dali"
              *ngIf="data.routine.activities.length > 1"
            >
              actividades.</span
            >
            <span
              class="font-bold-dali"
              *ngIf="data.routine.activities.length == 1"
            >
              actividad.</span
            >
          </p>
        </div>
        <div class="repository-link">
          <span class="font-bold-dali">Mi repositorio:</span>
          <button
            class="btn-green p-12 font-book fts-14"
            pButton
            (click)="goToUrl(data.routines.linkSharepoint)"
          >
            Ir al sharepoint
            <img
              src="assets/image/home-seller/icon-redirect.svg"
              class="ml-8"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="content-container-desktop">
      <div class="activities-container">
        <div class="activities-title font-bold-dali">
          <img src="assets/image/modal/activities.svg" alt="activities" />
          Actividades
        </div>
        <!-- Contenido del contenedor de actividades -->
        <div
          class="activitie-list mt-16 mb-16"
          *ngFor="let activitie of data.routine.activities; let i = index"
        >
          <div>
            <span class="font-bold-dali">{{ i + 1 }}. </span
            ><span [innerHTML]="processText(activitie.name)"></span>
          </div>
          <div class="dl-divider mb-16 mt-16"  *ngIf="i !== data.routine?.activities.length - 1"></div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="format-container">
        <div class="format-title font-bold-dali">
          <img src="assets/image/modal/format.svg" alt="format" /> Formatos para
          esta rutina
        </div>
        <!-- Contenido del contenedor de rutinas -->
        <ng-container
          *ngFor="let activitie of data.routine.activities; let i = index"
        >
        <ng-container *ngIf="activitie.formatName && activitie.formatName.trim() !== ''">
          <div *ngIf="activitie.formatName" class="format-list mt-8">
           <div class="d-flex align-items-center justify-content-between">
            <div>{{ activitie.formatName }}</div>
            <button
              class="btn-red p-12 fts-14 font-bold-dali d-flex align-items-center"
              pButton
              (click)="goToUrlOpen(activitie, data.routine)"
            >
              Abrir
              <svg
                class="ml-8"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.04262 12.7445H11.0098C11.5344 12.7445 12.0066 12.3642 12.0066 11.7609V6.85597H10.4984V11.3674H1.5541V2.63302H6.23606V1.25597H1.04262C0.51803 1.25597 0.0458984 1.6363 0.0458984 2.23958V11.7609C0.0458984 12.3642 0.51803 12.7445 1.04262 12.7445ZM12.3607 1.58384L11.8492 4.95433L10.6164 4.7445C10.7738 3.82646 10.7607 3.35433 11.3508 2.47565L7.6 6.22647L6.76065 5.38712L10.5115 1.6363C9.63278 2.22647 9.17377 2.22647 8.30819 2.39696L8.09836 1.16417L11.4033 0.626465L12.3607 1.58384Z"
                />
              </svg>
            </button>
           </div>
            <div *ngIf="activitie?.idForm" class="pb-8 mt-16">
              <div class="fts-14 font-bold-dali">Completados</div>
              <div class="d-flex align-items-center mt-8">
                <div class="border-btn  count-format mr-8 font-bold-dali fts-12">{{setCurrentMonth()}}: {{activitie?.countMonth}}</div>
                <div class="border-btn count-format font-bold-dali fts-12">Semana en curso: {{activitie?.countWeek}}</div>
              </div>
              <div class="border-btn d-flex mt-16 fts-12 align-items-center text-color-alert font-regular mb-8">
                <span class="mr-8 icon-alert">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="12" fill="#E16C00"/>
                    <path d="M14.4788 17.8754V15.7639C14.4788 14.4918 16.6821 14.1508 16.6821 11.2918C16.6821 8.51148 14.3608 7.12131 12.0526 7.12131C9.73126 7.12131 7.40994 8.51148 7.40994 11.2918C7.40994 14.1508 9.60011 14.4918 9.60011 15.7639V17.8754C9.60011 18.7934 10.2034 19.3836 11.1214 19.3836H12.9706C13.8886 19.3836 14.4788 18.7934 14.4788 17.8754ZM13.0099 15.7639V18.0066H11.069V15.7639C11.069 13.5475 8.91814 13.7311 8.91814 11.2918C8.91814 9.42951 10.4788 8.49836 12.0526 8.49836C13.6132 8.49836 15.1739 9.42951 15.1739 11.2918C15.1739 13.7443 13.0099 13.5475 13.0099 15.7639ZM10.2952 15.5672V16.9443H13.9149V15.5672H10.2952ZM7.92142 5.04918L6.95093 5.92787L8.43289 7.50164L9.37716 6.63607L7.92142 5.04918ZM11.3313 4.05246V6.38689H12.7083V4.05246H11.3313ZM16.0788 5.04918L14.6231 6.63607L15.5673 7.50164L17.0493 5.92787L16.0788 5.04918Z" fill="#FCFCFC"/>
                    </svg>
                    
                  </span>
                Recuerda que cada fin de mes tu contador se reinicia, pero
                tus respuestas están guardadas.
              </div>
            </div>
          </div>
          <div class="dl-divider mb-16 mt-16" *ngIf="i !== getLastValidIndex(data.routine?.activities)"></div>
        </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="content-container-mobile">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
        <mat-tab label="Actividades">
          <div class="cont">
            <div class="activities-title font-bold-dali">
              <img src="assets/image/modal/activities.svg" alt="activities" />
              Actividades
            </div>
            <!-- Contenido del contenedor de actividades -->
            <div
              class="activitie-list mt-16 mb-16"
              *ngFor="let activitie of data.routine.activities; let i = index"
            >
              <div>
                <span class="font-bold-dali">{{ i + 1 }}. </span
                ><span [innerHTML]="processText(activitie.name)"></span>
              </div>
              <div class="dl-divider mb-16 mt-16" *ngIf="i !== data.routine?.activities.length - 1"></div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Formatos">
          <div class="cont">
            <div class="format-title font-bold-dali">
              <img src="assets/image/modal/format.svg" alt="format" /> Formatos
              para esta rutina
            </div>
            <!-- Contenido del contenedor de rutinas -->
            <ng-container
              *ngFor="let activitie of data.routine.activities; let i = index"
            >
            <ng-container *ngIf="activitie.formatName && activitie.formatName.trim() !== ''">
              <div *ngIf="activitie.formatName" class="format-list mt-8">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center fts-14 font-regular">
                    {{ activitie.formatName }}
                  </div>
                  <button
                    class="btn-red p-12 fts-14 font-bold-dali d-flex align-items-center"
                    pButton
                    (click)="goToUrlOpen(activitie, data.routine)"
                  >
                    Abrir
                    <svg
                      class="ml-8"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.04262 12.7445H11.0098C11.5344 12.7445 12.0066 12.3642 12.0066 11.7609V6.85597H10.4984V11.3674H1.5541V2.63302H6.23606V1.25597H1.04262C0.51803 1.25597 0.0458984 1.6363 0.0458984 2.23958V11.7609C0.0458984 12.3642 0.51803 12.7445 1.04262 12.7445ZM12.3607 1.58384L11.8492 4.95433L10.6164 4.7445C10.7738 3.82646 10.7607 3.35433 11.3508 2.47565L7.6 6.22647L6.76065 5.38712L10.5115 1.6363C9.63278 2.22647 9.17377 2.22647 8.30819 2.39696L8.09836 1.16417L11.4033 0.626465L12.3607 1.58384Z"
                      />
                    </svg>
                  </button>
                </div>
                <div *ngIf="activitie?.idForm" class="pb-8 mt-16">
                  <div class="fts-14 font-bold-dali">Completados</div>
                  <div class="d-flex align-items-center mt-8">
                    <div class="border-btn  count-format mr-8 font-bold-dali fts-12">{{setCurrentMonth()}}: {{activitie?.countMonth}}</div>
                    <div class="border-btn count-format font-bold-dali fts-12">Semana en curso: {{activitie?.countWeek}}</div>
                  </div>
                  <div class="border-btn d-flex mt-16 fts-12 align-items-center text-color-alert font-regular mb-8">
                    <span class="mr-8 icon-alert">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" rx="12" fill="#E16C00"/>
                        <path d="M14.4788 17.8754V15.7639C14.4788 14.4918 16.6821 14.1508 16.6821 11.2918C16.6821 8.51148 14.3608 7.12131 12.0526 7.12131C9.73126 7.12131 7.40994 8.51148 7.40994 11.2918C7.40994 14.1508 9.60011 14.4918 9.60011 15.7639V17.8754C9.60011 18.7934 10.2034 19.3836 11.1214 19.3836H12.9706C13.8886 19.3836 14.4788 18.7934 14.4788 17.8754ZM13.0099 15.7639V18.0066H11.069V15.7639C11.069 13.5475 8.91814 13.7311 8.91814 11.2918C8.91814 9.42951 10.4788 8.49836 12.0526 8.49836C13.6132 8.49836 15.1739 9.42951 15.1739 11.2918C15.1739 13.7443 13.0099 13.5475 13.0099 15.7639ZM10.2952 15.5672V16.9443H13.9149V15.5672H10.2952ZM7.92142 5.04918L6.95093 5.92787L8.43289 7.50164L9.37716 6.63607L7.92142 5.04918ZM11.3313 4.05246V6.38689H12.7083V4.05246H11.3313ZM16.0788 5.04918L14.6231 6.63607L15.5673 7.50164L17.0493 5.92787L16.0788 5.04918Z" fill="#FCFCFC"/>
                        </svg>
                        
                      </span>
                    Recuerda que cada fin de mes tu contador se reinicia, pero
                    tus respuestas están guardadas.
                  </div>
                </div>
              </div>
              <div class="dl-divider mb-16 mt-16" *ngIf="i !== getLastValidIndex(data.routine?.activities)"></div>
            </ng-container>
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <ng-template #nullRoutine>
    <div class="container header-container-error">
      <div class="modal-title font-bold-dali">
        {{ data.routine.position }}. {{ data.routine.name }}
      </div>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <img src="assets/image/modal/close-modal.svg" alt="alert" />
      </button>
    </div>
    <div class="text-error-container ta-c pb-16 pt-16">
      <div class="text-error">
        <app-message-empty-error
          [messageError]="messageClientNull"
        ></app-message-empty-error>
      </div>
    </div>
  </ng-template>
  <div class="modal-footer">
    <div
      *ngIf="data.routine.activities.length == 0"
      class="divider-horizontal"
    ></div>
    <app-paginator
      [length]="data.routines.routines.length"
      [pageSize]="pageSize"
      [pageIndex]="data.routine.position - 1"
      (pageChange)="onPageChange($event)"
      displayType="desktop"
    >
    </app-paginator>
  </div>
</div>
