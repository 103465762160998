import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as EssentialsActions from './essentials.actions';
import { EssentialsState } from '@core/interfaces/essentials';
import { tap, switchMap, take, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { EssentialsService } from '@core/services/essentials/essentials.service';

@Injectable()
export class EssentialsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<EssentialsState>,
    private _essentialService: EssentialsService,
  ) {}

  loadEssentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssentialsActions.loadEssentials),
      switchMap(({ code, sector, zone, schema, segment }) => {
        const body = { code, sector, zone, schema, segment };
   // Limpiar sessionStorage y el store antes de cargar nuevos datos
   sessionStorage.removeItem('essentials'); // Eliminar los essentials de sessionStorage
   sessionStorage.removeItem('essentials_loading'); // Eliminar loading
   sessionStorage.removeItem('essentials_error'); // Eliminar error

   // Limpiar el store de essentials
   this.store.dispatch(EssentialsActions.clearEssentials()); // Limpiar los essentials en el store
        // Despachar la acción para poner loading a true (solo activamos la acción de loading)
        this.store.dispatch(EssentialsActions.essentialsLoading({ loading: true }));
  
        return this._essentialService.getEssentials(body).pipe(
          take(1),
          map((res: any) => {
            if (res && Array.isArray(res)) {
              const productCards = res.map((product) => ({
                typeCard: 2,
                discountNumber: parseInt(product.discount) || 0,
                productImage: product.url || 'assets/image/search/no-image.svg',
                sku: product.sku,
                description: product.description,
                originalPrice: parseFloat(product.salePrice),
                discountedPrice: parseFloat(product.simulationPrice),
                count: 0,
                igv: parseFloat(product.igv) || 0,
                discountType: product.discountType,
                buyUnd: product.buyUnd,
                brandName: product.brandName,
                baseUnd: product.buyBase,
                cashPrice: product.cashPrice,
                categoryName: product.categoryName,
                creditPrice: product.creditPrice,
                salePrice: product.salePrice,
                available: true,
                percep: false,
                perceptionFlag: 0,
                stock: false,
                suggestedQuantity: 0,
                categoryCode: product.categoryCode,
                group: product.group,
                isWebExclusive: product.isWebExclusive,
                mechanics: product.mechanics,
                priority: product.priority,
              }));
  
              // Guardar los essentials en sessionStorage
              sessionStorage.setItem('essentials', JSON.stringify(productCards));
              sessionStorage.setItem('essentials_loading', JSON.stringify(false));  // Persistir loading como false
              sessionStorage.setItem('essentials_error', JSON.stringify(false)); // Persistir error como false
  
              return EssentialsActions.setEssentials({ essentials: productCards });
            }
            return EssentialsActions.setEssentials({ essentials: [] });
          }),
          catchError(() => {
            sessionStorage.setItem('essentials_error', JSON.stringify(true)); // Persistir error
            return of(EssentialsActions.essentialsError({ error: true }));
          })
        );
      })
    )
  );
  

  // Persistir el estado de error en sessionStorage
  persistErrorState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EssentialsActions.essentialsError),
        tap(() => {
          sessionStorage.setItem('essentials_error', JSON.stringify(true));  // Persistir error
        })
      ),
    { dispatch: false }
  );
}
