import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TermsConditionsService {
  private url = environment.apiURL;
  constructor(private _http: HttpClienteService, private http: HttpClient) {}

  getTermsConditionsStatus(id: any) {
    const query = `${this.url}/wcl-customer/customer-term-accepted?customerCode=${id}`;
    return this._http.get(query);
  }

  acceptTermsConditions(data: any) {
    const query = `${this.url}/wcl-customer/customer-term`;
    return this._http.post(query, data);
  }
}
