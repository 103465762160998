import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChildren, QueryList, AfterViewChecked, SimpleChanges, OnChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { Note } from '@core/interfaces/binnacle';
import { SelectedClient } from '@core/interfaces/selected-client';

@Component({
  selector: 'app-binnacle-table',
  templateUrl: './binnacle-table.component.html',
  styleUrls: ['./binnacle-table.component.scss']
})
export class BinnacleTableComponent implements AfterViewChecked, OnChanges {
  @Input() dataSource: any[] = [];  // Datos de la tabla
  @Input() displayedColumns: string[] = [];  // Columnas a mostrar
  @Input() isAnyRowEditing: boolean = false;  // Indica si alguna fila está en edición
  @Input() isLoadingRegistrationService: boolean = false;  // Estado de carga del servicio de registro
  @Input() isErrorRegistrationService: boolean = false;  // Estado de error en el servicio de registro
  @Input() dataConvertClientSelect: any;  // Datos de cliente seleccionado
  @Input() dateClients: Array<SelectedClient> = [];  // Lista de clientes seleccionados
  @Input() selectedClient: any;  // Cliente seleccionado
  @Input() user: any;  // Usuario
  @Input() rowErrors: any;  // Errores en las filas
  @Input() isRowBeingEdited: boolean = false;  // Estado de error en el servicio de registro
  @Input() isAddingRow: boolean = false;  // Estado si se está agregando una fila

  @Output() editRow = new EventEmitter<any>();  // Emisor para la edición de una fila
  @Output() focusRow = new EventEmitter<any>();  // Emisor para foco de una fila
  @Output() blurRow = new EventEmitter<any>();  // Emisor para pérdida de foco de una fila
  @Output() growRow = new EventEmitter<any>();  // Emisor para el crecimiento de una fila
  @Output() actionRetry = new EventEmitter<any>();  // Emisor para la acción de reintentar
  @Output() addNewRowEvent = new EventEmitter<any>();  // Emisor para nuevos registros

  @ViewChildren('agendaText') agendaTextElements!: QueryList<ElementRef>;
  @ViewChildren('categoryText') categoryTextElements!: QueryList<ElementRef>;
  @ViewChildren('plansText') plansTextElements!: QueryList<ElementRef>;
  @ViewChildren('responsibleText') responsibleTextElements!: QueryList<ElementRef>;
  @ViewChildren('supportText') supportTextElements!: QueryList<ElementRef>;

  isTruncationCalculated = false;  // Flag para evitar cálculos múltiples
  isTruncated: boolean[][] = [];  // Estado de truncamiento por fila
  isDateError: boolean = false;  // Estado de error de fecha
  private previousState: boolean | null = null;  // Estado previo de un elemento
// Lista para las notas guardadas o editadas
notesEdit: any[] = [];

// Lista para las notas eliminadas
deleteNotesEdit: { id: number }[] = [];
  statusOptions: string[] = ['Por iniciar', 'Con retraso', 'En proceso', 'Finalizado', 'En pausa', 'Desestimado'];  // Opciones de estado de las reuniones
  selectedEstado!: string;  // Estado seleccionado
  activeElement: any = null;  // Elemento activo en edición
  newNoteVendedor: string = '';  // Nueva nota del vendedor
  newNoteCliente: string = '';  // Nueva nota del cliente
  isOpen: boolean = false;  // Estado si el panel está abierto
  isOpenSatus: boolean = false;  // Estado si el panel de estado está abierto
  selectedRowId: number | null = null;  // ID de la fila seleccionada

  constructor(private datePipe: DatePipe, private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
    // Registro de iconos SVG
    this.matIconRegistry.addSvgIcon(`datepicker_icon`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/date-picker-icon.svg'));
    this.matIconRegistry.addSvgIcon(`datepicker_icon_disabled`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/date-picker-icon_disabled.svg'));
    this.matIconRegistry.addSvgIcon(`icon_eye_green`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/icon_eye_green.svg'));

    // Inicializamos el cliente seleccionado
    this.changeValue(this.dateClients[0]?.code);
  }

  ngAfterViewChecked(): void {
    // Aseguramos que Angular haya procesado el DOM
    if (!this.isTruncationCalculated || this.isAddingRow) {
      this.cdr.detectChanges();  // Forzamos la detección de cambios una sola vez
      this.checkTextTruncation();  // Verificamos el truncamiento del texto
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Detecta cambios en el estado de agregar fila
    if (changes['isAddingRow'] && !changes['isAddingRow'].firstChange) {
      this.cdr.detectChanges();
      this.isTruncationCalculated = false;  // Forzamos recalcular el truncamiento
    }
  }

  checkTextTruncation(): void {
    if (!this.isTruncationCalculated || this.isAddingRow) {
      this.cdr.detectChanges();  // Forzamos la detección de cambios una sola vez

      // Grupos de elementos que deben ser revisados para truncamiento
      const elementsGroups = [
        this.agendaTextElements,
        this.categoryTextElements,
        this.plansTextElements,
        this.responsibleTextElements,
        this.supportTextElements
      ];

      // Iteramos sobre los grupos de elementos y calculamos el truncamiento
      elementsGroups.forEach((elements, groupIndex) => {
        if (elements && elements.length > 0) {
          // Inicializamos el array en isTruncated si no está inicializado
          if (!this.isTruncated[groupIndex]) {
            this.isTruncated[groupIndex] = [];
          }
          elements.toArray().forEach((element, index) => {
            const el = element.nativeElement;

            // Verificamos si el contenido está truncado
            const isTruncated = el.scrollHeight > el.offsetHeight;
            if (this.isAddingRow) {
              this.isTruncated[groupIndex][index + 1] = isTruncated;
            } else {
              this.isTruncated[groupIndex][index] = isTruncated;
            }
          });
        }
      });

      // Marcamos que los cálculos han sido realizados
      this.isTruncationCalculated = true;

      // Forzamos la detección de cambios para actualizar la vista
      this.cdr.detectChanges();
    }
  }

  // Emitir evento de cambio en los datos
  onDataChange(element, nameInput) {
    this.addNewRowEvent.emit({ element, nameInput });
  }

  // Emitir evento de edición de una fila
  onEdit(row: any) {
    this.editRow.emit(row);
  }

  // Emitir evento de foco en un área de texto
  onFocus(textarea: HTMLTextAreaElement) {
    this.focusRow.emit(textarea);
  }

  // Emitir evento de pérdida de foco en un área de texto
  onBlur(textarea: HTMLTextAreaElement) {
    this.blurRow.emit(textarea);
  }

  // Emitir evento de crecimiento de un área de texto
  autoGrow(value: any) {
    this.growRow.emit(value);
  }

  // Manejo de apertura y cierre del panel de selección
  onSelectPanelOpened() {
    this.isOpen = true;
  }

  onSelectPanelClosed() {
    this.isOpen = false;
  }

  onSelectPanelOpenedStatus() {
    this.isOpenSatus = true;
  }

  onSelectPanelClosedStatus() {
    this.isOpenSatus = false;
  }

  // Retorna la clase CSS asociada al estado
  getEstadoClass(estado: string): string {
    switch (estado) {
      case 'Por iniciar': return 'status-to-start';
      case 'Con retraso': return 'status-delayed';
      case 'En proceso': return 'status-in-progress';
      case 'Finalizado': return 'status-completed';
      case 'En pausa': return 'status-paused';
      case 'Desestimado': return 'status-dismissed';
      default: return 'status-dismissed';  // En caso de valor inesperado
    }
  }

  // Método para formatear las fechas en el formato dd MMM
  formatDateRange(dateRange: { start: Date, end: Date }): string {
    const start = this.datePipe.transform(dateRange.start, 'dd MMM');
    const end = this.datePipe.transform(dateRange.end, 'dd MMM');
    return `${start} - ${end}`;
  }

  // Cambiar valor del cliente seleccionado
  changeValue(clientSelect) {
    this.dataConvertClientSelect = clientSelect?.code;
  }

  // Método para manejar la activación y edición de un elemento
  setActiveElement(element: any, type: 'seller' | 'client') {
    this.activeElement = element;

    if (type === 'seller' && !this.activeElement.notasVendedor) {
      this.activeElement.notasVendedor = [];
    }
    if (type === 'client' && !this.activeElement.notasCliente) {
      this.activeElement.notasCliente = [];
    }
  }

  // Guardar notas
  saveNotes(tipo: string, textoNota: string) {
    if (!textoNota?.trim()) return;

    const noteNew = { type: tipo === 'vendedor' ? 'S' : 'C', note: textoNota.trim() };

    if (tipo === 'vendedor') {
      this.activeElement['Notas del vendedor']?.unshift(noteNew);
      this.newNoteVendedor = '';  // Limpiar campo de nueva nota
    } else if (tipo === 'cliente') {
      this.activeElement['Notas del cliente']?.unshift(noteNew);
      this.newNoteCliente = '';  // Limpiar campo de nueva nota
    }
  }
 
  // Eliminar nota
  deleteNote(tipo: string, index: number) {
    if (!this.activeElement) return;

    const notes = tipo === 'vendedor' ? 'Notas del vendedor' : 'Notas del cliente';
    if (Array.isArray(this.activeElement[notes])) {
      this.activeElement[notes].splice(index, 1);
    }
  }

  saveNotesEdit(tipo: string, textoNota: string) {
    if (!textoNota?.trim()) return;

    const noteNew = {
      type: tipo === 'vendedor' ? 'S' : 'C',
      note: textoNota.trim(),
      isNew: true, // Etiqueta para identificar notas nuevas
    };
  
    if (tipo === 'vendedor') {
      this.activeElement['Notas del vendedor']?.unshift(noteNew);
      this.newNoteVendedor = '';
    } else if (tipo === 'cliente') {
      this.activeElement['Notas del cliente']?.unshift(noteNew);
      this.newNoteVendedor = '';
    }
    }


deleteNoteEdit(tipo: string, index: number,id:number) {
  if (!this.activeElement) return;

  const notes = tipo === 'vendedor' ? 'Notas del vendedor' : 'Notas del cliente';
  if (Array.isArray(this.activeElement[notes])) {
    this.activeElement[notes].splice(index, 1);
  } const noteDelete = {
    type: tipo === 'vendedor' ? 'S' : 'C',
    id: id,
    isDelete: true, // Etiqueta para identificar notas nuevas
  };


      this.activeElement['Notas del vendedor']?.unshift(noteDelete);
  
}

  // Cancelar la nota actual
  cancelNote(tipo: string): void {
    if (tipo === 'vendedor') this.newNoteVendedor = '';
    else if (tipo === 'cliente') this.newNoteCliente = '';
  }

  // Verificar si hay alguna fila en edición o nueva
  verifyAndUpdateRow() {
    return this.dataSource.some(row => row.isEditing) || this.dataSource.some(row => row.isNewRegister);
  }

  // Cambiar estado del toggle
  onToggleChange(event: any, element: any, menuTrigger: MatMenuTrigger) {
    const newState = event.checked;
    if (element['Visibilidad cliente'] && element.isEditing) {
      event.source.checked = true;  // Mantener el estado original
      return;
    }

    if (!element['Visibilidad cliente'] && newState) {
      this.previousState = element['Visibilidad cliente'];
      menuTrigger.openMenu();
    } else {
      element['Visibilidad cliente'] = newState;
    }
  }

  // Confirmar el cambio de estado del toggle
  confirmToggleChange(element: any) {
    element['Visibilidad cliente'] = true;
  }

  // Emite un evento de acción de reintento
  actionCallback(row) {
    this.actionRetry.emit(row);
  }
}
