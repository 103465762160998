<section class="the-essentials-container">
  <div class="container pb-16 mt-16">
    <!-- Caja principal que contiene el título y el filtro -->
    <div class="p-24 general-box-container">
      <div class="donot-miss-it-container">
        <div>
          <div class="d-flex align-items-center">
            <img src="assets/image/home/icon-essential.svg" alt class="pr-8" />
            <p class="m-0 fts-24 font-bold-dali">Los infaltables</p>
          </div>
          <p class="m-0 p-0 text-donot-miss-it font-family-italic fts-16">
            ¡No te pierdas estos productos!
          </p>
        </div>

        <!-- Campo de búsqueda -->
        <div class="d-flex align-items-center width-custom">
          <div *ngIf="loading$ | async; else loadingSkeltonNot">
            <ngx-skeleton-loader
              class="custom-loader"
              [theme]="{
                width: '328px',
                'border-radius': '8px',
                height: '40px',
                'margin-bottom': '0px',
                display: 'flex',
                'align-items': 'center'
              }"
            >
            </ngx-skeleton-loader>
          </div>
          <ng-template #loadingSkeltonNot>
            <div class="vw-100 datepicker-input-40 d-flex align-items-center">
              <button
                mat-icon-button
                matPrefix
                [disabled]="isDisabled(error$ | async)
                "
              >
                <img [src]="isDisabled(error$ | async)?'/assets/svg/forms/search-disabled.svg':'/assets/svg/forms/search.svg'" alt="search" />
              </button>
              <input
                matInput
                placeholder="Busca un producto"
                class="input-search font-regular fts-16"
                [(ngModel)]="searchTerm"
                (ngModelChange)="filterProducts()"
                [disabled]="isDisabled(error$ | async) "
              />
              <button
                mat-icon-button
                matSuffix
                *ngIf="searchTerm"
                (click)="searchTerm = ''; filterProducts()"
              >
                <img src="/assets/svg/forms/delete.svg" alt="clear" />
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Zona de Ofertas -->
    <div class="p-24 general-box-container mt-16">
      <div class="d-flex justify-content-between">
        <div>
          <p class="m-0 fts-24 font-bold-dali">Zona de Ofertas</p>
          <p class="mt-5 mb-0 ml-0 mr-0 p-0 font-regular fts-16">
            Precios especiales en estos productos. ¡Aprovecha ahora!
          </p>
        </div>
      </div>
      <div class="mt-16 d-flex">
        <div class="vw-100">
          <!-- Mostrar skeleton cuando el estado es 'loading' -->
          <ng-template #skeletonTemplate>
            <ng-container *ngIf="loading$ | async; else errorTemplate">
              <div class="infaltables-section">
                <div
                  class="infaltables-section-dinamic"
                  *ngFor="let _ of [].constructor(5); let i = index"
                >
                  <div class="dinamic-card">
                    <app-essentials-card-skeleton></app-essentials-card-skeleton>
                  </div>
                </div>
              </div>
              <app-essentials-skeleton-paginator></app-essentials-skeleton-paginator>
            </ng-container>
          </ng-template>

          <!-- Mostrar skeleton en caso de error -->
          <ng-template #errorTemplate>
            <ng-container *ngIf="error$ | async; else nullData">
              <div class="error-message">
                <app-message-empty-error
                  [messageError]="messageError"
                  (retryDataError)="initEssentialsStore()"
                ></app-message-empty-error>
              </div>
            </ng-container>
          </ng-template>

          <!-- Mensaje en caso de datos nulos -->
          <ng-template #nullData>
            <ng-container>
              <div
                class="null-message d-flex justify-content-center align-items-center"
              >
                <app-message-empty-error
                  [messageError]="nullMessageTheEssencials"
                ></app-message-empty-error>
              </div>
            </ng-container>
          </ng-template>

          <!-- Mostrar los productos si no hay error -->
          <div
            [ngClass]="{
              'd-flex justify-content-center': (error$ | async)
            }"
          >
            <ng-container *ngIf="cards?.length > 0; else skeletonTemplate">
              <app-carousel-essentials
                [cards]="cards"
              ></app-carousel-essentials>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Los más vendidos -->
    <div class="p-24 general-box-container mt-16">
      <div class="d-flex justify-content-between">
        <div>
          <p class="m-0 fts-24 font-bold-dali">Los más vendidos</p>
          <p class="mt-5 mb-0 ml-0 mr-0 p-0 font-regular fts-16">
            Los productos que todos prefieren, ahora a tu alcance.
          </p>
        </div>
      </div>
      <div class="mt-16 d-flex">
        <div class="vw-100">
          <div
            class="alert-error d-flex align-items-center p-16"
            *ngIf="responseError"
          >
            <span class="mr-8">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="#E16C00" />
                <path
                  d="M18.6885 15.7638L13.259 6.22942C12.5377 4.9704 11.4623 4.9704 10.741 6.22942L5.31149 15.7507C4.59017 17.0097 5.12788 17.9278 6.5705 17.9278L17.4164 17.9409C18.859 17.9409 19.4098 17.0229 18.6885 15.7638ZM12.0394 14.5048C12.459 14.5048 12.7869 14.8327 12.7869 15.2524C12.7869 15.6589 12.459 15.9999 12.0394 15.9999C11.6197 15.9999 11.2918 15.6589 11.2918 15.2524C11.2918 14.8327 11.6197 14.5048 12.0394 14.5048ZM11.5148 10.1376H12.5508L12.5639 13.5081H11.5148V10.1376Z"
                  fill="#FCFCFC"
                />
              </svg>
            </span>
            <span class="fts-14 font-regular">{{ menssageError }}</span>
          </div>

          <div
            [ngClass]="{
              'd-flex justify-content-center': (error$ | async)
            }"
          >
            <ng-container *ngIf="cardsType2?.length > 0; else skeletonTemplate">
              <app-carousel-essentials
                [cards]="cardsType2"
              ></app-carousel-essentials>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Para ti -->
    <div class="p-24 general-box-container mt-16">
      <div class="d-flex justify-content-between">
        <div>
          <p class="m-0 fts-24 font-bold-dali">Para ti</p>
          <p class="mt-5 mb-0 ml-0 mr-0 p-0 font-regular fts-16">
            Recomendaciones hechas especialmente para ti.
          </p>
        </div>
      </div>
      <div class="mt-16 d-flex">
        <div class="vw-100">
          <div
            class="alert-error d-flex align-items-center p-16"
            *ngIf="responseError"
          >
            <span class="mr-8">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="#E16C00" />
                <path
                  d="M18.6885 15.7638L13.259 6.22942C12.5377 4.9704 11.4623 4.9704 10.741 6.22942L5.31149 15.7507C4.59017 17.0097 5.12788 17.9278 6.5705 17.9278L17.4164 17.9409C18.859 17.9409 19.4098 17.0229 18.6885 15.7638ZM12.0394 14.5048C12.459 14.5048 12.7869 14.8327 12.7869 15.2524C12.7869 15.6589 12.459 15.9999 12.0394 15.9999C11.6197 15.9999 11.2918 15.6589 11.2918 15.2524C11.2918 14.8327 11.6197 14.5048 12.0394 14.5048ZM11.5148 10.1376H12.5508L12.5639 13.5081H11.5148V10.1376Z"
                  fill="#FCFCFC"
                />
              </svg>
            </span>
            <span class="fts-14 font-regular">{{ menssageError }}</span>
          </div>

          <div
            [ngClass]="{
              'd-flex justify-content-center': (error$ | async)
            }"
          >
            <ng-container *ngIf="cardsType3?.length > 0; else skeletonTemplate">
              <app-carousel-essentials
                [cards]="cardsType3"
              ></app-carousel-essentials>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
