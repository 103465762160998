<div class="content-message d-flex align-items-center justify-content-center hp-100" [ngClass]="messageError[0].classCustom?messageError[0].classCustom:'p-16'">
  <div class="ta-c w-100 py-24 ta-c">
    <div class="ta-c img-password" *ngIf="messageError[0]?.icon">
      <img src="{{messageError[0].icon}}" alt="alert">
    </div>
    <div class="ta-c vw-100 container-iconText" *ngIf="messageError[0]?.iconText">
      <span class="img-iconText"> {{messageError[0].iconText}}</span>
     </div>
    <div class="p-16">
      <div [ngClass]="(messageError[0].classCustom)?(messageError[0].classCustom):'headingMessage'">{{ messageError[0].title }}</div>
    </div>
    <div class="align-items-center">
      <span class="message-alert" [innerHTML]="sanitizeHtml(messageError[0].description)">
        {{ messageError[0].description }}
      </span>
    </div>
    <div *ngIf="messageError[0].button" class="mt-24 d-flex content-button justify-content-center">
      <button mat-flat-button color="accent"class="font-bold ph-24 vw-100" (click)="buttonRetry(messageError[0].textButton)">
        <span *ngIf="!loginInProcess">{{ messageError[0].textButton }} </span>
        <span *ngIf="loginInProcess">
          <mat-icon>
            <mat-spinner diameter="24"></mat-spinner>
          </mat-icon>
        </span>
      </button>
    </div>
  </div>
</div>
