import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessFormServiceService {
  private accessAllowed = false;
  
  constructor() {}

  allowAccess(params: { idForm: string; idActivity: string; idRoutine: string }) {
    sessionStorage.setItem('accessAllowed', 'true');
    sessionStorage.setItem('allowedFormId', params.idForm);
    sessionStorage.setItem('allowedActivityId', params.idActivity);
    sessionStorage.setItem('allowedRoutineId', params.idRoutine);
  }

  resetAccess() {
    sessionStorage.removeItem('accessAllowed');
    sessionStorage.removeItem('allowedFormId');
    sessionStorage.removeItem('allowedActivityId');
    sessionStorage.removeItem('allowedRoutineId');
  }

  isAccessAllowed(): boolean {
    return sessionStorage.getItem('accessAllowed') === 'true';
  }

  getAllowedFormId(): string | null {
    return sessionStorage.getItem('allowedFormId');
  }

  getAllowedActivityId(): string | null {
    return sessionStorage.getItem('allowedActivityId');
  }

  getAllowedRoutineId(): string | null {
    return sessionStorage.getItem('allowedRoutineId');
  }
}
