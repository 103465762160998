<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fts-12">
  <!-- Columnas de la tabla -->
  <ng-container matColumnDef="Visibilidad cliente">
    <th mat-header-cell *matHeaderCellDef class="fts-12 font-regular">
      Visibilidad cliente
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container
        *ngIf="
          element.isEditing || element.isNewRegister;
          else readModeVisibilidad
        "
      >
        <mat-slide-toggle
          [ngModel]="element['Visibilidad cliente']"
          (change)="onToggleChange($event, element, confirmMenuTrigger)"
          [disabled]="
            (element['Visibilidad cliente'] && element.isEditing) ||
            isLoadingRegistrationService
          "
        >
        </mat-slide-toggle>

        <!-- Mat Menu con trigger -->
        <button
          mat-button
          [matMenuTriggerFor]="confirmMenu"
          #confirmMenuTrigger="matMenuTrigger"
          class="d-flex"
        ></button>
        <mat-menu
          #confirmMenu="matMenu"
          xPosition="before"
          yPosition="above"
          class="custom-menu-z-index mat-menu-toggle"
        >
          <div class="">
            <div class="d-flex pb-3 align-items-center">
              <div class="icon-delete fts-18" emoji="☝️">☝️</div>
              <div class="font-regular fts-16 ml-8 line-height-20">
                Esta opción no podrá ser cambiada después de guardar tu
                registro.
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div>
                <button
                  (click)="confirmToggleChange(element)"
                  mat-raised-button
                  color="accent"
                  class="fts-14 font-semiBold"
                >
                  Entendido
                </button>
              </div>
            </div>
          </div>
        </mat-menu>
      </ng-container>

      <ng-template #readModeVisibilidad>
        <ng-container
          *ngIf="element['Visibilidad cliente'] === true; else noImage"
        >
          <img
            src="assets/image/home-seller/check_regular.svg"
            alt="Imagen visible"
          />
        </ng-container>
        <ng-template #noImage>-</ng-template>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Cliente">
    <th mat-header-cell *matHeaderCellDef>Cliente</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container
        *ngIf="element.isEditing || element.isNewRegister; else readClient"
      >
        <div class="d-flex align-items-center justify-content-center pl-8 pr-8">
          <mat-form-field
            [floatLabel]="'false'"
            appearance="outline"
            color="accent"
            *ngIf="dateClients; else squeletonClientList"
            class="vw-100 select-general-40 font-regular fts-14 client-container"
            [class.error]="rowErrors[i]?.['Cliente']"
          >
            <mat-select
              [(ngModel)]="element['Cliente']"
              name="clientBinnacle"
              [ngClass]="{ 'hide-select-arrow': true }"
              [disabled]="
                dateClients?.length === 1 || isLoadingRegistrationService
              "
              #selectClientBinnacle="matSelect"
              (opened)="onSelectPanelOpened()"
              (closed)="onSelectPanelClosed()"
              placeholder="Elige un cliente"
              (ngModelChange)="onDataChange(element, 'Cliente')"
            >
              <mat-option
                *ngFor="let client of dateClients"
                [value]="client.code"
              >
                <span class="fts-14 font-regular"
                  >{{ client.code }} - {{ client.name }}</span
                >

                <mat-icon
                  *ngIf="element['Cliente'] === client?.code"
                  class="icon-check"
                >
                  <img src="assets/image/home-seller/check_regular.svg" alt />
                </mat-icon>
              </mat-option>
              <!-- Opciones en duro -->
              <mat-option value="OTRO">
                <span>Otro</span>
                <mat-icon
                  *ngIf="element['Cliente'] === 'OTRO'"
                  class="icon-check"
                >
                  <img src="assets/image/home-seller/check_regular.svg" alt />
                </mat-icon>
              </mat-option>
              <mat-option value="NINGUNO">
                <span>Ninguno</span>
                <mat-icon
                  *ngIf="element['Cliente'] === 'NINGUNO'"
                  class="icon-check"
                >
                  <img src="assets/image/home-seller/check_regular.svg" alt />
                </mat-icon>
              </mat-option>
            </mat-select>
            <span class="material-symbols-rounded color-active">{{
              isOpen ? "expand_less" : "expand_more "
            }}</span>
          </mat-form-field>
          <ng-template #squeletonClientList>
            <div class="d-flex align-items-center vw-100">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  height: '48px',
                  width: '100%',
                  'border-radius': '8px',
                  ' margin-bottom': '0px'
                }"
              >
              </ngx-skeleton-loader>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #readClient>
        {{ element["Cliente"]
        }}<span *ngIf="element.nameClient">- {{ element.nameClient }}</span>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Fecha de ejecución Inicio - Fin">
    <th mat-header-cell *matHeaderCellDef>Fecha de ejecución Inicio - Fin</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container
        *ngIf="element.isEditing || element.isNewRegister; else readModeFecha"
      >
        <div class="d-flex align-items-center justify-content-center pl-8 pr-8">
          <mat-form-field
            class="datepicker-input-40 font-regular fts-14"
            floatLabel="never"
            [class.error-date]="isDateError || rowErrors[i]?.['Fecha de ejecución Inicio - Fin']"
            [disabled]="isLoadingRegistrationService"
          >
            <mat-label>Elige las fechas</mat-label>
            <mat-date-range-input
              [rangePicker]="picker"
              class="input-picker font-regular fts-14 color-subTitle-custom"
            >
              <input
                matStartDate
                [(ngModel)]="element['Fecha de ejecución Inicio - Fin'].start"
                [disabled]="
                  isLoadingRegistrationService ||
                  (!element.isExecutionDateEditable && element.isEditing)
                "
                (ngModelChange)="
                  onDataChange(element, 'Fecha de ejecución Inicio - Fin')
                "
                placeholder="Start date"
              />
              <input
                matEndDate
                [(ngModel)]="element['Fecha de ejecución Inicio - Fin'].end"
                [disabled]="
                  isLoadingRegistrationService ||
                  (!element.isExecutionDateEditable && element.isEditing)
                "
                (ngModelChange)="
                  onDataChange(element, 'Fecha de ejecución Inicio - Fin')
                "
                placeholder="End date"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
              class="mat-datepicker-toggle"
            >
              <mat-icon
                matDatepickerToggleIcon
                [svgIcon]="
                  isLoadingRegistrationService
                    ? 'datepicker_icon_disabled'
                    : 'datepicker_icon'
                "
              ></mat-icon
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-template #readModeFecha>
        {{ formatDateRange(element["Fecha de ejecución Inicio - Fin"]) }}
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Estado">
    <th mat-header-cell *matHeaderCellDef>Estado</th>
    <td mat-cell *matCellDef="let element">
      <ng-container
        *ngIf="element.isEditing || element.isNewRegister; else readModeEstado"
      >
        <div class="d-flex align-items-center justify-content-center pr-8 pl-8">
          <mat-form-field
            [floatLabel]="'false'"
            appearance="outline"
            color="accent"
            class="vw-100 select-general-40 font-bold-dali fontw-700 fts-16"
          >
            <mat-select
              [(ngModel)]="element['Estado']"
              (ngModelChange)="onDataChange(element, 'Estado')"
              name="status"
              [ngClass]="{ 'hide-select-arrow': true }"
              #selectStatus="matSelect"
              placeholder="Elige un estado"
              (opened)="onSelectPanelOpenedStatus()"
              (closed)="onSelectPanelClosedStatus()"
              [disabled]="isLoadingRegistrationService"
            >
              <mat-option
                *ngFor="let status of statusOptions"
                [value]="status"
                [class.mat-selected]="element['Estado'] === status"
              >
                <span
                  class="fts-16 font-regular"
                  [ngClass]="{
                    'color-red': element['Estado'] === status,
                    'color-title-custom': element['Estado'] !== status
                  }"
                >
                  {{ status }}
                </span>

                <!-- Check icon -->
                <mat-icon
                  *ngIf="element['Estado'] === status"
                  class="icon-check d-flex justify-content-end align-items-center"
                >
                  <img
                    src="assets/image/home-seller/check_regular.svg"
                    width="20px"
                  />
                </mat-icon>
              </mat-option>
            </mat-select>
            <span
              class="material-symbols-rounded"
              [ngClass]="
                statusOptions?.length <= 1 ? 'color-disabled ' : 'color-active'
              "
              >{{ isOpenSatus ? "expand_less" : "expand_more " }}</span
            >
          </mat-form-field>
        </div>
      </ng-container>
      <ng-template #readModeEstado>
        <div class="d-flex align-items-center justify-content-center">
          <span class="status" [ngClass]="getEstadoClass(element['Estado'])">
            {{ element["Estado"] }}
          </span>
        </div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Agenda">
    <th mat-header-cell *matHeaderCellDef>Agenda</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container
        *ngIf="element.isEditing || element.isNewRegister; else readModeAgenda"
      >
        <div class="d-flex justify-content-center">
          <textarea
            [(ngModel)]="element['Agenda']"
            (ngModelChange)="onDataChange(element, 'Agenda')"
            class="editable-input auto-expand-text font-regular fts-14"
            (focus)="onFocus($event.target)"
            (blur)="onBlur($event.target)"
            (input)="autoGrow($event)"
            [rows]="1"
            [class.error]="rowErrors[i]?.['Agenda']"
            maxlength="1000"
            [disabled]="isLoadingRegistrationService"
          >
          </textarea>
        </div>
      </ng-container>
      <ng-template #readModeAgenda>
        <div
          [class]="
            isTruncated[0] && isTruncated[0][i]
              ? 'd-flex align-items-center justify-content-between ta-s'
              : 'd-flex align-items-center justify-content-center '
          "
        >
          <div
            class="d-flex align-items-center justify-content-between multi-line-text"
            #agendaText
          >
            <div>
              {{ element["Agenda"] }}
            </div>
          </div>
          <div
            *ngIf="isTruncated[0] && isTruncated[0][i]"
            class="ml-8 d-flex align-items-center"
          >
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-desktop-tablet"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Agenda']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (mouseover)="tooltipDesktop1?.show()"
              #tooltipDesktop1="matTooltip"
            >
            </mat-icon>

            <mat-icon
              svgIcon="icon_eye_green"
              class="show-mobile"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Agenda']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (click)="tooltipMobile1?.toggle()"
              #tooltipMobile1="matTooltip"
            >
            </mat-icon>
          </div>
        </div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Categoría">
    <th mat-header-cell *matHeaderCellDef>Categoría</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container
        *ngIf="
          element.isEditing || element.isNewRegister;
          else readModeCategoria
        "
      >
        <div class="d-flex justify-content-center">
          <textarea
            [(ngModel)]="element['Categoría']"
            (ngModelChange)="onDataChange(element, 'Categoría')"
            class="editable-input auto-expand-text font-regular fts-14"
            (focus)="onFocus($event.target)"
            (blur)="onBlur($event.target)"
            (input)="autoGrow($event)"
            [rows]="1"
            [class.error]="rowErrors[i]?.['Categoría']"
            maxlength="1000"
            [disabled]="isLoadingRegistrationService"
          >
          </textarea>
        </div>
      </ng-container>
      <ng-template #readModeCategoria>
        <div
          [class]="
            isTruncated[1] && isTruncated[1][i]
              ? 'd-flex align-items-center justify-content-between ta-s'
              : 'd-flex align-items-center justify-content-center '
          "
        >
          <div
            class="d-flex align-items-center justify-content-between multi-line-text"
            #categoryText
          >
            <div>
              {{ element["Categoría"] }}
            </div>
          </div>
          <div
            *ngIf="isTruncated[1] && isTruncated[1][i]"
            class="ml-8 d-flex align-items-center"
          >
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-desktop-tablet"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Categoría']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (mouseover)="tooltipDesktop2?.show()"
              #tooltipDesktop2="matTooltip"
            ></mat-icon>
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-mobile"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Categoría']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (click)="tooltipMobile2?.toggle()"
              #tooltipMobile2="matTooltip"
            ></mat-icon>
          </div>
        </div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Planes de acción / Actividades">
    <th mat-header-cell *matHeaderCellDef>Planes de acción / Actividades</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container
        *ngIf="element.isEditing || element.isNewRegister; else readModePlanes"
      >
        <div class="d-flex justify-content-center">
          <textarea
            [(ngModel)]="element['Planes de acción / Actividades']"
            (ngModelChange)="
              onDataChange(element, 'Planes de acción / Actividades')
            "
            class="editable-input auto-expand-text font-regular fts-14"
            (focus)="onFocus($event.target)"
            (blur)="onBlur($event.target)"
            (input)="autoGrow($event)"
            [rows]="1"
            [class.error]="rowErrors[i]?.['Planes de acción / Actividades']"
            maxlength="1000"
            [disabled]="isLoadingRegistrationService"
          >
          </textarea>
        </div>
      </ng-container>
      <ng-template #readModePlanes>
        <div
          [class]="
            isTruncated[2] && isTruncated[2][i]
              ? 'd-flex align-items-center justify-content-between ta-s'
              : 'd-flex align-items-center justify-content-center '
          "
        >
          <div
            class="d-flex align-items-center justify-content-between multi-line-text"
            #plansText
          >
            <div>
              {{ element["Planes de acción / Actividades"] }}
            </div>
          </div>
          <div
            *ngIf="isTruncated[2] && isTruncated[2][i]"
            class="ml-8 d-flex align-items-center"
          >
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-desktop-tablet"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Planes de acción / Actividades']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (mouseover)="tooltipDesktop3?.show()"
              #tooltipDesktop3="matTooltip"
            ></mat-icon>
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-mobile"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Planes de acción / Actividades']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (click)="tooltipMobile3?.toggle()"
              #tooltipMobile3="matTooltip"
            ></mat-icon>
          </div>
        </div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Responsable">
    <th mat-header-cell *matHeaderCellDef>Responsable</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container
        *ngIf="
          element.isEditing || element.isNewRegister;
          else readModeResponsable
        "
      >
        <div class="d-flex justify-content-center">
          <textarea
            [(ngModel)]="element['Responsable']"
            (ngModelChange)="onDataChange(element, 'Responsable')"
            class="editable-input auto-expand-text font-regular fts-14"
            (focus)="onFocus($event.target)"
            (blur)="onBlur($event.target)"
            (input)="autoGrow($event)"
            [rows]="1"
            [class.error]="rowErrors[i]?.['Responsable']"
            maxlength="1000"
            [disabled]="isLoadingRegistrationService"
          >
          </textarea>
        </div>
      </ng-container>
      <ng-template #readModeResponsable>
        <div
          [class]="
            isTruncated[3] && isTruncated[3][i]
              ? 'd-flex align-items-center justify-content-between ta-s'
              : 'd-flex align-items-center justify-content-center '
          "
        >
          <div
            class="d-flex align-items-center justify-content-between multi-line-text"
            #responsibleText
          >
            <div>
              {{ element["Responsable"] }}
            </div>
          </div>
          <div
            *ngIf="isTruncated[3] && isTruncated[3][i]"
            class="ml-8 d-flex align-items-center"
          >
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-desktop-tablet"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Responsable']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (mouseover)="tooltipDesktop4?.show()"
              #tooltipDesktop4="matTooltip"
            ></mat-icon>
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-mobile"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Responsable']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (click)="tooltipMobile4?.toggle()"
              #tooltipMobile4="matTooltip"
            ></mat-icon>
          </div>
        </div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Área soporte">
    <th mat-header-cell *matHeaderCellDef>Área soporte</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ng-container
        *ngIf="element.isEditing || element.isNewRegister; else readModeArea"
      >
        <div class="d-flex justify-content-center">
          <textarea
            [(ngModel)]="element['Área soporte']"
            (ngModelChange)="onDataChange(element, 'Área soporte')"
            class="editable-input auto-expand-text font-regular fts-14"
            (focus)="onFocus($event.target)"
            (blur)="onBlur($event.target)"
            (input)="autoGrow($event)"
            [rows]="1"
            [class.error]="rowErrors[i]?.['Área soporte']"
            maxlength="1000"
            [disabled]="isLoadingRegistrationService"
          >
          </textarea>
        </div>
      </ng-container>
      <ng-template #readModeArea>
        <div
          [class]="
            isTruncated[4] && isTruncated[4][i]
              ? 'd-flex align-items-center justify-content-between ta-s'
              : 'd-flex align-items-center justify-content-center '
          "
        >
          <div
            class="d-flex align-items-center justify-content-between multi-line-text"
            #supportText
          >
            <div>
              {{ element["Área soporte"] }}
            </div>
          </div>
          <div
            *ngIf="isTruncated[4] && isTruncated[4][i]"
            class="ml-8 d-flex align-items-center"
          >
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-desktop-tablet"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Área soporte']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (mouseover)="tooltipDesktop5?.show()"
              #tooltipDesktop5="matTooltip"
            ></mat-icon>
            <mat-icon
              svgIcon="icon_eye_green"
              class="show-mobile"
              [ngStyle]="{ width: '1rem', cursor: 'pointer' }"
              [matTooltip]="element['Área soporte']"
              matTooltipClass="custom-tooltip"
              matTooltipPosition="above"
              (click)="tooltipMobile5?.toggle()"
              #tooltipMobile5="matTooltip"
            ></mat-icon>
          </div>
        </div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="Fecha de creación">
    <th mat-header-cell *matHeaderCellDef>Fecha de creación</th>
    <td mat-cell *matCellDef="let element">
      <ng-container
        *ngIf="
          element.isEditing || element.isNewRegister;
          else readModeCreacion
        "
      >
        -
      </ng-container>
      <ng-template #readModeCreacion>
        {{ element["Fecha de creación"] }}
      </ng-template>
    </td>
  </ng-container>

  <!-- Columna para Notas del vendedor -->
  <ng-container matColumnDef="Notas del vendedor">
    <th mat-header-cell *matHeaderCellDef>Notas del vendedor</th>
    <td mat-cell *matCellDef="let element">
      <ng-container
        *ngIf="
          !isLoadingRegistrationService && user.type === 'Vendedor';
          else readModeVendedor
        "
      >
        <img
          src="assets/image/home-seller/notes.svg"
          [matMenuTriggerFor]="menuNotasVendedor"
          (click)="setActiveElement(element, 'seller')"
          class="clickable-icon"
        />
      </ng-container>

      <ng-template #readModeVendedor>
        <img
          *ngIf="element['Notas del vendedor']?.length > 0; else littleScript"
          src="assets/image/home-seller/notes-disabled.svg"
          class="notes-disabled"
        />
        <ng-template #littleScript>-</ng-template>
      </ng-template>
      <!-- Menú para notas del vendedor -->
      <mat-menu
        #menuNotasVendedor="matMenu"
        class="custom-menu-panel"
        yPosition="above"
        [overlapTrigger]="false"
      >
        <div class="p-24">
          <div
            class="d-flex justify-content-between align-items-start container-notes"
          >
            <div class="fts-16 font-bold-dali">Tus notas</div>
            <div class="button-x justify-content-end d-flex">
              <img
                class="cursor-pointer"
                src="assets/image/modal/close-modal.svg"
                alt="alert"
                width="14px"
                (click)="cancelNote('vendedor')"
              />
            </div>
          </div>
          <div class="dl-divider mb-24"></div>
          <!-- listado de notas  -->
          <ng-container
            *ngIf="activeElement && activeElement['Notas del vendedor']"
          >
            <div class="container-list-note">
              <ng-container *ngFor="
              let note of activeElement['Notas del vendedor'];
              let i = index
            " >
              <div
               
                *ngIf="!note.isDelete"
                class="d-flex align-items-start justify-content-between mb-16"
              >
                <div class="fts-16 font-regular">
                  {{ note.note }}<br />
                  <div class="fts-14 font-semiBold dateRegister">
                    {{ note.createdAt ? note.createdAt : "-" }}
                  </div>
                </div>
                <div *ngIf="element.isEditing || element.isNewRegister">
                  <span
                    class="ml-8 d-flex align-items-center"
                    (click)="
                      element.isNewRegister
                        ? deleteNote('vendedor', i)
                        : deleteNoteEdit('vendedor', i,note.id);
                      $event.stopPropagation()
                    "
                    ><svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.2196 2.90839H10.2786V1.88544C10.2786 0.95429 9.59663 0.246094 8.65236 0.246094H5.12449C4.20646 0.246094 3.47204 0.941175 3.47204 1.88544V2.90839H1.76712C1.09826 2.90839 0.376953 3.48544 0.376953 4.28544H10.2655L9.68843 12.2986H4.12777L3.62941 5.24282H2.12122L2.71138 13.6756H11.1048L11.7737 4.28544H13.6229C13.6229 3.48544 12.8884 2.90839 12.2196 2.90839ZM4.98023 2.90839V2.08216C4.98023 1.74118 5.11138 1.62314 5.43925 1.62314H8.32449C8.65236 1.62314 8.7704 1.72806 8.7704 2.08216V2.90839H4.98023Z"
                        fill="#E62020"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="element.isEditing || element.isNewRegister">
            <div>
              <textarea
                placeholder="Ingresa una nota"
                class="editable-textArea ml-0 mr-0 font-regular fts-14"
                [(ngModel)]="newNoteVendedor"
                (click)="$event.stopPropagation()"
                maxlength="1000"
              ></textarea>
            </div>
            <div class="d-flex justify-content-end pt-16">
              <!-- Botones de guardar y cancelar al agregar una fila -->
              <button
                mat-flat-button
                (click)="cancelNote('vendedor')"
                class="btn-cancel d-flex justify-content-center align-items-center mr-16"
              >
                Cancelar
              </button>
              <button
                class="font-semiBold fts-14"
                pButton
                [ngClass]="
                  !newNoteVendedor?.trim() ? 'btn-disabled' : 'btn-green'
                "
                [disabled]="!newNoteVendedor?.trim()"
                (click)="element.isNewRegister?
                  saveNotes('vendedor', newNoteVendedor): saveNotesEdit('vendedor', newNoteVendedor);
                  $event.stopPropagation()
                "
              >
                Guardar nota
              </button>
            </div>
          </ng-container>
        </div>
      </mat-menu>
    </td>
  </ng-container>

  <!-- Columna para Notas del cliente -->
  <ng-container matColumnDef="Notas del cliente">
    <th mat-header-cell *matHeaderCellDef>Notas del cliente</th>
    <td mat-cell *matCellDef="let element">
     
      <ng-container
      *ngIf="
        !isLoadingRegistrationService && user.type === 'Vendedor' &&element['Notas del cliente']?.length > 0;
        else readModeClienteNotas
      "
    >
     
      <img
      *ngIf="element.isNewRegister ||
        element.isEditing;
        else littleScriptCliente
      "
      src="assets/image/home-seller/notes-disabled.svg"
      class="notes-disabled"
    />
   <ng-template #littleScriptCliente> <img
    src="assets/image/home-seller/notes.svg"
    [matMenuTriggerFor]="menuNotasCliente"
    [disabled]="user.type === 'Vendedor'"
    (click)="setActiveElement(element, 'client')"
    class="clickable-icon"
  /></ng-template>
   
    </ng-container>
    <ng-template #readModeClienteNotas>-</ng-template>
    </td>
  </ng-container>

  <!-- Columna fija con el botón de editar -->
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef class="columna-fija-header">
      {{ isAnyRowEditing ? "Edición" : "Editar" }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      class="columna-fija mat-column-acciones"
    >
      <div class="d-flex justify-content-center">
        <div
          *ngIf="element.isEditing || element.isNewRegister; else editAction"
          class="d-flex align-items-center justify-content-center"
        >
          <!-- Si está cargando -->
          <div
            *ngIf="isLoadingRegistrationService"
            class="d-flex align-items-center justify-content-center"
          >
            <div class="icon">
              <div class="spinner-custom"></div>
            </div>
          </div>

          <!-- Si hay error -->
          <div
            *ngIf="isErrorRegistrationService && !isLoadingRegistrationService"
            class="d-flex align-items-center justify-content-center"
            (click)="actionCallback(element)"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.2324 6.99984H13.7406C13.7406 3.30148 10.7111 0.271973 7.01275 0.271973C4.82259 0.271973 2.72423 1.32115 1.49144 3.3146C1.66193 2.26542 1.57013 1.63591 1.55701 1.01951H0.232422L0.363569 5.13755L1.38652 5.84574L5.26849 4.5605L4.84881 3.3146C4.10127 3.55066 3.05209 3.82607 2.51439 4.23263C3.44554 2.84246 5.20291 1.72771 7.01275 1.72771C9.88488 1.72771 12.2324 4.07525 12.2324 6.99984ZM7.01275 13.7277C9.1898 13.7277 11.1308 12.6785 12.3636 11.0654C12.3373 11.7605 12.4291 12.3769 12.4423 12.9933H13.7668L13.6357 8.87525L12.6127 8.16706L8.73078 9.4523L9.15045 10.6982C9.88488 10.4621 10.6062 10.3179 11.3144 9.97689C10.3832 11.3671 8.79636 12.272 7.01275 12.272C4.11439 12.272 1.77996 9.92443 1.77996 6.99984H0.271766C0.271766 10.6982 3.28816 13.7277 7.01275 13.7277Z"
                fill="#E62020"
              />
            </svg>
          </div>

          <!-- Si no hay error y no está cargando, mostramos la línea -->

          <div
            *ngIf="
              !isLoadingRegistrationService &&
              !isErrorRegistrationService &&
              (element.isEditing || element.isNewRegister)
            "
          >
            -
          </div>
        </div>

        <ng-template #editAction>
          <div
            class="d-flex justify-content-center"
            [ngClass]="{ disabled: verifyAndUpdateRow() }"
            *ngIf="!verifyAndUpdateRow(); else disabledAction"
            (click)="onEdit(element)"
          >
            <img
              src="assets/image/home-seller/edit.svg"
              height="12.64"
              alt="Editar"
              class="icon d-flex align-items-center mr-8"
            />
          </div>
          <ng-template #disabledAction>
            <div class="d-flex justify-content-center disabled">
              <img
                src="assets/image/home-seller/edit-disabled.svg"
                height="12.64"
                alt="Editar deshabilitado"
                class="icon d-flex align-items-center mr-8"
              />
            </div>
          </ng-template>
        </ng-template>
      </div>
    </td>
  </ng-container>

  <!-- Definir las filas -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{
      'editing-row': row.isEditing || row.isNewRegister,
      'editing-row-error': row.isNewRegister && isErrorRegistrationService
    }"
  ></tr>
</table>

<!-- Menú para notas del cliente -->
<mat-menu #menuNotasCliente="matMenu" xPosition="before" yPosition="above" class="mat-menu-client">
  <div class="p-24">
    <div
      class="d-flex justify-content-between align-items-start container-notes"
    >
      <div class="fts-16 font-bold-dali mr-16">Notas del cliente</div>
      <div class="button-x justify-content-end d-flex">
        <img
          class="cursor-pointer"
          src="assets/image/modal/close-modal.svg"
          alt="alert"
          width="14px"
          (click)="cancelNote('cliente')"
        />
      </div>
    </div>
    <div class="dl-divider mb-24"></div>
    <!-- listado de notas  -->
    <ng-container *ngIf="activeElement && activeElement['Notas del cliente']">
      <div
        *ngFor="let note of activeElement['Notas del cliente']; let i = index"
        class="d-flex align-items-center justify-content-between mb-16"
      >
        <div class="fts-16 font-regular">
          {{ note.note }}<br />
          <div class="fts-14 font-semiBold dateRegister">
            {{ note.createdAt ? note.createdAt : "-" }}
          </div>
        </div>
      </div>
    </ng-container>
   
  </div>
</mat-menu>
