import { ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Carousel } from 'primeng/carousel';

@Component({
  selector: 'app-carousel-essentials',
  templateUrl: './carousel-essentials.component.html',
  styleUrls: ['./carousel-essentials.component.scss']
})
export class CarouselEssentialsComponent implements OnInit{
  constructor( private ref: ChangeDetectorRef,) { }

 ngOnInit(): void {
   
 }
  @Input() cards: any[] = []; // Las tarjetas a mostrar
  @ViewChild('carouselGeneral') carouselGeneral!: Carousel; // Acceso al carrusel
  responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 5,
      numScroll: 5,
    },
    {
      breakpoint: '1200px',
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: '992px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '576px',
      numVisible: 1.5,  // Ajustado a 1.5 para dispositivos muy pequeños
      numScroll: 1.5,
    }
  ];
  
  
  prevPage() {
    this.carouselGeneral.navBackward(new Event('click'));
  }

  nextPage() {
    this.carouselGeneral.navForward(new Event('click'));
  }

  getTotalPages(): number[] {
    const width = window.innerWidth;
    let itemsPerPage = 5; // default for desktop

    // Determine items per page based on screen width
    if (width <= 576) {
      itemsPerPage = 1.5;
      // Calculate total items excluding first and last card
      const totalItems = Math.max(0, this.cards.length);
      // Calculate pages based on actual items
      const pages = Math.max(1, Math.ceil(totalItems / itemsPerPage));
      return Array(pages).fill(0);
    } else if (width <= 768) {
      itemsPerPage = 2;
    } else if (width <= 992) {
      itemsPerPage = 3;
    } else if (width <= 1200) {
      itemsPerPage = 4;
    }

    const totalItems = Math.max(0, this.cards.length);
    const totalPages = Math.max(1, Math.ceil(totalItems / itemsPerPage));
    return Array(totalPages).fill(0);
  }

  @HostListener('window:resize')
  onResize() {
    this.ref.detectChanges();
  }

  goToPage(page: number) {
    this.carouselGeneral.navForward(new Event('click'));
    this.carouselGeneral.page = page;
  }
}
