<div class="snack-bar-content d-flex justify-content-between align-items-center vw-100">
  <span class="snack-bar-message mr-16">{{ message }}</span>
  <span
    *ngIf="actionText === 'Entendido'; else buttonError"
    class="color-secondary mr-8 fts-14 font-semiBold clickable"
    (click)="onActionClick()"
  >
    {{ actionText }}
  </span>
  <ng-template #buttonError>
    <button
      mat-button
      (click)="onActionClick()"
      class="button-ingresar btn-enter m-0 p-0 d-flex justify-content-end"
    >
      <span class="color-secondary mr-8 fts-14 font-semiBold">{{ actionText }}</span>
      <svg *ngIf="actionText !=='X'"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2324 6.99984H13.7406C13.7406 3.30148 10.7111 0.271973 7.01275 0.271973C4.82259 0.271973 2.72423 1.32115 1.49144 3.3146C1.66193 2.26542 1.57013 1.63591 1.55701 1.01951H0.232422L0.363569 5.13755L1.38652 5.84574L5.26849 4.5605L4.84881 3.3146C4.10127 3.55066 3.05209 3.82607 2.51439 4.23263C3.44554 2.84246 5.20291 1.72771 7.01275 1.72771C9.88488 1.72771 12.2324 4.07525 12.2324 6.99984ZM7.01275 13.7277C9.1898 13.7277 11.1308 12.6785 12.3636 11.0654C12.3373 11.7605 12.4291 12.3769 12.4423 12.9933H13.7668L13.6357 8.87525L12.6127 8.16706L8.73078 9.4523L9.15045 10.6982C9.88488 10.4621 10.6062 10.3179 11.3144 9.97689C10.3832 11.3671 8.79636 12.272 7.01275 12.272C4.11439 12.272 1.77996 9.92443 1.77996 6.99984H0.271766C0.271766 10.6982 3.28816 13.7277 7.01275 13.7277Z"
          fill="#E62020"
        />
      </svg>
    </button>
  </ng-template>
</div>
