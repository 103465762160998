import { createAction, props } from '@ngrx/store';
import { Essential } from '@core/interfaces/essentials';

// Acciones para gestionar los essentials
export const setEssentials = createAction(
  '[Essentials] Set Essentials',
  props<{ essentials: Essential[] }>()
);

// Acción para indicar que los essentials están cargando
export const essentialsLoading = createAction(
  '[Essentials] Set Essentials Loading',
  props<{ loading: boolean }>());

// Acción para indicar que ha ocurrido un error al cargar los essentials
export const essentialsError = createAction(
  '[Essentials] Set Essentials Error',
  props<{ error: boolean }>()
);

export const loadEssentials = createAction(
  '[Essentials] Load Essentials',
  props<{
    code: string;
    sector: string;
    zone: string;
    schema: string;
    segment: string;
  }>()
);
export const clearAll = createAction('[Essentials] Clear All');  // Acción de limpieza global

export const clearEssentials = createAction('[Essentials] Unset Essentials');
