import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as authActions from '@core/store/auth/auth.actions';
import * as shoppingActions from '@core/store/shoppingCar/shoppingCar.actions';
import * as clientsActions from '@core/store/selectClient/selectClient.actions';
import * as EssentialsActions from '@core/store/essentials/essentials.actions';
import { AppState } from '@core/store/app.reducer';
import { AuthService } from '@core/services/authentication/auth.service';
import { DialogCloseSesionComponent } from '../modals/dialog-close-sesion/dialog-close-sesion.component';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() user: any;
  @Input() dateClients: any;
  @Output() clientSelect = new EventEmitter<string>();
  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private _authService: AuthService,
    
  ) {}

  ngOnInit() {}
  select(){
    this.clientSelect.emit('');
  }
  logout() {
    this.clientSelect.emit('');
    const dialogRef = this.dialog.open(DialogCloseSesionComponent, {
      width: '31rem',
      data: {},
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data?.status) {
        this.store.dispatch(authActions.unsetUser());
        this.store.dispatch(shoppingActions.unsetCar());
        this.store.dispatch(clientsActions.unsetClients());
        this.store.dispatch(EssentialsActions.clearAll());
        this._authService.logout();
      }
      //
    });
  }
}
