import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';
import {
  benefitsTitle,
  currentLevelTitle,
  dataTable,
  iconPeople,
  levelImages,
  levelsTitle,
  levelsTitleMobile,
  sectionAvailableText,
} from 'src/assets/data/partners-ALI-fixed-data';

@Component({
  selector: 'web-table-partners-ALI',
  templateUrl: './table-partners-ALI.component.html',
  styleUrls: ['./table-partners-ALI.component.scss'],
})
export class TablePartnersALIComponent implements OnInit {
  // Definir las variables para los datos
  benefitsTitle = benefitsTitle;
  sectionAvailableText = sectionAvailableText;
  levelsTitle = levelsTitle;
  levelsTitleMobile = levelsTitleMobile;
  levelImages = levelImages;
  iconPeople = iconPeople;
  currentLevelTitle = currentLevelTitle;
  dataTable = dataTable;
  dataTableSelected: any;
  selectedOption: { id: number; src: string; alt: string; text: string } =
    this.levelImages[0]; // Inicializar con el primer objeto de la lista
  selectedOptionButton: string = 'aliados';
  isOpen: boolean = false;
   // El clientLevelType es un número (1, 2, 3, 4)
   clientLevelType!: number ;  // Este valor podría venir de un servicio
   @Input() userDataSegment;
  constructor() {}

  ngOnInit() {
    this.onSelectOptionButton('aliados');
   this.updateLevel();
  }
  // Función para manejar el cambio de selección
  onSelectionChange(option: any) {
    this.selectedOption = option; // Almacenar el objeto completo seleccionado
  }
  onSelectPanelOpened() {
    this.isOpen = true;
  }

  onSelectPanelClosed() {
    this.isOpen = false;
  }
  // Función para cambiar la opción seleccionada (por ejemplo, cuando el usuario selecciona un filtro)
  onSelectOptionButton(option: string) {
    this.selectedOptionButton = option;
    this.updateTableData(); // Actualizar la tabla con la nueva selección
  }
  // Función para actualizar los datos según la opción seleccionada
  updateTableData() {
    switch (this.selectedOptionButton) {
      case 'aliados':
        this.dataTableSelected = dataTable.allies;
        break;
      case 'visibilidad':
        this.dataTableSelected = dataTable.visibility;
        break;
      case 'ejecucion':
        this.dataTableSelected = dataTable.Execution;
        break;
      case 'soporte':
        this.dataTableSelected = dataTable.Support;
        break;
      default:
        this.dataTableSelected = dataTable.allies; // Default to 'aliados' if no option is selected
        break;
    }
  }
  getLevelData(levelId: number) {
    // Busca el nivel correspondiente en partnersTypes según el id
    return this.dataTableSelected[0]?.partnersTypes.find(
      (type: any) => type.id == levelId
    );
  }
  getLevelClasses(fieldName: string) {
    const levelData = this.getLevelData(this.selectedOption?.id);
    return {
      'fts-20 font-font-semiBold color-neutral-medium':
        levelData?.[fieldName] === 'X',
      'fts-16 font-regular': levelData?.[fieldName] !== 'X',
      'color-client-background': levelData?.levelTypeSelect,
    };
  }
  getLevelClassesDesktop(fieldName: string, idSelect: number) {
    const levelData = this.getLevelData(idSelect);
    return {
      'fts-20 font-font-semiBold color-neutral-medium':
        levelData?.[fieldName] === 'X',
      'fts-16 font-regular': levelData?.[fieldName] !== 'X',
      'color-client-background': levelData?.levelTypeSelect,
    };
  }
  updateLevel(): void {
    // La variable selectedOptionButton es el valor que se obtiene desde la interfaz
    switch (this.userDataSegment) {
      case 'B':
        this.clientLevelType = 1;
        break;
      case 'A':
        this.clientLevelType = 2;
        break;
      case 'A+':
        this.clientLevelType = 3;
        break;
      case '1T':
        this.clientLevelType = 4;
        break;
      default:
        this.clientLevelType = 0;
        break;
    }
    this.selectedOption= this.levelImages[this.clientLevelType - 1]; 
    this.activateLevelSelect(this.clientLevelType);
  }
  
   // Función para activar el nivel según el id.
   activateLevelSelect(clientLevel: number): void {
    // Recorre todas las categorías (allies, visibility, execution, support)
    Object.values(this.dataTable).forEach(category => {
      // Recorre todos los tipos de partners en cada categoría
      category.forEach(ally => {
        const levels = ally.partnersTypes; // Accede a los diferentes niveles dentro de "partnersTypes"
        
        // Desactiva todos los niveles al principio
        levels.forEach(level => {
          level.levelTypeSelect = false;
        });

        // Encuentra el nivel correspondiente según el ID y lo activa
        const selectedLevel = levels.find(level => level.id === clientLevel);
        if (selectedLevel) {
          selectedLevel.levelTypeSelect = true;  // Activa el nivel correspondiente
        }
      });
    });
  }
}
