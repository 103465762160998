import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectedClient } from '@core/interfaces/selected-client';

@Component({
  selector: 'app-binnacle-skeleton',
  templateUrl: './binnacle-skeleton.component.html',
  styleUrls: ['./binnacle-skeleton.component.scss']
})
export class BinnacleSkeletonComponent implements OnInit {
  dataSource: number[] = Array(7).fill(0); // Crea un array de 11 elementos
  displayedColumns: string[] = [
    'Visibilidad cliente',
    'Cliente',
    'Fecha de ejecución Inicio - Fin',
    'Estado',
    'Agenda',
    'Categoría',
    'Planes de acción / Actividades',
    'acciones',
  ];
  constructor() {
  }

  ngOnInit() {
  }

}
