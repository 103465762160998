<!-- Existing terms-conditions snackbar -->
<div class="terms-snackbar-container" *ngIf="!isLoading && termsStatus === false">
  <div class="message-container">
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#DF1978" />
      <path
        d="M24.9174 21.0185L17.6781 8.30589C16.7163 6.6272 15.2824 6.6272 14.3207 8.30589L7.08133 21.001C6.11958 22.6797 6.83652 23.9037 8.76002 23.9037L23.2212 23.9212C25.1447 23.9212 25.8791 22.6972 24.9174 21.0185ZM16.0518 19.3398C16.6114 19.3398 17.0485 19.7769 17.0485 20.3365C17.0485 20.8786 16.6114 21.3332 16.0518 21.3332C15.4923 21.3332 15.0551 20.8786 15.0551 20.3365C15.0551 19.7769 15.4923 19.3398 16.0518 19.3398ZM15.3524 13.5168H16.7338L16.7513 18.0108H15.3524V13.5168Z"
        fill="#FCFCFC"
      />
    </svg>
    <div class="terms-message font-bold-dali">
      Aún no eres parte de Ali Socios.
    </div>
  </div>
  <div class="terms-content color-secondary" *ngIf="data.userType === 'client'">
    <div class="terms-checkbox-group font-bold-dali">
      <a (click)="openTerms()" class="terms-link">
        <span class="terms-text">Acepto los</span>
        <span class="terms-text"> Términos y Condiciones</span>
      </a>
      <mat-checkbox
        [formControl]="acceptTerms"
        class="custom-checkbox"
      ></mat-checkbox>
    </div>
    <svg
      width="4"
      height="36"
      viewBox="0 0 4 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="#DEDEDE" />
      <circle cx="2" cy="10" r="2" fill="#DEDEDE" />
      <circle cx="2" cy="18" r="2" fill="#DEDEDE" />
      <circle cx="2" cy="26" r="2" fill="#DEDEDE" />
      <circle cx="2" cy="34" r="2" fill="#DEDEDE" />
    </svg>

    <button
      mat-raised-button
      class="btn-green font-semiBold"
      [ngClass]="{ 'btn-disabled': !acceptTerms.value }"
      [disabled]="!acceptTerms.value"
      (click)="onSubmit()"
    >
      <mat-spinner
        *ngIf="isSubmitting"
        diameter="20"
        class="spinner-button"
      ></mat-spinner>
      <span *ngIf="!isSubmitting">Enviar</span>
    </button>
  </div>
</div>

<!-- Success snackbar template -->
<ng-template #successSnackbar>
  <div class="terms-snackbar-container success">
    <div class="message-container">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#79D279" />
        <path
          d="M13.3762 22.0863L24.3402 11.2273L23.0812 9.88086L13.3937 19.4809L8.93471 14.8645L7.6582 16.1585L13.3762 22.0863Z"
          fill="#FCFCFC"
        />
      </svg>

      <div class="terms-message font-bold-dali">
        <span class="desktop-text">¡Felicidades! Ya eres parte de Ali Socios</span>
        <span class="mobile-text">Ya eres parte de Ali Socios</span>
      </div>
    </div>
    <div class="terms-content color-secondary">
      <div class="terms-checkbox-group-success font-bold-dali">
        <a (click)="openTerms()" class="terms-link"
          >Revisa los Términos y Condiciones</a
        >
        <mat-checkbox
          [disabled]="true"
          class="custom-checkbox"
          [checked]="true"
          [class.disabled-checkbox]="true"
        ></mat-checkbox>
      </div>
    </div>
  </div>
</ng-template>

<!-- Error snackbar template -->
<ng-template #errorSnackbar>
  <div class="terms-snackbar-container error">
    <div class="message-container">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#DF1978" />
        <path
          d="M22.9766 15.9998H24.9875C24.9875 11.0686 20.9481 7.0293 16.017 7.0293C13.0968 7.0293 10.299 8.4282 8.65525 11.0861C8.88257 9.68722 8.76017 8.84788 8.74268 8.02602H6.97656L7.15143 13.5167L8.51536 14.461L13.6913 12.7473L13.1318 11.0861C12.135 11.4009 10.7361 11.7681 10.0192 12.3102C11.2607 10.4566 13.6039 8.97028 16.017 8.97028C19.8465 8.97028 22.9766 12.1003 22.9766 15.9998ZM16.017 24.9703C18.9197 24.9703 21.5077 23.5714 23.1514 21.4206C23.1165 22.3473 23.2389 23.1692 23.2563 23.991H25.0225L24.8476 18.5003L23.4837 17.5561L18.3077 19.2697L18.8673 20.9309C19.8465 20.6162 20.8083 20.4238 21.7525 19.9692C20.511 21.8227 18.3951 23.0293 16.017 23.0293C12.1525 23.0293 9.03995 19.8992 9.03995 15.9998H7.02902C7.02902 20.9309 11.0509 24.9703 16.017 24.9703Z"
          fill="#FCFCFC"
        />
      </svg>

      <div class="terms-message-error font-regular fts-14">
        Lo sentimos no pudimos enviar tu respuesta, vuelve a intentarlo en unos
        minutos.
      </div>
    </div>
    <div class="terms-content margin-error">
      <button
        mat-button
        class="retry-button color-secondary font-semiBold fts-14"
        (click)="retrySubmit()"
      >
        Reintentar
      </button>
    </div>
  </div>
</ng-template>
