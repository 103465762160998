import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AppState } from '@core/store/app.reducer';
import { selectEssentials } from '@core/store/app.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ErrorsMessageTheEssencials, NullMessageTheEssencials } from 'src/assets/data/errors-message';
import * as EssentialsActions from '@core/store/essentials/essentials.actions';
import { NgZone } from '@angular/core';
@Component({
  selector: 'app-the-essentials',
  templateUrl: './the-essentials.component.html',
  styleUrls: ['./the-essentials.component.scss']
})
export class TheEssentialsComponent implements OnInit {
  menssageError = "No pudimos mostrar los precios con descuento, refresca tu navegador para volver a intentarlo.";
  textListNull = 'Lo sentimos no pudimos enviar tu respuesta, vuelve a intentarlo en unos minutos.';
  responseError = false;
  private essentialsSubscription$: Subscription | undefined;
  loading$!: Observable<boolean>;
  error$!: Observable<boolean | null>;
  cards: any[] = [];
  cardsType2: any[] = [];
  cardsType3: any[] = [];
  messageError = [ErrorsMessageTheEssencials];
  nullMessageTheEssencials = [NullMessageTheEssencials];
  searchTerm: string = '';
  private essentialsSuscription$: Subscription | undefined;
  // Variables para almacenar los productos originales
  originalCards: any[] = [];
  originalCardsType2: any[] = [];
  originalCardsType3: any[] = [];

  constructor(private store: Store<AppState>,private localStorageService: LocalStorageService,  private ngZone: NgZone) { }

  ngOnInit() {
    const user = this.localStorageService?.getItem('user');
    
    if (user?.type == 'Vendedor') {
      
      this.initEssentialsStore();
    } else {
      this.handleEssentialsState();  // Llamar a la función que maneja la suscripción al store
    }
  }
  
  initEssentialsStore() {
    const clientSelect = this.localStorageService.getItem('clientSelect');
    const body = {
      code: clientSelect.code,
      sector: clientSelect.sector,
      zone: clientSelect.zone,
      schema: clientSelect.schema,
      segment: clientSelect.segment,
    };
    // Limpiar las tarjetas antes de cargar nuevos datos
    this.cards = [];
  
    // Despachar la acción para cargar los essentials
    this.store.dispatch(EssentialsActions.loadEssentials(body));
  
    // Llamar a la función para manejar la suscripción
    this.handleEssentialsState();
  }
  
  handleEssentialsState() {
    // Suscribirse al estado de essentials para procesar la respuesta
    this.essentialsSubscription$ = this.store.select(selectEssentials).subscribe((state) => {
      if (state && state.length > 0) {
        this.originalCards = [...state.filter(card => card.discountNumber && card.discountType && card.discountType.trim() !== '')];
        this.originalCardsType2 = [...state.filter(card => card.group === '2')];
        this.originalCardsType3 = [...state.filter(card => card.group === '3')];
  
        // Actualizar las tarjetas filtradas
        this.cards = [...this.originalCards];
        this.cardsType2 = [...this.originalCardsType2];
        this.cardsType3 = [...this.originalCardsType3];
      }
    });
    this.essentialsSubscription$ = this.store.select(selectEssentials).subscribe((state) => {
      console.log('Essentials State:', state); // Agregar un log para ver los datos
      if (state && state.length > 0) {
        this.originalCards = [...state.filter(card => card.discountNumber && card.discountType && card.discountType.trim() !== '')];
        // ... resto de la lógica
      } else {
        console.log('No se encontraron productos esenciales');
      }
    });
    
    // Obtener loading y error desde el Store
    this.loading$ = this.store.select(state => state.essentialsState.loading);
    this.error$ = this.store.select(state => state.essentialsState.error);
  }
  
  // Función para filtrar los productos por coincidencia
  filterProducts() {
    const term = this.searchTerm.toLowerCase().trim(); // Aseguramos que el término de búsqueda sea minúscula y sin espacios al inicio y final
  
    // Si el término de búsqueda tiene menos de 3 caracteres o está vacío, mostramos todos los productos
    if (term.length < 3) {
      // Restaurar productos originales si el término de búsqueda tiene menos de 3 caracteres o está vacío
      this.resetFilter();
      return;
    }
  
    // Filtrar productos solo si el término de búsqueda tiene 3 o más caracteres
    this.cards = this.originalCards.filter(product =>
      (product.sku && product.sku.toLowerCase().includes(term)) ||
      (product.description && product.description.toLowerCase().includes(term)) ||
      (product.categoryName && product.categoryName.toLowerCase().includes(term))
    );
    
    this.cardsType2 = this.originalCardsType2.filter(product =>
      (product.sku && product.sku.toLowerCase().includes(term)) ||
      (product.description && product.description.toLowerCase().includes(term)) ||
      (product.categoryName && product.categoryName.toLowerCase().includes(term))
    );
  
    this.cardsType3 = this.originalCardsType3.filter(product =>
      (product.sku && product.sku.toLowerCase().includes(term)) ||
      (product.description && product.description.toLowerCase().includes(term)) ||
      (product.categoryName && product.categoryName.toLowerCase().includes(term))
    );
  }
  
  // Función para restaurar los productos cuando el filtro debe ser eliminado (por ejemplo, cuando la búsqueda es vacía o tiene menos de 3 caracteres)
  resetFilter() {
    // Restaurar todos los productos originales si es necesario
    this.cards = [...this.originalCards];
    this.cardsType2 = [...this.originalCardsType2];
    this.cardsType3 = [...this.originalCardsType3];
  }
 // Método que recibe el valor de error y determina si debe estar deshabilitado
 isDisabled(error: boolean | null): boolean {
  return (
    error || 
    (this.originalCards.length <= 0 && 
     this.originalCardsType2.length <= 0 && 
     this.originalCardsType3.length <= 0)
  );
}
  ngOnDestroy() {
    // Limpiar la suscripción cuando el componente se destruya
    if (this.essentialsSubscription$) {
      this.essentialsSubscription$.unsubscribe();
    }
  }
  
}
