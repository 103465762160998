<div class="scroll-hidden">
  <!-- Image and text -->
  <div>
    <div>
      <nav class="navbar navbar-light bg-light p-0 content-nav scroll-hidden">
        <div
          class="d-flex justify-content-between container-list vw-100 align-items-center container"
        >
          <a class="navbar-brand">
            <img src="assets/svg/logo-new.svg" alt />
          </a>
          <div>
            <div class="content-img-perfil d-none d-md-block">
              <div class="img-user d-flex" [matMenuTriggerFor]="menuClients">
                <mat-icon
                  ><img
                    src="assets/image/selected-client-list/user-select.svg"
                    alt
                    class="m-0 p-0"
                /></mat-icon>
              </div>
            </div>

            <div class="content-profile d-md-none">
              <mat-drawer-container class="hp-100 vw-100" autosize>
                <mat-drawer #drawerProfile mode="over" position="end">
                  <app-profile-responsive
                    [optionsMenu]="options"
                    [user]="user"
                    [dateClients]="dateClients?.length ? true : false"
                    (closeModalProfile)="closeProfile($event)"
                  ></app-profile-responsive>
                </mat-drawer>

                <mat-drawer-content>
                  <div class="background-color">
                    <div class="img-user d-flex" (click)="openProfile()">
                      <mat-icon
                        ><img
                          src="assets/image/selected-client-list/user-select.svg"
                          alt
                          class="m-0 p-0"
                      /></mat-icon>
                    </div>
                  </div>
                </mat-drawer-content>
              </mat-drawer-container>
            </div>
          </div>
        </div>
      </nav>
      <div class="content-banner content-desk-close">
        <img
          src="assets/image/selected-client-list/banner-nav.svg"
          alt
          class="vw-100 container"
        />
      </div>
    </div>
    <div class="container-list align-items-center row ml-0 mr-0 container">
      <div class="fts-24 font-semiBold d-none d-sm-block">
        Hola, {{ user.fullName }}.
        <span class="fts-14 title-welcome">{{ welcomeSubMessage }}</span>
      </div>
      <div class="fts-24 font-semiBold d-sm-none">
        Hola, {{ user.fullName }}.
        <div class="fts-14 title-welcome">
          {{ welcomeSubMessage }}
        </div>
      </div>
    </div>
  </div>
  <!-- -->
  <div class="overflow-auto pt-0 hp-100 container section-seller">
    <div class="d-flex container-cards">
      <app-card-routines
        [routinesMessage]="routinesMessage"
        [routinesList]="routinesList"
        [routinesListLoading]="routinesListLoading"
        [routinesMessageError]="routinesMessageError"
        [headerShow]=" true"
        (errorRoutines)="initDataRoutines()"
      ></app-card-routines>
<div>
      <div class="container-seller show-box-shadow">
        <div class="container-img-custom">
          <img
            src="assets/image/home-seller/clients-mobile.svg"
            class="img-custom"
          />
        </div>

        <div class="p-24 vw-100">
          <div class="d-flex justify-content-between pb-16">
            <div class="d-flex align-items-center">
              <img
                src="assets/image/home-seller/icon-clients.svg"
                alt
                class="pr-8"
              />
              <p class="m-0 fts-16 font-bold">
                Mis clientes ({{ clientSearch.length }})
              </p>
            </div>
            <div
              class="d-flex align-items-center img-user"
              *ngIf="dateClients?.length > 0"
              [routerLink]="'/main/cliente-seleccionado'"
            >
              <span class="pr-8 text-show font-semiBold fts-16">Ver más</span>
              <img src="assets/image/home-seller/caretRight_regular.svg" alt />
            </div>
          </div>
          <div class="dl-divider"></div>
          <div
            class="mt-16 d-flex"
            *ngIf="dateClients?.length > 0; else clientsSqueleton"
          >
            <div class="d-flex flex-column vw-100">
              <div class="font-book font-custom ta-s">
                {{ clientDescription }}
              </div>
              <div
                class="p-0 content-nav-logo d-flex justify-content-start mt-16"
              >
                <mat-form-field
                  [floatLabel]="'false'"
                  appearance="outline"
                  class="clientSelectList"
                  color="accent"
                >
                  <!-- <mat-label  class="fts-16 line-height-20 font-book">Elige un cliente</mat-label> -->
                  <mat-select
                    [(ngModel)]="dataConvertClientSelect"
                    name="client"
                    [ngClass]="{ 'hide-select-arrow': true }"
                    [disabled]="dateClients?.length === 1"
                    #selectClient="matSelect"
                    (opened)="onSelectPanelOpened()"
                    (closed)="onSelectPanelClosed()"
                    placeholder="{{ clientSelectMessag }}"
                  >
                    <mat-option
                      *ngFor="let client of dateClients"
                      [value]="client.code"
                    >
                      <span>{{ client.code }} - {{ client.name }}</span>

                      <mat-icon
                        *ngIf="dataConvertClientSelect?.code === client?.code"
                        class="icon-check"
                      >
                        <img
                          src="assets/image/home-seller/check_regular.svg"
                          alt
                        />
                      </mat-icon>
                    </mat-option>
                  </mat-select>
                  <span
                    class="material-symbols-rounded"
                    [ngClass]="
                      dateClients?.length <= 1
                        ? 'color-disabled '
                        : 'color-active'
                    "
                    >{{ isOpen ? "expand_less" : "expand_more " }}</span
                  >
                </mat-form-field>
              </div>
              <button
                class="btn-green btn-secondary-red p-12 font-book fts-14 mt-8"
                pButton
                [disabled]="!dateClients"
                (click)="changeValue(dataConvertClientSelect, false)"
              >
                Ingresar
              </button>
            </div>
          </div>
          <ng-template #clientsSqueleton>
            <div
              class="d-flex align-items-center justify-content-center skeleton-custom"
            >
              <div
                *ngIf="loadingSkeleton; else bodyError"
                class="container-skeleton vw-100 mt-16"
              >
                <div class="d-flex flex-column">
                  <div class="hp-16 vw-100 mb-10">
                    <ngx-skeleton-loader
                      count="1"
                      count="1"
                      appearance="line"
                    ></ngx-skeleton-loader>
                  </div>
                  <div class="hp-16 vw-50">
                    <ngx-skeleton-loader
                      count="1"
                      count="1"
                      appearance="line"
                    ></ngx-skeleton-loader>
                  </div>
                  <div class="hp-16 vw-100 mb-10 mt-24">
                    <ngx-skeleton-loader
                      count="1"
                      count="1"
                      appearance="line"
                      height="56px"
                      class="h-custom"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
              <ng-template #bodyError>
                <div
                  class="text-error ta-c d-flex"
                  *ngIf="booleanMessageError; else nullClient"
                >
                  <app-message-empty-error
                    [messageError]="messageError"
                    (retryDataError)="initDataSelectclient()"
                  ></app-message-empty-error>
                </div>
                <ng-template #nullClient>
                  <div class="text-error ta-c">
                    <app-message-empty-error
                      [messageError]="messageClientNull"
                    ></app-message-empty-error>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="mt-16 mb-16">
        <div class="p-24 show-box-shadow">
          <div class="d-flex justify-content-between pb-16">
            <div class="d-flex align-items-center container-binnacle">
              <img
                src="assets/image/home-seller/icon-meeting.svg"
                alt
                class="pr-8"
              />
              <p class="m-0 fts-16 font-bold">Bitácora de reuniones y planes</p>
            </div>
            <div
            class="d-flex align-items-center img-user"
            [routerLink]="'/detalle-bitácora'"
          >
            <span class="pr-8 text-show font-semiBold fts-16">Ver más</span>
            <img src="assets/image/home-seller/caretRight_regular.svg" alt />
          </div>
          </div>
          <div class="dl-divider"></div>
          <div class="mt-16 d-flex">
            <div class="d-flex">
              <div class="font-book fts-14 line-height-20 ta-s" [innerHTML]="meetingsDescription">
              </div>

             <div><img
              src="assets/image/home-seller/meeting.svg"
              class="ml-8"
            /></div>
            </div>
          </div>
        </div>
      </div>
</div>
      <div>
        <div class="show-box-shadow container-seller-02 margin-custom">
          <div class="p-24">
            <div class="d-flex justify-content-between pb-16">
              <div class="d-flex align-items-center">
                <img
                  src="assets/image/home-seller/icon-suggestions.svg"
                  alt
                  class="pr-8"
                />
                <p class="m-0 fts-16 font-bold">Sugerencias</p>
              </div>
            </div>
            <div class="dl-divider"></div>
            <div class="mt-16 d-flex">
              <div class="d-flex flex-column">
                <div class="font-book fts-14 line-height-20 ta-s">
                  {{ suggestionsDescription }}
                </div>

                <div
                  class="p-0 content-nav-logo d-flex justify-content-start mt-16"
                >
                  <button
                    class="btn-green p-12 font-book fts-14"
                    pButton
                    (click)="redirectToUrlSuggestions()"
                  >
                    Enviar sugerencias
                    <img
                      src="assets/image/home-seller/icon-redirect.svg"
                      class="ml-8"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="show-box-shadow container-seller-02 mt-16">
          <div class="p-24">
            <div class="d-flex justify-content-between pb-16">
              <div class="d-flex align-items-center">
                <img
                  src="assets/image/home-seller/icon-training.svg"
                  alt
                  class="pr-8"
                />
                <p class="m-0 fts-16 font-bold">Capacitaciones</p>
              </div>
            </div>
            <div class="dl-divider"></div>
            <div class="mt-16 d-flex">
              <div class="d-flex flex-column">
                <div class="font-book fts-14 line-height-20 ta-s">
                  {{ trainingDescription }}
                </div>

                <div
                  class="p-0 content-nav-logo d-flex justify-content-start mt-16"
                >
                  <button
                    class="btn-green p-12 font-book fts-14"
                    pButton
                    (click)="redirectToUrl()"
                  >
                    Ir a las capacitaciones
                    <img
                      src="assets/image/home-seller/icon-redirect.svg"
                      class="ml-8"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-menu #menuClients="matMenu">
  <app-profile
    [user]="user"
    [dateClients]="dateClients?.length ? true : false"
    (clientSelect)="clientSelect()"
  ></app-profile>
</mat-menu>
